import React, { useState } from 'react';
import styled from 'styled-components';
import { BigText, NormalText, PurpleText, Container } from './ControlDesc';
import hotImg from 'assets/imgs/service/hot.png';
import coldImg from 'assets/imgs/service/cold.png';
import hotImgSmall from 'assets/imgs/service/hotImgSmall.png';
import coldImgSmall from 'assets/imgs/service/coldImgSmall.png';
import useWindowSize from 'hooks/common/useWindowSize';
import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import { useTranslation, Trans } from 'react-i18next';

interface StyledProps {
  isTransition: boolean;
}

function AiDesc() {
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const [transition, setTransition] = useState(false);
  const { t } = useTranslation('services');
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setTransition(true);
    } else {
      setTransition(false);
    }
  };
  const setTarget = useIntersectionObserver({
    onIntersect,
    threshold: 1,
  });
  return (
    <MarginContainer>
      <PaddingContainer>
        <PurpleText>
          <Trans t={t} i18nKey="ai.purple" />
        </PurpleText>
        <BigText>
          <span>
            <Trans t={t} i18nKey="ai.big1" />
          </span>
          <span>
            <Trans t={t} i18nKey="ai.big2" />
          </span>
          <span>
            <Trans t={t} i18nKey="ai.big3" />
          </span>
        </BigText>
        <MarginNormalText>
          <Trans t={t} i18nKey="ai.normal" />
        </MarginNormalText>
      </PaddingContainer>
      <ImageBox ref={setTarget} isTransition={transition}>
        <img src={isSmallScreen ? hotImgSmall : hotImg} />
        <img src={isSmallScreen ? coldImgSmall : coldImg} />
        {isSmallScreen ? (
          <>
            <div>
              <p>
                <Trans t={t} i18nKey="ai.imageSmall1" />
              </p>
              <p>
                <Trans t={t} i18nKey="ai.imageSmall2" />
              </p>
            </div>
            <div>
              <p>
                <Trans t={t} i18nKey="ai.imageSmall3" />
              </p>
              <p>
                <Trans t={t} i18nKey="ai.imageSmall4" />
              </p>
            </div>
          </>
        ) : (
          <>
            <div>
              <p>
                <Trans t={t} i18nKey="ai.image1" />
              </p>
              <p>
                <Trans t={t} i18nKey="ai.image2" />
              </p>
              <p>
                <Trans t={t} i18nKey="ai.image3" />
              </p>
              <p>
                <Trans t={t} i18nKey="ai.image4" />
              </p>
            </div>
            <div>
              <p>
                <Trans t={t} i18nKey="ai.image5" />
              </p>
              <p>
                <Trans t={t} i18nKey="ai.image6" />
              </p>
              <p>
                <Trans t={t} i18nKey="ai.image7" />
              </p>
              <p>
                <Trans t={t} i18nKey="ai.image8" />
              </p>
            </div>
          </>
        )}
      </ImageBox>
    </MarginContainer>
  );
}

const MarginContainer = styled(Container)`
  margin-bottom: 300px;
  padding: 0;
  @media (max-width: 767px) {
    margin-bottom: 76px;
    max-width: 767px;
  }
`;

const MarginNormalText = styled(NormalText)`
  margin-bottom: 100px;
`;

const PaddingContainer = styled.div`
  padding: 0 50px 0 50px;
`;

const ImageBox = styled.div<StyledProps>`
  width: 100%;
  height: 530px;
  display: flex;
  position: relative;
  & > img {
    width: 513px;
    height: 353px;
    @media (max-width: 767px) {
      width: 50%;
      height: 200px;
    }
  }
  & > img:nth-last-child(3) {
    transform: translateY(50%);
    @media (max-width: 767px) {
      transform: translateY(0);
    }
  }
  & > div {
    opacity: 0;
    position: absolute;
    background: #111111;
    width: 513px;
    height: 353px;
    font-weight: 500;
    font-size: 22px;
    line-height: 40px;
    color: white;
    padding: 30px 40px;
    transition: all 0.5s;
    @media (max-width: 767px) {
      width: 50%;
      height: 200px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      padding: 40px 25px;
      text-align: right;
    }
  }
  &:hover > div {
    opacity: 0.8;
  }
  @media (max-width: 767px) {
    & > div {
      opacity: ${({ isTransition }) => (isTransition ? 0.8 : 0)};
    }
  }
  & > div:last-child {
    left: 512px;
    top: 177px;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 767px) {
      left: unset;
      right: 0;
      top: 0;
      text-align: left;
    }
  }

  & > div:nth-last-child(2) {
    @media (max-width: 767px) {
      top: 100px;
    }
  }

  @media (max-width: 767px) {
    margin-top: 100px;
    height: 300px;
    & > img:first-child {
      transform: translateY(50%);
    }
  }
`;

export default AiDesc;
