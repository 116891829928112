import styled from 'styled-components';
import LineImg from 'assets/imgs/main/illust-line.png';
import { SEEDN_NEW, SEEDN_SUB } from 'styles/palette';
import useWindowSize from 'hooks/common/useWindowSize';
import { ILLUST_ARTICLES } from 'constants/main';
import GradientImg0 from 'assets/imgs/main/illust-container.png';
import { useState } from 'react';
import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import IllustCarousel from './IllustCarousel';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

const ROOT_MARGIN = '500px';

const Illust = () => {
  const { t } = useTranslation('main');
  const isMobile = useWindowSize().windowSize! < 768;
  const [animation, setAnimation] = useState(false);
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setAnimation(true);
    }
  };
  const { language } = useChangeLanguage();
  const setTarget = useIntersectionObserver({
    rootMargin: ROOT_MARGIN,
    onIntersect,
  });

  return (
    <>
      <Container>
        <Background />
        <Line>
          <LineImage src={LineImg} />
        </Line>
        <Wrapper>
          <Title>
            <h1 dangerouslySetInnerHTML={{ __html: t('illust.title.1') }}></h1>
            <h2>{isMobile && language === 'en' ? '' : t('illust.title.2')}</h2>
          </Title>
          <Articles ref={setTarget}>
            <IllustContainer>
              <Gradient animation={animation} />
            </IllustContainer>
            {ILLUST_ARTICLES.map((article, idx) => (
              <Article key={idx} gap={article.gap}>
                <IllustCircle>
                  <IllustImg
                    src={article.illustImg}
                    alt="일러스트"
                    desktop={article.desktopWidth}
                    mobile={article.mobileWidth}
                  />
                </IllustCircle>
                <Text>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: t(`illust.articles.${idx + 1}.title`),
                    }}
                  ></h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`illust.articles.${idx + 1}.desc`),
                    }}
                  ></p>
                </Text>
              </Article>
            ))}
          </Articles>
          {isMobile && <IllustCarousel />}
        </Wrapper>
      </Container>
      <svg width="0" height="0">
        <defs>
          <clipPath id="myClip">
            <path d="M312.884 389.963C300.264 377.95 281.445 375.891 265.097 381.913C243.875 389.731 220.936 394 197 394C88.1999 394 0 305.8 0 197C0 88.1999 88.1999 0 197 0C305.8 0 394 88.1999 394 197C394 223.548 388.749 248.869 379.228 271.982C372.005 289.517 374.239 310.304 387.975 323.38L502.187 432.1C515.875 445.131 536.659 446.379 553.785 438.387C579.064 426.589 607.263 420 637 420C745.8 420 834 508.2 834 617C834 725.8 745.8 814 637 814C609.718 814 583.731 808.454 560.105 798.428C543.286 791.291 523.343 792.903 510.109 805.5L391.204 918.688C377.924 931.33 375.364 951.259 381.745 968.448C389.67 989.797 394 1012.89 394 1037C394 1061.28 389.608 1084.53 381.574 1106.01C375.135 1123.23 377.672 1143.21 390.985 1155.89L509.544 1268.75C522.809 1281.37 542.812 1282.96 559.653 1275.76C583.399 1265.62 609.544 1260 637 1260C745.8 1260 834 1348.2 834 1457C834 1565.8 745.8 1654 637 1654C528.2 1654 440 1565.8 440 1457C440 1433.91 443.974 1411.74 451.275 1391.15C457.316 1374.11 454.63 1354.54 441.538 1342.07L320.563 1226.91C307.518 1214.5 287.919 1212.74 271.237 1219.53C248.326 1228.86 223.263 1234 197 1234C88.1999 1234 0 1145.8 0 1037C0 928.2 88.1999 840 197 840C223.438 840 248.659 845.208 271.695 854.654C288.401 861.504 308.058 859.774 321.136 847.325L441.75 732.509C454.875 720.016 457.539 700.38 451.441 683.316C444.034 662.594 440 640.268 440 617C440 596.217 443.218 576.186 449.184 557.377C454.47 540.709 451.515 521.928 438.849 509.872L312.884 389.963Z" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Illust;

const Container = styled.section`
  position: relative;
  width: 1018px;
  padding-top: 350px;
  padding-bottom: 337px;
  margin: 0 auto;

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    padding-top: 90px;
    padding-bottom: 130px;
  }
`;

const Background = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
    background-color: ${SEEDN_NEW};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: -10;
  }
`;

const Line = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
    position: absolute;
    top: -15px;
    left: 50px;
    width: 100%;
    height: 20px;
    background-color: #fff;
    z-index: 20;
  }
`;

const LineImage = styled.img`
  @media (max-width: 767px) {
    display: block;
    position: absolute;
    width: 396px;
    left: -70px;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
`;

const Title = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 130px;

  * {
    opacity: 0.8;
  }

  h1 {
    font-weight: 700;
    font-size: 44px;
    line-height: 60px;
  }

  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
  }

  @media (max-width: 767px) {
    width: fit-content;
    margin: 0 auto 20px;
    left: 0;

    h1 {
      font-weight: 500;
      line-height: 30px;
      color: #fcfcfc;
      font-size: 18px;
      text-align: end;

      /* &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -11px;
        width: 9px;
        height: 9px;
        background-color: ${SEEDN_SUB};
        border-radius: 50%;
      } */
    }

    h2 {
      font-weight: 500;
      line-height: 30px;
      color: #fcfcfc;
      font-size: 14px;
      opacity: 0.7;
      text-align: end;
    }
  }
`;

const IllustContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 834px;
  height: 1654px;
  z-index: -1;
  background-image: url(${GradientImg0});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 300ms ease-in;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Gradient = styled.div<{ animation: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: linear-gradient(180deg, #b518ff 0%, #921cfe 51.1%, #4f16a9 91%);
  clip-path: url(#myClip);
  animation: ${({ animation }) =>
    animation && `gradient 7500ms ease-in-out 0ms 1 normal forwards`};

  @keyframes gradient {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }
`;

const Articles = styled.div`
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Text = styled.div`
  position: relative;

  h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    opacity: 0.8;
    margin-bottom: 28px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.019em;
    color: #1e1e1e;
    opacity: 0.8;
  }
  strong {
    font-weight: 500;
    display: inline-block;
    background-color: #e7dcf8;
    height: 23px;
    line-height: 23px;
  }
`;

const Article = styled.article<{
  gap?: string;
  marginBottom?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 760px;
  margin: 0 auto 100px;

  &:nth-child(2n-1) {
    flex-direction: row-reverse;
    text-align: end;
  }

  &:first-of-type {
    padding-top: 37px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 767px) {
    &:nth-of-type(2n-1) {
      ${Text} {
        left: 50px;
      }
    }

    &:nth-of-type(2n) {
      ${Text} {
        left: -30px;
      }
    }
  }
`;

const IllustCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: -40px 40px 80px rgba(0, 0, 0, 0.2);
`;

const IllustImg = styled.img<{ desktop?: string; mobile?: string }>`
  width: ${({ desktop }) => desktop};
  height: ${({ desktop }) => desktop};
`;
