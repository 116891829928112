import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';

export type DropdownPlaceholderStrings = '1개월' | '3개월' | '6개월' | '1년';

export interface DateRange {
  startDate: Moment | null;
  endDate: Moment | null;
}

export interface DropdownPlaceholder {
  dropdownPlaceholder: DropdownPlaceholderStrings;
}

interface InitialState extends DateRange, DropdownPlaceholder {
  isCalendarOpen: boolean;
  isDropdownOpen: boolean;
}

const initialState: InitialState = {
  isCalendarOpen: false,
  startDate: moment(),
  endDate: moment().subtract(3, 'months'),
  isDropdownOpen: false,
  dropdownPlaceholder: '3개월',
};

const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    setIsCalendarOpen: state => {
      state.isCalendarOpen = true;
    },
    setIsCalendarClose: state => {
      state.isCalendarOpen = false;
    },
    setIsDropdownOpen: state => {
      state.isDropdownOpen = true;
    },
    setIsDropdownClose: state => {
      state.isDropdownOpen = false;
    },
    setDropdownPlaceholder: (
      state,
      { payload }: PayloadAction<DropdownPlaceholderStrings>,
    ) => {
      state.dropdownPlaceholder = payload;
    },
    setDateRange: (
      state,
      { payload: { startDate, endDate } }: PayloadAction<DateRange>,
    ) => {
      state.startDate = startDate;
      state.endDate = endDate;
    },
  },
});

export const {
  setIsCalendarOpen,
  setIsCalendarClose,
  setDateRange,
  setIsDropdownOpen,
  setIsDropdownClose,
  setDropdownPlaceholder,
} = dateRangeSlice.actions;

export default dateRangeSlice.reducer;
