export const FAQ_TABMENU_ITEMS = [
  '가장 많이 묻는 질문',
  '제품',
  '결제/환불',
  '설치',
  '운영/제어',
  '고장/오류',
  '설치',
  '멤버십',
  '회원',
  '어플리케이션',
];

export const FAQ_LIST_ITEMS = [
  {
    q: '1시간마다 에어컨이 자꾸 꺼져요',
    a: (
      <>
        <p>
          리프 앱에서 에어컨을 종료로 설정해 두면 1시간마다 종료 명령이
          반복되므로, 꼭 앱을 통해서만 에어컨을 제어해주세요.
        </p>
        <p>
          리프 앱의 신호가 에어컨의 자체 리모컨/컨트롤러 신호보다 우선적으로
          적용됩니다.
        </p>
      </>
    ),
  },
  {
    q: '에어컨이 즉시 제어되지 않아요',
    a: (
      <>
        <p>
          리프 앱에서 에어컨을 종료로 설정해 두면 1시간마다 종료 명령이
          반복되므로, 꼭 앱을 통해서만 에어컨을 제어해주세요.
        </p>
        <p>
          리프 앱의 신호가 에어컨의 자체 리모컨/컨트롤러 신호보다 우선적으로
          적용됩니다.
        </p>
      </>
    ),
  },
  {
    q: '2분이 지나도 에어컨이 제어되지 않아요',
    a: (
      <>
        <p>
          리프 앱에서 에어컨을 종료로 설정해 두면 1시간마다 종료 명령이
          반복되므로, 꼭 앱을 통해서만 에어컨을 제어해주세요.
        </p>
        <p>
          리프 앱의 신호가 에어컨의 자체 리모컨/컨트롤러 신호보다 우선적으로
          적용됩니다.
        </p>
      </>
    ),
  },
  {
    q: '필터 청소 후, 에어컨이 제어되지 않아요',
    a: (
      <>
        <p>
          리프 앱에서 에어컨을 종료로 설정해 두면 1시간마다 종료 명령이
          반복되므로, 꼭 앱을 통해서만 에어컨을 제어해주세요.
        </p>
        <p>
          리프 앱의 신호가 에어컨의 자체 리모컨/컨트롤러 신호보다 우선적으로
          적용됩니다.
        </p>
      </>
    ),
  },
  {
    q: '와이파이 또는 비밀번호 변경 후, 연결이 안돼요',
    a: (
      <>
        <p>
          리프 앱에서 에어컨을 종료로 설정해 두면 1시간마다 종료 명령이
          반복되므로, 꼭 앱을 통해서만 에어컨을 제어해주세요.
        </p>
        <p>
          리프 앱의 신호가 에어컨의 자체 리모컨/컨트롤러 신호보다 우선적으로
          적용됩니다.
        </p>
      </>
    ),
  },
  {
    q: '온습도 센서 1개가 감지를 못해요',
    a: (
      <>
        <p>
          리프 앱에서 에어컨을 종료로 설정해 두면 1시간마다 종료 명령이
          반복되므로, 꼭 앱을 통해서만 에어컨을 제어해주세요.
        </p>
        <p>
          리프 앱의 신호가 에어컨의 자체 리모컨/컨트롤러 신호보다 우선적으로
          적용됩니다.
        </p>
      </>
    ),
  },
  {
    q: '온습도 센서 여러 개가 감지를 못해요',
    a: (
      <>
        <p>
          리프 앱에서 에어컨을 종료로 설정해 두면 1시간마다 종료 명령이
          반복되므로, 꼭 앱을 통해서만 에어컨을 제어해주세요.
        </p>
        <p>
          리프 앱의 신호가 에어컨의 자체 리모컨/컨트롤러 신호보다 우선적으로
          적용됩니다.
        </p>
      </>
    ),
  },
];
