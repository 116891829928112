export const validateEmail = (email: string) => {
  const regEmail =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const result: { email: string } = { email: '' };

  result.email = '확인.';

  if (!regEmail.test(email)) {
    result.email = '이메일 형식이 올바르지 않습니다.';
  }
  if (!email) {
    result.email = '이메일을 입력해주세요.';
  }

  return result;
};

export const validatePhone = (phone: string) => {
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const result = { phone: '' };

  result.phone = '확인.';

  if (!regPhone.test(phone)) {
    result.phone = '전화번호 형식이 올바르지 않습니다.';
  }
  if (!phone) {
    result.phone = '연락처를 입력해주세요.';
  }

  return result;
};
