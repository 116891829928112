import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import styled from 'styled-components';
import TemperatureController from './TemperatureController';
import ScheduleController from './ScheduleController';
import TimerController from './TimerController';
import { useTranslation, Trans } from 'react-i18next';

function Slider() {
  const { t } = useTranslation('services');
  return (
    <Container>
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={0}
        className="mySwiper"
      >
        <Slide style={{ width: '156px', height: '173px' }}>
          <TemperatureController />
          <Text>
            <Trans t={t} i18nKey="control.controllersName1" />
          </Text>
        </Slide>
        <Slide style={{ width: '156px', height: '173px' }}>
          <ScheduleController />
          <Text>
            <Trans t={t} i18nKey="control.controllersName2" />
          </Text>
        </Slide>
        <Slide style={{ width: '156px', height: '173px' }}>
          <TimerController />
          <Text>
            <Trans t={t} i18nKey="control.controllersName3" />
          </Text>
        </Slide>
      </Swiper>
      <GradientLeft />
      <GradientRight />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 190px;
  margin-bottom: 51px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .swiper {
    width: 60%;
    height: 100%;
    overflow: visible;
  }
  .swiper-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const Slide = styled(SwiperSlide)`
  text-align: center;
  font-size: 18px;
  width: 156px;
  height: 173px;
  text-align: center;
`;

const GradientLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  background: linear-gradient(270deg, #ffffff 20%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(-180deg);
  z-index: 10;
`;

const GradientRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background: linear-gradient(90deg, #ffffff 20%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(-180deg);
  z-index: 10;
`;

const Text = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
  color: #6f36c9;
  transform: translateX(-5%);
`;

export default Slider;
