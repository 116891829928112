import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as careersAPI from 'apis/careers';

export interface PositionDetail {
  position: string;
  contract: string;
  group: string;
  stack: string;
  content: string;
  mainImage?: string;
  id?: number | null;
}

interface Form {
  name: string;
  value: string | File | null;
}

interface WriteForm {
  [prop: string]: any;
}

export interface IPost {
  id: string;
  position: string;
  stack: string;
  contract: string;
}

interface InitialState {
  writeForm: WriteForm;
  posts: IPost[];
  positionDetail: PositionDetail;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  [prop: string]: any;
}

const initialState: InitialState = {
  writeForm: {
    position: '',
    stack: '',
    contract: '',
    group: '',
    content: '',
    mainImage: '',
    error: null,
  },
  posts: [],
  positionDetail: {
    id: null,
    position: '',
    contract: '',
    group: '',
    content: '',
    mainImage: '',
    stack: '',
  },
  isLoading: false,
  isError: false,
  isSuccess: false,
};

export const getPosts = createAsyncThunk('post/getAll', async (_, thunkAPI) => {
  try {
    return await careersAPI.getPosts();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getPositionDetail = createAsyncThunk(
  'post/getOnePost',
  async (id: number, thunkAPI) => {
    try {
      return await careersAPI.getPositionDetail(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deletePost = createAsyncThunk(
  'post/delete',
  async (id: number, thunkAPI) => {
    try {
      await careersAPI.deletePosition(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createPost = createAsyncThunk(
  'post/create',
  async (
    { position, contract, group, stack, content, mainImage }: PositionDetail,
    thunkAPI,
  ) => {
    try {
      const formData: FormData = new FormData();
      formData.append('position', position);
      formData.append('contract', contract);
      formData.append('group', group);
      formData.append('stack', stack);
      formData.append('content', content);
      if (mainImage) {
        formData.append('mainImage', mainImage);
      }
      return await (
        await careersAPI.createPost(formData)
      )?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updatePost = createAsyncThunk(
  'post/update',
  async (
    {
      id,
      position,
      contract,
      group,
      stack,
      content,
      mainImage,
    }: PositionDetail,
    thunkAPI,
  ) => {
    try {
      const formData: FormData = new FormData();
      formData.append('position', position);
      formData.append('contract', contract);
      formData.append('group', group);
      formData.append('stack', stack);
      formData.append('content', content);
      if (mainImage) {
        formData.append('mainImage', mainImage);
      } else {
        formData.append('mainImage', '');
      }
      return await (
        await careersAPI.updatePost({
          id: String(id),
          data: formData,
        })
      ).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const submitApplication = createAsyncThunk(
  'careers/apply',
  async (formData: FormData, thunkAPI) => {
    try {
      await careersAPI.submitApplication(formData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const careersSlice = createSlice({
  name: 'careers',
  initialState,
  reducers: {
    initializeWriteForm: state => {
      state.writeForm = initialState.writeForm;
    },
    onChangeWriteForm: (
      state,
      { payload: { name, value } }: PayloadAction<Form>,
    ) => {
      state.writeForm[name] = value;
    },
    copyWriteForm: (state, action) => {
      state.writeForm = action.payload;
    },
    onChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPosts.pending, state => {
        state.isLoading = true;
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.posts = action.payload;
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(getPosts.rejected, state => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getPositionDetail.pending, state => {
        state.isLoading = true;
      })
      .addCase(getPositionDetail.fulfilled, (state, action) => {
        state.positionDetail = action.payload.data;
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(getPositionDetail.rejected, state => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(createPost.pending, state => {
        state.isLoading = true;
      })
      .addCase(createPost.fulfilled, state => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createPost.rejected, state => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updatePost.pending, state => {
        state.isLoading = true;
      })
      .addCase(updatePost.fulfilled, state => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updatePost.rejected, state => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(submitApplication.pending, state => {
        state.isLoading = true;
      })
      .addCase(submitApplication.fulfilled, state => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(submitApplication.rejected, state => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const {
  initializeWriteForm,
  onChangeWriteForm,
  onChange,
  copyWriteForm,
} = careersSlice.actions;

export default careersSlice.reducer;
