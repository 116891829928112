import styled from 'styled-components';
import {
  AiOutlinePause,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlinePlus,
  AiFillNotification,
  AiOutlineCheck,
} from 'react-icons/ai';
import { BsPlayFill, BsLink45Deg } from 'react-icons/bs';
import { MdCreate, MdOutlineArrowBack } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const IconPlus = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <AiOutlinePlus />
  </IconWrapper>
);

export const IconPause = ({ onClick }: any) => (
  <IconWrapper onClick={onClick}>
    <AiOutlinePause />
  </IconWrapper>
);

export const IconPlay = ({ onClick }: any) => (
  <IconWrapper onClick={onClick}>
    <BsPlayFill />
  </IconWrapper>
);

export const IconWrite = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <MdCreate />
  </IconWrapper>
);

export const IconEdit = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <AiOutlineEdit />
  </IconWrapper>
);

export const IconDelete = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <AiOutlineDelete />
  </IconWrapper>
);

export const IconBack = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <MdOutlineArrowBack />
  </IconWrapper>
);

export const IconHamburger = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <GiHamburgerMenu />
  </IconWrapper>
);

export const IconNotification = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <AiFillNotification />
  </IconWrapper>
);

export const IconCopyLink = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <BsLink45Deg size="24" />
  </IconWrapper>
);

export const IconChecked = ({ onClick, ...rest }: any) => (
  <IconWrapper onClick={onClick} {...rest}>
    <AiOutlineCheck size="24" />
  </IconWrapper>
);
