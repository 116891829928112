import { ReactComponent as Icon1 } from 'assets/icons/about/number1.svg';
import { ReactComponent as Icon2 } from 'assets/icons/about/number2.svg';
import { ReactComponent as Icon3 } from 'assets/icons/about/number3.svg';
import { ReactComponent as Icon4 } from 'assets/icons/about/number4.svg';
import Circle1 from 'assets/imgs/about/circle1.png';
import Circle2 from 'assets/imgs/about/circle2.png';
import Circle3 from 'assets/imgs/about/circle3.png';
import styled from 'styled-components';

export const INTRO_CIRCLES = [
  {
    id: 1,
    image: Circle1,
    text: '혁신',
  },
  {
    id: 2,
    image: Circle2,
    text: '에너지',
  },
  {
    id: 3,
    image: Circle3,
    text: 'AIoT',
  },
];

export const INTRO_BACKGROUND_TEXT = [
  {
    id: 1,
    h3: (
      <>
        씨드앤은
        <br />
        에너지 친화적인 사회를
        <br />
        만들어 갑니다.
      </>
    ),
    p: (
      <>
        '씨드앤(SeedN)'은 건물마다
        <br />
        리프라는 에너지 씨앗(Seed)이 자라
        <br />
        나무가 되어 네트워크(Network)처럼
        <br />
        연결됨을 의미합니다.
        <br />
        작은 매장부터 건물, 더 나아가
        <br />
        도시로 단위를 확장해 가면서
        <br />
        세계 어디서든 네트워크를 형성하겠다는
        <br />
        비전을 내포하고 있습니다.
        <br />
        씨드앤은 에너지제도 혁신을 비롯한
        <br />
        HVAC 시장에
        <br />
        혁신을 가져오기 위해 노력합니다.
      </>
    ),
  },
  {
    id: 2,
    h3: (
      <>
        <span>낭비되는 </span>
        에너지를 모아
        <br />큰 에너지를{` `}
        <span>만듭니다.</span>
      </>
    ),
    p: (
      <>
        씨드앤은 온도에 대한 기준을 만드는 것에서 그치지 않고,
        <br />
        에너지 관리방식의 기준을 만들어 가고 있습니다.
        <br />
        하루하루 작은 단위에서 절감한 에너지들이 쌓여서
        <br />한 달, 1년이 지났을 때 큰 에너지가 될 수 있음을 믿고 있습니다.
      </>
    ),
  },
  {
    id: 3,
    h3: (
      <>
        편리함, 그 이상의
        <br />
        AIoT 스마트 <span>관리 시스템</span>
      </>
    ),
    p: (
      <>
        누구나 쉽게 제어할 수 있는 온도,
        <br />
        씨드앤은 온도 자체에 집중하여
        <br />
        온도에 대한 새로운 기준을 정립합니다.
        <br />
        리프 센서가 수집한 온습도 데이터,
        <br />
        건물 및 날씨데이터를 AI 기술로 조합해
        <br />
        공간 별 최적의 온도를 도출합니다.
        <br />
        지속적인 R&D와 PoC를 통한
        <br />
        현장 검증을 바탕으로
        <br />
        공간의 특성에 따른 온습도 표준화를
        <br />
        가능하게 합니다.
        <br />
        대형 건물에 최적화되어 있던
        <br />
        기존 건물관리시스템의 한계를 뛰어 넘어
        <br />
        모든 공간에 적용 가능한 시스템 혁신을
        <br />
        이루어내고 있습니다.
      </>
    ),
  },
];

export const NEWS_TAB_CONTENT = [
  {
    button: '2023',
    content: [
      {
        title: <>제목</>,
        press: '언론사',
        date: '2023.03.16',
        url: '/',
      },
    ],
  },
  {
    button: 2022,
    content: [
      {
        title: (
          <>
            나우시스템-씨드앤,
            <br />
            실내공기질 관리시스템
            <br />
            고도화 위한 MOU 체결
          </>
        ),
        press: '씨큐리티월드',
        date: '2022.12.16',
        url: '/',
      },
      {
        title: (
          <>
            씨드앤-핀포인트,
            <br />
            건물 AIoT 솔루션 업무협약
          </>
        ),
        press: '머니투데이',
        date: '2022.11.16',
        url: '/',
      },
      {
        title: (
          <>
            씨드앤, COMEUP
            <br />
            2022서 AI·IoT 기반
            <br />
            실내 온도 제어 솔루션
            <br />
            ‘리프(Leaf)’ 알렸다...
          </>
        ),
        press: '에이빙',
        date: '2022.11.10',
        url: '/',
      },
      {
        title: (
          <>
            씨드앤, COMEUP
            <br />
            2022서 AI·IoT 기반
            <br />
            실내 온도 제어 솔루션
            <br />
            ‘리프(Leaf)’ 알렸다...
          </>
        ),
        press: '에이빙',
        date: '2022.11.10',
        url: '/',
      },
      {
        title: (
          <>
            씨드앤, COMEUP
            <br />
            2022서 AI·IoT 기반
            <br />
            실내 온도 제어 솔루션
            <br />
            ‘리프(Leaf)’ 알렸다...
          </>
        ),
        press: '에이빙',
        date: '2022.11.10',
        url: '/',
      },
    ],
  },
  {
    button: '2021',
    content: [
      {
        title: <>제목</>,
        press: '언론사',
        date: '2023.03.16',
        url: '/',
      },
    ],
  },
  {
    button: '2020',
    content: [
      {
        title: <>제목</>,
        press: '언론사',
        date: '2023.03.16',
        url: '/',
      },
    ],
  },
  {
    button: '지난 기사 더보기',
    content: [
      {
        title: <>제목</>,
        press: '언론사',
        date: '2023.03.16',
        url: '/',
      },
    ],
  },
];

export const NUMBER_ITEMS = [
  {
    id: 1,
    title: '8년',
    desc: `
      <p>
        <span>2015년 설립 이후,</span> <span>8년 간 축적된</span>
        <br />
        AIoT 및 데이터 분석 등 <span>기술적 노하우</span>
      </p>
      `,
    icon: <Icon1 />,
  },
  {
    id: 2,
    title: '300여 곳',
    desc: `
      <p>
        <span>실제 리프 서비스를</span>
        <br />
        <span>운영 중인 상업 시설</span>
        <br />약 300여 곳
      </p>
      `,
    icon: <Icon2 />,
  },
  {
    id: 3,
    title: '1억 개',
    desc: `
      <p>
        <span>실제 현장에서 수집한</span>
        <br />
        <span>환경데이터</span>
        <br />약 1억 개 이상
      </p>
      <small>23년 1월 기준</small>
      `,
    icon: <Icon3 />,
  },
  {
    id: 4,
    title: '17%',
    desc: `
    <p>
      <span>리프 사용 후,
      <br />
      기존 설정</span> <span>온도 제어 방식과 비교한</span>
      <br />
      에너지 효율 <span>평균 17% 이상</span>
    </p>
    `,
    icon: <Icon4 />,
  },
];

const Year = styled.h4`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;

  @media (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 23px;
  }
`;

const Flexbox = styled.div`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  @media (max-width: 767px) {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const Month = styled.strong`
  font-weight: 600;
  font-size: 16px;
  opacity: 0.2;
  @media (max-width: 767px) {
    width: 26px;
    font-weight: 700;
    font-size: 12px;
    line-height: 10px;
    opacity: 0.2;
  }
`;

const Desc = styled.p`
  font-weight: 500;
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const HISTORY_TEXT = [
  <>
    <Year>2015년</Year>
    <Flexbox>
      <Month>05월</Month>
      <Desc>씨드앤(SeedN) 법인설립</Desc>
    </Flexbox>
  </>,
  <>
    <Year>2016년</Year>
    <Flexbox>
      <Month>06월</Month>
      <div>
        <Desc>강원지방기상청 ‘날씨데이터+스타트업 Contest’ 최우수상</Desc>
        <Desc>
          사물인터넷 국제전시회 K-Global IoT 챌린지 한국인터넷진흥원장상
        </Desc>
        <Desc>리프 1차 테스트 버전 개발 완료 및 베타테스트</Desc>
      </div>
    </Flexbox>
  </>,
  <>
    <Year>2017년</Year>
    <Flexbox>
      <Month>09월</Month>
      <div>
        <Desc>피츠버그 국제발명전시회 Category of Gadget 금상</Desc>
        <Desc>리프 2차 버전 개발 완료 및 베타테스트</Desc>
      </div>
    </Flexbox>
  </>,
  <>
    <Year>2018년</Year>
    <Flexbox>
      <Month>12월</Month>
      <Desc>리프 3차 버전 개발 완료</Desc>
    </Flexbox>
  </>,
  <>
    <Year>2019년</Year>
    <Flexbox>
      <Month>04월</Month>
      <Desc>리프 정식 온라인 판매 시작</Desc>
    </Flexbox>
    <Flexbox>
      <Month>05월</Month>
      <Desc>삼성 에스원 NDA 체결 및 냉난방 원격관리시스템 공급계약</Desc>
    </Flexbox>
    <Flexbox>
      <Month>06월</Month>
      <div>
        <Desc>
          2019 코엑스 World IT쇼 우수 혁신상 수상 및 혁신 우수사례 선정
        </Desc>
        <Desc>한국 에너지 공단 에너지 적정기술 활용 공모전 우수상</Desc>
      </div>
    </Flexbox>
  </>,
  <>
    <Year>2020년</Year>
    <Flexbox>
      <Month>06월</Month>
      <Desc>투썸플레이스 전국 직영점 공급 계약 체결</Desc>
    </Flexbox>
    <Flexbox>
      <Month>08월</Month>
      <Desc>리프 4차 버전 개발</Desc>
    </Flexbox>
  </>,
  <>
    <Year>2021년</Year>
    <Flexbox>
      <Month>08월</Month>
      <Desc>카카오벤처스 Pre-A 5억원 투자유치</Desc>
    </Flexbox>
  </>,
  <>
    <Year>2022년</Year>
    <Flexbox>
      <Month>06월</Month>
      <div>
        <Desc>씨드앤-SK쉴더스, '친환경' 융합보안 서비스 제공</Desc>
        <Desc>씨드앤, IoT 기반 냉동고 온습도센싱 상품 개발</Desc>
      </div>
    </Flexbox>
    <Flexbox>
      <Month>10월</Month>
      <Desc>하나금융그룹 20억원 투자유치</Desc>
    </Flexbox>
  </>,
];
