import * as authAPI from 'apis/auth';
import client from 'apis/client';
import { onChangeAuth } from 'slices/authSlice';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCheckAuth = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const checkAuth = useCallback(async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      dispatch(onChangeAuth({ name: 'authCheck', value: false }));
      if (pathname.includes('write')) {
        navigate('/careers');
      }
      return false;
    } else {
      try {
        const { accessToken } = await (await authAPI.revoke(refreshToken)).data;
        client.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${accessToken}`;
        dispatch(onChangeAuth({ name: 'authCheck', value: true }));
        return accessToken;
      } catch (e: any) {
        if (e.response?.data.error === 'Refresh Token Expired') {
          localStorage.removeItem('refreshToken');
          dispatch(onChangeAuth({ name: 'authCheck', value: false }));
          navigate('/careers');
          return false;
        }
      }
    }
  }, [navigate, dispatch, pathname]);

  useEffect(() => {
    checkAuth();

    client.interceptors.response.use(undefined, async error => {
      if (
        error.config &&
        error.response &&
        error.response.data.error === 'Access Token Expired'
      ) {
        const accessToken = await checkAuth();
        if (accessToken) {
          error.config.headers.Authorization = `Bearer ${accessToken}`;
          return client.request(error.config);
        } else {
          return;
        }
      }
      return Promise.reject(error);
    });
  }, [checkAuth]);
};
