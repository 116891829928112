import Footer from 'components/common/Footer';
import Header from 'components/common/Header';
import { useCheckAuth } from 'hooks/auth/useCheckAuth';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useHeader from 'hooks/careers/useCareerHeader';
import { Z_INDEX } from 'styles';
import { SCROLL_HEIGHT } from 'constants/careers';

const CareersPage = () => {
  useCheckAuth();

  const { pathname } = useLocation();
  const scrollActive = useHeader();

  return (
    <>
      <Header
        type={pathname === '/careers' && scrollActive ? 'scroll' : 'normal'}
      />
      <Outlet />
      <StyledFooter />
    </>
  );
};

export default CareersPage;

const StyledFooter = styled(Footer)`
  @media (min-height: 1100px) {
    position: absolute;
    z-index: ${Z_INDEX.SECTION};
    width: 100%;
    top: calc(${SCROLL_HEIGHT}px + 280px);
  }
`;
