import { ChangeEvent, FocusEvent, useState } from 'react';
import styled from 'styled-components';
import * as api from 'apis/index';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18n';
import IconLeaf from 'assets/icons/main/customer-leaf.png';
import ApplyButton from 'components/careers/ApplyButton';
import { SEEDN_NEW } from 'styles/palette';
import { useNavigate } from 'react-router-dom';
import Input from 'components/common/Input';
import { validateEmail, validatePhone } from 'utils/validate';

const AskForm = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    company: '',
    manager: '',
    email: '',
    phone: '',
    helpMessage: '',
  });
  const [localError, setLocalError] = useState<any>({});
  const { t } = useTranslation('main');

  const onSubmit = async () => {
    try {
      const response = await api.help(inputs);
      if (response.status === 200) {
        navigate('/ask/result/success');
      }
    } catch (e) {
      navigate('/ask/result/fail');
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    if (name === 'phone') {
      const validateResult = validatePhone(value);
      setLocalError({
        ...localError,
        [name]: validateResult.phone,
      });
    } else if (name === 'email') {
      const validateResult = validateEmail(value);
      setLocalError({
        ...localError,
        [name]: validateResult.email,
      });
    } else {
      if (!value) {
        setLocalError({ ...localError, [name]: '필수항목입니다.' });
      } else {
        setLocalError({ ...localError, [name]: '확인.' });
      }
    }
  };

  return (
    <Container>
      <Title>
        <h1>
          <img src={IconLeaf} alt="icon" />
          <Trans t={t} i18nKey="ask.modal.title" />
        </h1>
        <span>
          <Trans t={t} i18nKey="ask.modal.desc" />
        </span>
      </Title>

      <Index>
        <Label>
          <span className="label">
            <Trans t={t} i18nKey="ask.modal.company" />
          </span>
          {localError.company && (
            <ErrorMessage error={localError.company}>
              {localError.company}
            </ErrorMessage>
          )}
        </Label>
        <AskInput
          type="text"
          name="company"
          value={inputs.company}
          onChange={onChange}
          error={localError.company}
          placeholder={
            i18n.language === 'ko'
              ? '회사명을 입력해주세요.'
              : 'Please enter your company name.'
          }
          onBlur={onBlur}
        />

        <IconDelete onClick={() => setInputs({ ...inputs, company: '' })}>
          X
        </IconDelete>
      </Index>

      <Index>
        <Label>
          <span className="label">
            <Trans t={t} i18nKey="ask.modal.name" />
          </span>
          {localError.manager && (
            <ErrorMessage error={localError.manager}>
              {localError.manager}
            </ErrorMessage>
          )}
        </Label>
        <AskInput
          type="text"
          name="manager"
          value={inputs.manager}
          onChange={onChange}
          error={localError.manager}
          placeholder={
            i18n.language === 'ko'
              ? '성함을 입력해주세요.'
              : 'Please enter your name.'
          }
          onBlur={onBlur}
        />
        <IconDelete onClick={() => setInputs({ ...inputs, manager: '' })}>
          X
        </IconDelete>
      </Index>

      <Index>
        <Label>
          <span className="label">
            <Trans t={t} i18nKey="ask.modal.email" />
          </span>
          {localError.email && (
            <ErrorMessage error={localError.email}>
              {localError.email}
            </ErrorMessage>
          )}
        </Label>
        <AskInput
          type="text"
          name="email"
          value={inputs.email}
          onChange={onChange}
          error={localError.email}
          placeholder={
            i18n.language === 'ko'
              ? '이메일을 입력해주세요.'
              : 'Please enter your email.'
          }
          onBlur={onBlur}
        />

        <IconDelete onClick={() => setInputs({ ...inputs, email: '' })}>
          X
        </IconDelete>
      </Index>

      <Index>
        <Label>
          <span className="label">
            <Trans t={t} i18nKey="ask.modal.contact" />
          </span>
          {localError.phone && (
            <ErrorMessage error={localError.phone}>
              {localError.phone}
            </ErrorMessage>
          )}
        </Label>
        <AskInput
          type="text"
          name="phone"
          error={localError.phone}
          placeholder={
            i18n.language === 'ko'
              ? '하이픈(-)을 포함하여 입력해주세요.'
              : 'Please include Hyphen(-).'
          }
          value={inputs.phone}
          onChange={onChange}
          onBlur={onBlur}
        />
        <IconDelete onClick={() => setInputs({ ...inputs, phone: '' })}>
          X
        </IconDelete>
      </Index>

      <Index>
        <Label>
          <span className="label">
            <Trans t={t} i18nKey="ask.modal.content" />
          </span>
          {localError.helpMessage && (
            <ErrorMessage error={localError.helpMessage}>
              {localError.helpMessage}
            </ErrorMessage>
          )}
        </Label>
        <AskInput
          forwardedAs={'textarea'}
          style={{ height: '156px' }}
          rows={5}
          name="helpMessage"
          value={inputs.helpMessage}
          maxLength={200}
          error={localError.helpMessage}
          placeholder={
            i18n.language === 'ko'
              ? '200자 이내로 입력해주세요.'
              : 'Please enter within 200 characters.'
          }
          onChange={onChange}
          onBlur={onBlur}
        />
      </Index>

      <ButtonGroup>
        <StyledApplyButton color="grey" onClick={() => navigate(-1)}>
          <Trans t={t} i18nKey="ask.modal.cancel" />
        </StyledApplyButton>
        <StyledApplyButton
          disabled={
            !Object.values(localError).every(error => error === '확인.') ||
            Object.values(inputs).some(value => !value)
          }
          onClick={onSubmit}
        >
          <Trans t={t} i18nKey="ask.modal.send" />
        </StyledApplyButton>
      </ButtonGroup>
    </Container>
  );
};

export default AskForm;

const Container = styled.main`
  width: fit-content;
  margin: 0 auto;
`;

const Title = styled.div`
  margin-top: 60px;
  margin-bottom: 45px;

  h1 {
    position: relative;
    margin-bottom: 10px;
    color: #000;
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;

    img {
      position: relative;
      top: -5px;
      width: 46px;
      margin-right: 18px;
    }
  }

  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #7c7c7c;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 53px;

    h1 {
      font-size: 24px;
      line-height: 35px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const Index = styled.div`
  position: relative;
  display: block;
  margin-bottom: 45px;

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

const Label = styled.label`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;

  .label {
    position: relative;

    &::after {
      content: '*';
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #ff0000;
      position: absolute;
      top: -5px;
      right: -6px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0;

    .label::after {
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
    }
  }
`;

const AskInput = styled(Input)<{ error: string }>`
  width: 517px;
  border: ${({ error }) => !error && `1px solid #111111`};
  padding: 18px 20px;
  font-size: 16px;
  line-height: 23px;
  color: #000;

  &::placeholder {
    font-size: 16px;
    color: #aaaaaa;
  }

  @media (max-width: 767px) {
    width: 312px;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      font-size: 14px;
    }
  }
`;

const IconDelete = styled.span`
  position: absolute;
  bottom: 2px;
  right: 24px;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
    bottom: 0;
    right: 12px;
  }
`;

const ErrorMessage = styled.span<{ error: string }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: ${({ error }) => (error === '확인.' ? SEEDN_NEW : '#ff0000')};
  margin-left: 15px;

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    margin-left: auto;
    margin-right: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  margin: 32px 0 97px;

  @media (max-width: 767px) {
    gap: 16px;
    margin: 60px 0 100px;

    span {
      display: none;
    }
  }
`;

const StyledApplyButton = styled(ApplyButton)`
  background-color: ${({ color }) => color !== 'grey' && SEEDN_NEW};
  background-color: ${({ disabled }) => disabled && '#aaa'};

  &:hover {
    background-color: ${({ color }) => color !== 'grey' && '#6421A7'};
    background-color: ${({ disabled }) => disabled && '#aaa'};
  }
`;
