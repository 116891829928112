import { SCROLL_HEIGHT } from 'constants/careers';
import { useState, useEffect } from 'react';

const useCareerHeader = () => {
  const [scrollActive, setScrollActive] = useState(false);

  const handleScroll = () => {
    document.body.scrollTop > 600 &&
    document.body.scrollTop < SCROLL_HEIGHT * 0.8
      ? setScrollActive(true)
      : setScrollActive(false);
  };

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll);
    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };
  });

  return scrollActive;
};

export default useCareerHeader;
