import { useState } from 'react';
import styled from 'styled-components';
import hubImg from 'assets/imgs/service/hubleaf.png';
import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import AnimatedLines from './AnimatedLines';
import smallGradient from 'assets/imgs/service/smallGradient.png';
import bigGradient from 'assets/imgs/service/bigGradient.png';
import SmallAninatedLines from './SmallAnimatedLines';
import useWindowSize from 'hooks/common/useWindowSize';
import { useTranslation, Trans } from 'react-i18next';

export interface StyledProps {
  isTransition: boolean;
}

function HubDesc() {
  const { windowSize } = useWindowSize();
  const { t } = useTranslation('services');
  const isSmallScreen = windowSize < 768;
  const [lineTransition, setLineTransition] = useState(false);
  const [lightTransition, setLightTransition] = useState(false);
  const onIntersectHub: IntersectionObserverCallback = ([
    { isIntersecting },
  ]) => {
    if (isIntersecting) {
      setLineTransition(true);
    }
  };
  const onIntersectBottom: IntersectionObserverCallback = ([
    { isIntersecting },
  ]) => {
    if (isIntersecting) {
      setLightTransition(true);
    }
  };

  const setTarget1 = useIntersectionObserver({
    onIntersect: onIntersectHub,
    threshold: 0.5,
  });
  const setTarget2 = useIntersectionObserver({
    onIntersect: onIntersectBottom,
    threshold: 1,
  });
  return (
    <Container>
      <TopText>
        <Trans t={t} i18nKey="hub.top" />
      </TopText>
      {isSmallScreen ? (
        <SmallAninatedLines isTransition={lineTransition} />
      ) : (
        <AnimatedLines isTransition={lineTransition} />
      )}
      <HubImage src={hubImg} ref={setTarget1} />
      <SmallGradient src={smallGradient} isTransition={lightTransition} />
      <BigGradient src={bigGradient} isTransition={lightTransition} />
      <BottomText ref={setTarget2}>
        {isSmallScreen ? (
          <>
            <Trans t={t} i18nKey="hub.bottomSmall" />
          </>
        ) : (
          <>
            <Trans t={t} i18nKey="hub.bottom" />
          </>
        )}
      </BottomText>
    </Container>
  );
}

const Container = styled.section`
  background: #4f16a9;
  height: 1255px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
  margin-bottom: 296px;
  @media (max-width: 767px) {
    height: 475px;
    margin-bottom: 170px;
  }
`;
const HubImage = styled.img`
  position: absolute;
  width: 955px;
  height: 711px;
  margin: 192px auto;
  @media (max-width: 767px) {
    width: 360px;
    height: 268px;
    margin: 0;
    top: 80px;
  }
`;

const TopText = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 120px;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    top: 44px;
  }
`;

const BottomText = styled.div`
  font-weight: 350;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  bottom: 210px;
  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.8px;
    height: 50px;
    bottom: 58px;
  }
`;

const SmallGradient = styled.img<StyledProps>`
  position: absolute;
  top: 760px;
  width: 100%;
  height: 452px;
  opacity: ${props => (props.isTransition ? 0 : 1)};
  @media (max-width: 767px) {
    top: 300px;
    height: 200px;
  }
`;
const BigGradient = styled.img<StyledProps>`
  position: absolute;
  top: 760px;
  width: 100%;
  height: 652px;
  transition: all 0.5s;
  opacity: ${props => (props.isTransition ? 1 : 0)};
  @media (max-width: 767px) {
    top: 300px;
    height: 300px;
  }
`;

export default HubDesc;
