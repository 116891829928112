import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import { ENTERPRISE_LOGOS } from 'constants/main';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

const Enterprise = () => {
  const { t } = useTranslation('main');
  const [transition, setTransition] = useState(false);
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setTransition(true);
    }
  };
  const setTarget = useIntersectionObserver({ onIntersect });

  return (
    <Container>
      <Title>
        <span>{t('enterprise.1')}</span> <span>{t('enterprise.2')}</span>
      </Title>
      <ScrollWrapper ref={setTarget} className={transition ? 'transition' : ''}>
        <Logos className={transition ? 'transition' : ''}>
          {ENTERPRISE_LOGOS.map(logo => (
            <Circle
              key={logo.src}
              width={logo.width}
              mobileWidth={logo.mobileWidth}
              mobileTop={logo.mobileTop}
            >
              <img src={logo.src} alt="logo" />
            </Circle>
          ))}
          {ENTERPRISE_LOGOS.map(logo => (
            <Circle
              key={logo.src}
              width={logo.width}
              mobileWidth={logo.mobileWidth}
              mobileTop={logo.mobileTop}
            >
              <img src={logo.src} alt="logo" width={logo.width} />
            </Circle>
          ))}
        </Logos>
        <Gradient type="left" />
        <Gradient type="right" />
      </ScrollWrapper>
    </Container>
  );
};

export default Enterprise;

const Container = styled.section`
  padding-top: 400px;

  @media (max-width: 767px) {
    padding-top: 110px;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  color: #111111;
  opacity: 0.8;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      display: block;
    }
  }
`;

const ScrollWrapper = styled.div`
  position: relative;
  width: 1024px;
  height: 294px;
  margin: 0 auto 300px;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 360px;
    height: 103px;
    margin-bottom: 116px;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: absolute;
  left: 0;
  gap: 53px;
  width: 100%;
  height: 100%;
  white-space: nowrap;

  &.transition {
    animation-name: scroll-desktop;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 200ms;

    @media (max-width: 767px) {
      animation-name: scroll-mobile;
    }
  }

  @media (max-width: 767px) {
    gap: 18px;
    padding: 0 18px;
  }

  @keyframes scroll-desktop {
    0% {
      left: 0;
    }

    100% {
      left: -312%;
    }
  }

  @keyframes scroll-mobile {
    0% {
      left: 0;
    }

    100% {
      left: -308%;
    }
  }
`;

const Circle = styled.div<{
  width?: string;
  mobileWidth?: string;
  mobileTop?: string;
}>`
  position: relative;
  min-width: 160px;
  height: 160px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  pointer-events: none;

  img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ width }) => width || '100%'};
  }

  @media (max-width: 767px) {
    min-width: 56px;
    height: 56px;

    img {
      top: ${({ mobileTop }) => mobileTop};
      width: ${({ mobileWidth }) => mobileWidth || '100%'};
    }
  }
`;

const Gradient = styled.div<{ type: string }>`
  position: absolute;
  top: 0;
  left: ${({ type }) => type === 'left' && '0'};
  right: ${({ type }) => type === 'right' && '0'};
  width: 100px;
  height: 100%;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  transform: ${({ type }) => type === 'right' && 'matrix(-1, 0, 0, 1, 0, 0)'};
`;
