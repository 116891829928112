import styled from 'styled-components';
import ImageBackground from 'assets/imgs/main/background.jpg';
import { useTranslation, Trans } from 'react-i18next';
import { BALLOON_IMAGES } from 'constants/main';
import { useState } from 'react';
import useIntersectionObserver from 'hooks/common/useIntersectionObserver';

const Start = () => {
  const { t } = useTranslation('main');
  const [visible, setVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setTimeout(() => {
        setVisible(true);
      }, 100);
    } else {
      setVisible(false);
    }
  };
  const setTarget = useIntersectionObserver({ onIntersect });

  return (
    <Container>
      <Background>
        <ImageWrapper>
          <img
            src={ImageBackground}
            alt="background"
            onLoad={() => setLoaded(true)}
          />
        </ImageWrapper>
        {loaded && <Layer />}
      </Background>
      <StartBlock>
        <Text dangerouslySetInnerHTML={{ __html: t('start.title') }}></Text>
        <Balloons ref={setTarget}>
          {BALLOON_IMAGES.map((image, idx) => (
            <Balloon key={idx} className={visible ? 'visible' : ''}>
              <img src={image.src} alt="말풍선" />
              {image.text && (
                <span>
                  <Trans t={t} i18nKey={`start.balloons.${image.text}`} />
                </span>
              )}
            </Balloon>
          ))}
        </Balloons>
        <Slogan>
          <h3>
            <span
              dangerouslySetInnerHTML={{ __html: t('start.outro.1') }}
            ></span>{' '}
            <span
              dangerouslySetInnerHTML={{ __html: t('start.outro.2') }}
            ></span>{' '}
          </h3>
          <h3 dangerouslySetInnerHTML={{ __html: t('start.outro.3') }}></h3>
        </Slogan>
      </StartBlock>
    </Container>
  );
};

export default Start;

const Container = styled.section`
  position: relative;
  height: 2350px;

  @media (max-width: 767px) {
    height: 1350px;
  }
`;

const StartBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 452px;

  @media (max-width: 767px) {
    top: 258px;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2350px;
  overflow: hidden;

  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    height: 1350px;
  }
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2350px;
  background: linear-gradient(
    180deg,
    #000000 17.93%,
    rgba(0, 0, 0, 0.87) 54.13%,
    rgba(27, 27, 27, 0.72) 73.31%,
    rgba(54, 27, 48, 0.11) 101.85%
  );
  transform: rotate(-180deg);

  @media (max-width: 1024px) {
    background: linear-gradient(
      180deg,
      #000000 42.77%,
      rgba(0, 0, 0, 0.87) 54.78%,
      rgba(27, 27, 27, 0.72) 71.31%,
      rgba(54, 27, 48, 0.11) 101.85%
    );
  }

  @media (max-width: 767px) {
    height: 1350px;
    background: linear-gradient(
      180deg,
      #000000 48.77%,
      rgba(0, 0, 0, 0.87) 57.78%,
      rgba(27, 27, 27, 0.72) 71.31%,
      rgba(54, 27, 48, 0.11) 101.85%
    );
    transform: rotate(-180deg);
  }
`;

const Text = styled.h1`
  font-family: 'Nanum Gothic', sans-serif;
  font-weight: 700;
  font-size: 47.25px;
  line-height: 67px;
  opacity: 0.81;
  color: #fff;
  text-align: center;
  margin-bottom: 554px;
  letter-spacing: -0.01em;

  @media (max-width: 767px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 325px;
  }
`;

const Balloons = styled.div`
  width: 1024px;
  height: 505px;
  position: relative;
  margin-bottom: 220px;

  @media (max-width: 767px) {
    width: 360px;
    height: 410px;
    margin-bottom: 80px;
  }
`;

const Balloon = styled.div`
  position: absolute;
  width: fit-content;
  opacity: 0;
  transition: all 300ms ease-in;

  &.visible {
    opacity: 1;
    transform: translateY(-55px);
  }

  img {
    display: block;
    position: relative;
    width: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    font-size: 20px;
    line-height: 80px;
    color: #111111;

    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &:first-child {
    top: 0;
    left: 102px;

    @media (max-width: 767px) {
      width: 288px;
      left: 27px;
    }
  }

  &:nth-child(2) {
    top: 80px;
    left: 151px;
    transition-delay: 100ms;

    @media (max-width: 767px) {
      width: 180px;
      top: 67px;
      left: 24px;
    }
  }

  &:nth-child(3) {
    top: 114px;
    right: 123px;
    transition-delay: 200ms;

    @media (max-width: 767px) {
      width: 236px;
      top: 97px;
      left: 49px;
    }
  }

  &:nth-child(4) {
    top: 190px;
    left: 210px;
    transition-delay: 300ms;

    @media (max-width: 767px) {
      width: 236px;
      top: 145px;
      left: 63px;
    }
  }

  &:nth-child(5) {
    top: 285px;
    right: 100px;
    transition-delay: 400ms;

    @media (max-width: 767px) {
      width: 312px;
      top: 238px;
      left: 24px;
    }
  }

  &:nth-child(6) {
    top: 318px;
    left: 90px;
    transition-delay: 500ms;

    @media (max-width: 767px) {
      width: 184px;
      top: 198px;
      left: 75px;
    }
  }

  &:nth-child(7) {
    top: 370px;
    right: 121px;
    transition-delay: 600ms;

    @media (max-width: 767px) {
      width: 207px;
      top: 286px;
      left: 133px;
    }
  }

  &:nth-child(8) {
    top: 392px;
    left: 102px;
    transition-delay: 700ms;

    @media (max-width: 767px) {
      width: 288px;
      top: 326px;
      left: 27px;
    }
  }

  &:nth-child(9) {
    top: 470px;
    right: 121px;
    transition-delay: 800ms;

    @media (max-width: 767px) {
      width: 290px;
      top: 384px;
      left: 45px;
    }
  }
`;

const Slogan = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 6rem;
  font-weight: 700;
  font-size: 40px;
  line-height: 80px;
  color: #fff;
  text-align: right;

  h3:first-child {
    font-weight: 400;
    opacity: 0.8;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 120px;
    margin-right: 24px;

    h3:first-child {
      opacity: 0.6;
      margin-bottom: 20px;
    }

    span {
      display: block;
    }

    br {
      display: none;
    }
  }
`;
