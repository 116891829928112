import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Responsive from 'components/common/Responsive';
import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { SEEDN_GREEN, SEEDN_NEW } from 'styles/palette';
import LanguageSwitchButton from './LanguageSwitchButton';
import { ReactComponent as IconHamburger } from 'assets/icons/common/hamburger.svg';
import { ReactComponent as IconClose } from 'assets/icons/common/close.svg';
import { SNS_LINK } from 'constants/main';
import { Sub2, Z_INDEX } from 'styles';
import { ReactComponent as IconTextLogo } from 'assets/icons/common/logo-seedn.svg';
import SymbolLogoPurple from 'assets/icons/common/logo-purple.png';
import SymbolLogoWhite from 'assets/icons/common/logo-white.png';
import { ReactComponent as IconAsk } from 'assets/icons/common/header-ask.svg';
import useChangeLanguage from 'hooks/common/useChangeLanguage';
import { ReactComponent as IconMypage } from 'assets/icons/common/mobile-mypage.svg';
import { ReactComponent as IconLogin } from 'assets/icons/common/mobile-login.svg';
import useWindowSize from 'hooks/common/useWindowSize';

const NavType = {
  Main: '/',
  About: '/about',
  Services: '/services',
  Careers: '/careers',
};

interface IHeader {
  type?: string;
  className?: string;
}

const NavItem = ({ className, item, index, type, onClick }: any) => {
  const { t } = useTranslation('header');

  return (
    <NavItemBlock onClick={onClick} className={className} type={type}>
      <Link to={item}>
        <Trans t={t} i18nKey={`navList.${index}`} />
      </Link>
    </NavItemBlock>
  );
};

const Header = ({ type = 'normal', className }: IHeader) => {
  const [headerType, setHeaderType] = useState(type);
  const [isMenu, setIsMenu] = useState(false);
  const { pathname } = useLocation();
  const { language, changeLanguage } = useChangeLanguage();
  const isMobile = useWindowSize().windowSize! < 768;

  useEffect(() => {
    if (isMenu) document.body.style.overflow = 'hidden';
    else document.body.style.overflowY = 'overlay';

    if (isMenu) {
      setHeaderType('normal');
    }
  }, [isMenu]);

  useEffect(() => {
    setHeaderType(type);
  }, [setHeaderType, type]);

  return (
    <>
      <Container type={headerType} className={className}>
        <HeaderBlock type={headerType} isMenu={isMenu} pathname={pathname}>
          <HeaderItems>
            <Logo type={headerType}>
              <LogoLink to="/">
                <SymbolLogoImg
                  src={
                    headerType === 'normal' ? SymbolLogoPurple : SymbolLogoWhite
                  }
                />
                <TextLogo>
                  {!isMobile && (
                    <TextLogoImg
                      fill={headerType === 'normal' ? '#090000' : '#fff'}
                    />
                  )}
                  {!isMobile && pathname.includes('/careers') && (
                    <Link to="/careers" className="green">
                      careers
                    </Link>
                  )}
                </TextLogo>
              </LogoLink>
            </Logo>
            <MenuBlock type={headerType}>
              {language === 'ko'
                ? Object.values(NavType).map((item, index) => (
                    <NavItem key={item} item={item} index={index} type={type} />
                  ))
                : Object.values(NavType)
                    .filter(item => item !== '/careers')
                    .map((item, index) => (
                      <NavItem
                        key={item}
                        item={item}
                        index={index}
                        type={type}
                      />
                    ))}
            </MenuBlock>
            <ButtonBlock>
              {!pathname.includes('/careers') && (
                // <Button
                //   language={language}
                //   className="language"
                //   as="button"
                //   onClick={changeLanguage}
                //   header={headerType}
                // >
                //   {language === 'ko' ? 'EN' : 'KO'}
                // </Button>
                <LanguageSwitchButton />
              )}
            </ButtonBlock>
            <MobileIcon>
              {!isMenu ? (
                <IconHamburger
                  fill={headerType === 'scroll' ? '#fff' : '#000'}
                  onClick={() => setIsMenu(prev => !prev)}
                />
              ) : (
                <IconClose
                  fill={headerType === 'scroll' ? '#fff' : '#000'}
                  onClick={() => setIsMenu(prev => !prev)}
                />
              )}
            </MobileIcon>
          </HeaderItems>
        </HeaderBlock>

        {/* Mobile */}
        {isMenu && (
          <MobileMenuBlock>
            <MobileNavList>
              {/* {Object.values(NavType).map((item, index) => (
                <NavItem
                  key={item}
                  item={item}
                  index={index}
                  type={headerType}
                  onClick={() => setIsMenu(false)}
                  className={
                    item !== '/careers' && item === pathname
                      ? 'active'
                      : item === '/careers' && pathname.includes('/careers')
                      ? 'active careers'
                      : ''
                  }
                />
              ))} */}
              {language === 'ko'
                ? Object.values(NavType).map((item, index) => (
                    <NavItem
                      key={item}
                      item={item}
                      index={index}
                      type={headerType}
                      onClick={() => setIsMenu(false)}
                      className={
                        item !== '/careers' && item === pathname
                          ? 'active'
                          : item === '/careers' && pathname.includes('/careers')
                          ? 'active careers'
                          : ''
                      }
                    />
                  ))
                : Object.values(NavType).map((item, index) => (
                    <NavItem
                      key={item}
                      item={item}
                      index={index}
                      type={headerType}
                      onClick={() => setIsMenu(false)}
                      className={
                        item === pathname
                          ? 'active'
                          : item === '/careers'
                          ? 'hidden'
                          : ''
                      }
                    />
                  ))}
            </MobileNavList>
            <MobileNavButtons>
              <ButtonGroup>
                {/* {isLogin ? (
                  <LoginButton to="/mypage">
                    <IconMypage />
                    <Sub2>MY</Sub2>
                  </LoginButton>
                ) : (
                  <LoginButton to="/login">
                    <IconLogin />
                    <Sub2>LOGIN</Sub2>
                  </LoginButton>
                )} */}
                {!pathname.includes('/careers') && <LanguageSwitchButton />}
              </ButtonGroup>
              <SNSLinks>
                <a href={SNS_LINK.instagram} target="_blank" rel="noreferrer">
                  INSTAGRAM
                </a>
                <a href={SNS_LINK.notion} target="_blank" rel="noreferrer">
                  NOTION
                </a>
              </SNSLinks>
            </MobileNavButtons>
            <Line />
          </MobileMenuBlock>
        )}
      </Container>
      {isMenu && <Layer onClick={() => setIsMenu(prev => !prev)} />}
    </>
  );
};

const Container = styled.header<IHeader>`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.HEADER};
  background-color: ${({ type }) =>
    type === 'scroll' ? 'transparent' : '#fff'};
  width: 100%;
  color: ${({ type }) => (type === 'scroll' ? '#fff' : '#000')};
`;

const HeaderBlock = styled(Responsive)<{
  isMenu: boolean;
  type?: string;
  pathname?: string;
}>`
  height: 60px;
  width: 100%;
  border-bottom: ${({ isMenu, type, pathname }) =>
    type !== 'scroll' &&
    !isMenu &&
    pathname === '/careers' &&
    `2px ${SEEDN_GREEN} solid`};

  @media (max-width: 767px) {
    height: fit-content;
  }
`;

const HeaderItems = styled.div`
  width: auto;
  height: 100%;
  position: relative;
  margin: 0 5vw;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    height: fit-content;
    padding: 24px 32px;
    margin: 0 auto;
  }
`;

const Logo = styled.div<IHeader>`
  width: fit-content;
  color: ${({ type }) => (type === 'scroll' ? '#fff' : '#000')};
  .green {
    color: ${({ type }) => (type === 'scroll' ? '#fff' : SEEDN_GREEN)};
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const SymbolLogoImg = styled.img`
  width: 20px;

  @media (max-width: 767px) {
    width: 33px;
    height: 43px;
  }
`;

const TextLogoImg = styled(IconTextLogo)`
  width: 70px;
`;

const TextLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: 'EliceDigitalBaeum';
  font-size: 13px;
`;

const MobileIcon = styled.span`
  width: 20px;
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
`;

const MenuBlock = styled.div<IHeader>`
  display: flex;
  align-items: center;
  position: relative;
  gap: 7vw;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  & > span + span {
    margin-left: 32px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  /* width: 168px; */

  @media (max-width: 767px) {
    display: none;
  }
`;

const Button = styled(Link)<{ header?: string; language?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 60px;
  color: ${({ header }) => (header === 'scroll' ? '#fff' : SEEDN_NEW)};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  &.language {
    ${({ language }) =>
      language === 'ko'
        ? css`
            color: ${SEEDN_NEW};
          `
        : css`
            color: #fff;
          `}
    ${({ header, language }) =>
      header === 'normal' && language === 'ko'
        ? css`
            color: #fff;
            background-color: ${SEEDN_NEW};
          `
        : header === 'normal' && language === 'en'
        ? css`
            color: ${SEEDN_NEW};
          `
        : ''}
  }

  &.mypage {
    background-color: #fff;
  }
`;

const MobileMenuBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  width: 100%;
  z-index: ${Z_INDEX.HEADER_MENU};

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileNavList = styled.div`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
`;

const NavItemBlock = styled.div<{ type: string }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  position: relative;
  width: fit-content;

  &:hover {
    color: ${({ type }) => (type === 'scroll' ? '#fff' : 'rgba(0,0,0,0.5)')};
  }

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #aaa;

    &.hidden {
      visibility: hidden;
    }

    &.active {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #000000;

      &::before {
        position: absolute;
        top: 0;
        left: -6px;
        width: 6px;
        height: 6px;
        background-color: ${SEEDN_NEW};
        border-radius: 50%;
        content: '';
      }
    }

    &.active.careers {
      color: ${SEEDN_GREEN};

      &::before {
        background-color: #000;
      }
    }
  }
`;

const MobileNavButtons = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
`;

const LoginButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 92px;
  height: 34px;
  border-radius: 20px;
  background-color: ${SEEDN_NEW};
  color: #fff;
`;

const SNSLinks = styled.div`
  display: flex;
  gap: 30px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #aaa;
  margin-bottom: 20px;
`;

const Line = styled.div`
  width: 100px;
  height: 2px;
  background: #d9d9d9;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${Z_INDEX.HEADER_LAYER};
`;

export default Header;
