import { onChangeAuth } from 'slices/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem('refreshToken');
    dispatch(onChangeAuth({ name: 'authCheck', value: false }));
    navigate('/careers');
    document.body.scrollTo(0, 0);
  };

  return logout;
};

export default useLogout;
