import React from 'react';
import styled, { css } from 'styled-components';
import { mobileStretch, stretch } from 'styles/animations';
import { StyledProps } from './HubDesc';

function SmallAnimatedLines({ isTransition }: { isTransition: boolean }) {
  return (
    <Lines>
      <SvgLeft width="90" height="10" isTransition={isTransition}>
        <line
          x1="90"
          y1="5"
          x2="0"
          y2="5"
          stroke="#fff"
          strokeWidth="2"
          className="line"
        />
      </SvgLeft>
      <SvgRight width="90" height="10" isTransition={isTransition}>
        <line
          x1="0"
          y1="5"
          x2="90"
          y2="5"
          stroke="#fff"
          strokeWidth="2"
          className="line"
        />
      </SvgRight>
      <LineLeft width="2" height="10" isTransition={isTransition}>
        <line x1="0" y1="0" x2="0" y2="10" stroke="#fff" strokeWidth="2" />
      </LineLeft>
      <LineRight width="1" height="10" isTransition={isTransition}>
        <line x1="0" y1="0" x2="0" y2="10" stroke="#fff" strokeWidth="2" />
      </LineRight>
    </Lines>
  );
}

const Lines = styled.div`
  position: relative;
  width: 80px;
  height: 10px;
  top: 74px;
`;

const SvgLeft = styled.svg<StyledProps>`
  position: absolute;
  right: 70px;
  .line {
    stroke-dasharray: 100;
    stroke-dashoffset: 90;
    ${({ isTransition }) =>
      isTransition &&
      css`
        animation: ${mobileStretch} 1s forwards linear;
      `}
  }
`;

const SvgRight = styled.svg<StyledProps>`
  position: absolute;
  left: 70px;
  .line {
    stroke-dasharray: 100;
    stroke-dashoffset: 90;
    ${({ isTransition }) =>
      isTransition &&
      css`
        animation: ${mobileStretch} 1s forwards linear;
      `}
  }
`;

const LineLeft = styled.svg<StyledProps>`
  position: absolute;
  left: 0;
  transition: all 0.9s linear;
  ${({ isTransition }) =>
    isTransition &&
    css`
      transform: translateX(-80px);
    `}
`;
const LineRight = styled.svg<StyledProps>`
  position: absolute;
  right: 0;
  transition: all 0.9s linear;
  ${({ isTransition }) =>
    isTransition &&
    css`
      transform: translateX(80px);
    `}
`;

export default SmallAnimatedLines;
