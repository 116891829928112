import { RootState } from 'index';
import { useDispatch, useSelector } from 'react-redux';
import * as authAPI from 'apis/auth';
import { initializeLoginForm, onChangeAuth } from 'slices/authSlice';
import client from 'apis/client';
import { useEffect } from 'react';

export const useLoginForm = (onClose?: any) => {
  const dispatch = useDispatch();
  const { userId, password, error } = useSelector(
    ({ auth }: RootState) => auth,
  );

  const login = async () => {
    try {
      const { accessToken, refreshToken } = await (
        await authAPI.login(userId, password)
      ).data;

      dispatch(onChangeAuth({ name: 'authCheck', value: true }));

      localStorage.setItem('refreshToken', refreshToken);

      client.defaults.headers.common[
        'Authorization'
      ] = `Bearder ${accessToken}`;

      onClose();
      document.body.scrollTo(0, 0);
    } catch (e: any) {
      dispatch(
        onChangeAuth({ name: 'error', value: e.response?.data.message }),
      );
      dispatch(onChangeAuth({ name: 'authCheck', value: false }));
    }
  };

  useEffect(() => {
    dispatch(initializeLoginForm());
  }, [dispatch]);

  const onChange = (event: any) => {
    const { name, value } = event.target;
    dispatch(onChangeAuth({ name, value }));
  };

  return { userId, password, error, onChange, login };
};
