/* eslint import/no-anonymous-default-export: 0 */
export default {
  mon: 'M ',
  thuToSun: 'T W R F S U',
  wedToFri: ' W R F',
  monToTue: 'M T ',
  SatToSun: ' S U ',
  monToFri: 'M T W R F ',
  hero: {
    small: 'Make Your Temperature Management Easy',
    mobilesmall: 'Make Your<br/> Temperature Management <br/>Easy',
    big1: 'AIoT-based',
    //line-height: 60px;
    big2: 'Temperature Management',
    big3: 'Solution, Leaf',
    outline: 'OVERVIEW',
    //간격 띄우기
    spec: 'SPEC',
  },
  control: {
    purple: 'APP Remote Control',
    big1: 'From power on/off ',
    big2: 'to operation mode, temperature,',
    big3: 'and fan speed control it the way you want.',
    normal:
      'Users can control it from anywhere at any time.<br />Real-time control enables active HVAC and energy management.',
    controllersName1: 'Group Management',
    controllersName2: 'Schedule Setting',
    controllersName3: 'Timer Function',
    //    line-height: 16px;
    purpleBox1: 'Simultaneous control of multiple AC as a group',
    purpleBox2:
      'Automatic schedule management <br/>by setting day-by-day operating hours',
    purpleBox3: 'Operate at desired times using timer function',
  },
  user: {
    multi: 'Simultaneous Control',
    bigRightSmall1: 'Log in to the Leaf app',
    bigRightSmall2: 'from multiple devices',
    bigRightSmall3: 'with one ID, like a',
    bigRightSmall4: 'single administrator,',
    bigRightSmall5: 'even with multiple',
    // administrators 내리기
    bigRight1: 'You can log in',
    bigRight2: 'to the Leaf app',
    bigRight3: 'with one ID',
    normal:
      'You can easily log in with <br/> just one ID and password.<br />Simultaneous access is possible,<br/> and it is synchronized in <br/>real-time in the app.',
  },
  ai: {
    purple: 'AI Auto Management',
    big1: "You don't have to worry about",
    big2: 'which AC to turn on/off',
    big3: 'or how to control the temperature.',
    //letter-spacing 0.015
    normal:
      "It's okay even if you don't look into the app.<br/> Seed AI takes care of everything.<br />You can manage your AC in the most optimal way <br/> to save costs and reduce energy waste.",
    imageSmall1: 'Even in the same place,',
    imageSmall2: 'Not everyone feels the same temperature.',
    imageSmall3: 'By learning only the data of heat in the same space,',
    imageSmall4:
      'energy management within the space is possible according to its usage.',
    image1: 'Even in the same place,',
    image2: 'Not everyone feels the same temperature.',
    image3: 'Energy management should be different',
    image4: 'depending on how the space is used.',
    image5: 'Classify spaces by usage,',
    image6: 'and learn only the data of',
    image7: 'heat in the same space to enable ',
    image8: 'energy management within the space according to its usage.',
  },
  hub: {
    top: 'Wide communication range of over  <br /> 20m',
    bottomSmall:
      'Stable communication <br/> without being affected by obstacles. <br /> Automatic recovery function<br/> even in case of WiFi connection failure.',
    bottom:
      'Stable communication <br/> without being affected by obstacles. <br /> Automatic recovery function <br/> even with WiFi connection failure.',
  },
  sync: {
    purple: 'Sync Function',
    big1: 'Every 1 hour,',
    big2: 'synchronize with AC.',
    smallBig3: 'Operate more <br /> thoroughly',
    big3: 'More thorough operation of AC.',
    normal:
      'Send the most recent command to the AC <br/>every 1 hour to eliminate random operation <br/>variables caused by remote control.',
    gray: '*Arbitrary operation <br>by remote control is not reflected <br/>in the Leaf app.',
  },
  advantage: {
    gray1: 'To install',
    gray2: 'Over',
    gray3: 'Continuous',
    underline1: '10 Seconds',
    underline2: '3 years',
    underline3: 'After Service',
    normal1: 'Even if the AC is old',
    normal2: 'Without damaging <br/> existing equipment',
    normal3: 'Just attach it with 3M tape',
    normal4: '',
    normal5: 'Designed with ',
    normal6: 'low power consumption',
    normal7: 'Based on Bluetooth 4.2',
    normal8: 'We provide',
    normal9: 'stable after service',
    normal10: 'when any inconvenience',
    normal11: 'arises using Leaf',
  },
  payment: {
    title: 'Choose payment options<br/> according to your needs.',
    //예쁘게 올려놓기
    black1: 'Single product',
    black2: 'Enjoy Leaf and',
    tempblack2: 'change your',
    black3: 'lifestyle without pressure',
    star: '*AI auto mode is not supported for single payment.',
    white1: 'Monthly subscription',
    white2: 'Experience more precise',
    white3: 'energy operation management',
    white4: ' management with',
    white5: 'AI auto mode',
    starSmall:
      '*Arbitrary operation <br/> by remote control is not reflected in the Leaf app.',
    whiteSmall3: '',
    whiteSmall4: 'energy operation management',
  },
  flow: {
    server: 'Server',
    ac: 'AC',
    hub: 'Hub',
    hubText1:
      'Acts as a relay to allow users <br/> to use various functions <br/>even from a distance, <br />connected to indoor Wi-Fi router.',
    hubText2:
      'Up to 8 sensors and controllers <br/> can be connected via Bluetooth to one hub.',
    sensor: 'Sensor',
    sensorText1:
      'Connected to the hub via Bluetooth, senses <br/>and learns indoor temperature and <br/>humidity in real-time.',
    sensorText2:
      'Can be attached to the wall or placed in <br/> a desired location for use.',
    controller: 'Controller',
    controllerText1:
      'Connected to the hub via Bluetooth, can be connected to up to 7 hubs for use.',
    controllerText2:
      'Attached to the AC, controls the AC <br/>in real-time with IR signals.',
  },
  spec: {
    size: 'Size',
    color: 'Color',
    conn: 'Connection',
    charge: 'Charging',
    hubSize: 'Width 139.5mm / Height 72.0mm',
    hubSizeHorizontal: 'Width 139.5mm',
    hubSizeVertical: 'Height 72.0mm',
    white: 'Matte White & Gray',
    lowEnergy: 'Up to 3 years of usage with low energy sequence applied',
    lowEnergySmall: 'Up to 3 years of usage',
    battery: '2 AA batteries',
    black: 'Matte Black',
    sensorSize: 'Width 72.0mm / Height 30.5mm',
    sensorSizeHorizontal: 'Width 72.0mm',
    sensorSizeVertical: 'Height 30.5mm',
    device: 'AC',
    ir: '360° Infrared (IR) signal',
    controllerArea: 'Communication range of 0.75M',
    hubArea: 'Communication range of over 20M',
    divide: 'Sensor/Controller',
  },
};
