import styled, { css } from 'styled-components';
import { SEEDN_NEW } from 'styles/palette';
import { NUMBER_ITEMS } from 'constants/about';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

const Number = () => {
  const { t } = useTranslation('about');
  const { language } = useChangeLanguage();
  return (
    <Container en={language === 'en'}>
      <Title>{t('number.title')}</Title>
      <Flexbox en={language === 'en'}>
        {NUMBER_ITEMS.map(item => (
          <Item key={item.title} en={language === 'en'}>
            <SubTitleWrapper>
              <SubTitle>
                <span>
                  <Trans t={t} i18nKey={`number.items.${item.id}.title`} />
                </span>
                <Shadow />
              </SubTitle>
              {item.icon}
            </SubTitleWrapper>
            <Desc
              dangerouslySetInnerHTML={{
                __html: t(`number.items.${item.id}.desc`),
              }}
            />
          </Item>
        ))}
      </Flexbox>
    </Container>
  );
};

const Container = styled.section<{ en?: boolean }>`
  width: 100%;
  margin: 345px auto 0;
  padding: ${({ en }) => (en ? `128px 0 500px` : `128px 0 300px`)};

  @media (max-width: 767px) {
    width: 360px;
    padding: 98px 0 180px;
    margin-top: 0;
  }
`;

const Title = styled.h1`
  width: fit-content;
  padding: 14px 40px 14px 86px;
  margin-bottom: 140px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  background-color: ${SEEDN_NEW};
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
  white-space: nowrap;

  @media (max-width: 767px) {
    position: relative;
    z-index: 20;
    background-color: transparent;
    padding: 0 0 0 20px;
    /* mobile H2 */
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 25px;
  }
`;

const Flexbox = styled.div<{ en: boolean }>`
  width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 232px;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0 auto;
    padding-top: 62px;
    gap: 45px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 400vw;
      height: 400vw;
      border-radius: 50%;
      background-color: #fff;
    }

    ${({ en }) =>
      en &&
      css`
        gap: 60px;
      `}
  }
`;

const Item = styled.div<{ en: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  color: #4f16a9;
  text-align: center;
  white-space: nowrap;
  z-index: 20;

  svg {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }

  &:hover {
    span,
    p {
      animation: slide-down 1.5s;
      animation-fill-mode: forwards;
    }

    svg {
      opacity: 1;
    }
  }

  &:not(:hover) {
    span,
    p {
      animation: hover-out 1.3s;
      animation-fill-mode: forwards;
    }
  }

  @media (min-width: 767px) {
    width: ${({ en }) => en && '1000px'};
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0px);
    }
    20% {
      transform: translateY(7px);
    }
    40% {
      transform: translateY(3px);
    }
    60% {
      transform: translateY(4px);
    }
    80% {
      transform: translateY(2px);
    }
    100% {
      transform: translateY(3px);
    }
  }

  @keyframes hover-out {
    0% {
      transform: translateY(5px);
    }
    25% {
      transform: translateY(2px);
    }
    50% {
      transform: translateY(4px);
    }
    75% {
      transform: translateY(2px);
    }
    100% {
      transform: translateY(3px);
    }
  }
`;

const SubTitleWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: -23px;
    right: 95%;

    @media (max-width: 767px) {
      width: 45%;
      height: 45%;
      top: -13px;
      right: 78%;
    }
  }
`;

const SubTitle = styled.h3`
  position: relative;
  width: fit-content;
  padding: 0 10px;
  margin-bottom: 34px;
  font-weight: 700;
  font-size: 75px;
  line-height: 95px;
  overflow: hidden;

  span {
    display: block;
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 0;

    span {
      margin-bottom: 2.5px;
    }
  }
`;

const Desc = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;

  small {
    font-size: 8px;
  }

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    small {
      font-size: 4px;
      display: inline-block;
      margin-top: 10px;
    }

    br {
      display: none;
    }

    span {
      display: block;
    }
  }
`;

const Shadow = styled.div`
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: 100px;
  box-shadow: 0 -3.7878px 18.939px -2px rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    height: 60px;
    bottom: -60px;
  }
`;

export default Number;
