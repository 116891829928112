/* eslint import/no-anonymous-default-export: 0 */
export default {
  mon: '월',
  thuToSun: '화 수 목 금 토 일',
  wedToFri: '수 목 금',
  monToTue: '월 화',
  SatToSun: '토 일',
  monToFri: '월 화 수 목 금',
  hero: {
    small: '당신의 온도관리를 쉽게',
    mobilesmall: '당신의 온도관리를 쉽게',
    big1: 'AIoT 기반의',
    big2: '온도관리 솔루션',
    big3: '리프 (Leaf) 를 소개합니다.',
    outline: '개요',
    spec: '기술사양',
  },
  control: {
    purple: 'APP원격제어',
    big1: '전원 on/off 부터',
    big2: '운전 모드, 온도, 바람 세기까지 원하는대로',
    big3: '가까이 있지 않아도, 직접 제어하지 않아도 가능해요.',
    normal:
      '사용자가 언제 어디서든지 원하는대로 제어할 수 있어요.<br />실시간 제어로 주도적인 냉난방시스템 및 에너지 관리가 가능해요.',
    controllersName1: '그룹관리',
    controllersName2: '스케줄 설정',
    controllersName3: '타이머 기능',
    purpleBox1: '여러 대의 에어컨을 그룹으로 동시 제어',
    purpleBox2: '요일별 운영 시간 설정으로 자동 운영 스케쥴 관리',
    purpleBox3: '타이머 기능으로 원하는 시간만 운영',
  },
  user: {
    multi: '동시제어',
    bigRightSmall1: '다수의 관리자도',
    bigRightSmall2: '한 명의 관리자처럼,',
    bigRightSmall3: '하나의 아이디로 ',
    bigRightSmall4: '여러 휴대폰에서',
    bigRightSmall5: '리프 앱 로그인이 가능해요.',
    bigRight1: '다수의 관리자도 한 명의 관리자처럼,',
    bigRight2: '하나의 아이디로 여러 휴대폰에서',
    bigRight3: '리프 앱 로그인이 가능해요.',
    normal:
      '아이디와 패스워드만 알면 쉽게 로그인 할 수 있어요.<br />동시 접속이 가능하고 실시간으로 동기화되어 앱에 반영돼요.',
  },
  ai: {
    purple: 'AI 자동관리',
    big1: '어떤 에어컨을 켜고 끌지',
    big2: '몇도로 제어할지 고민하지 않아도',
    big3: '최적의 냉난방기 운영 조합으로 관리해요.',
    normal:
      ' 앱을 들여다보지 않아도 괜찮아요. Seed AI가 다 알아서 해주거든요.<br />비용을 절감하고, 에너지 낭비를 줄일 수 있는 최적의 방식으로 에어컨을 관리할 수 있어요.',
    imageSmall1: '같은 장소에 있어도',
    imageSmall2: '모두가 같은 온도로 <br />느끼지는 않죠.',
    imageSmall3: '같은 공간의 열에 대한 <br />데이터만 학습하여',
    imageSmall4: '용도에 따라 공간 내<br/>에너지 관리가 가능해요.',
    image1: '같은 장소에 있어도',
    image2: '모두가 같은 온도로 느끼지는 않죠.',
    image3: '공간을 어떻게 사용하느냐에 따라',
    image4: '에너지 관리도 달라야 해요.',
    image5: '같은 용도의 공간만 묶어서 관리해요.',
    image6: '공간을 용도에 따라 분류하고,',
    image7: '같은 공간의 열에 대한 데이터만 학습하여',
    image8: '용도에 따라 공간 내 에너지 관리가 가능해요.',
  },
  hub: {
    top: '좌우 폭 20m 이상의 통신 범위로 <br /> 넓게',
    bottomSmall:
      '장애물의 영향을 받지 않아 <br /> 안정적인 통신이 가능해요. <br /> WiFi 통신 장애에도 자동복구 기능으로 걱정 없어요.',
    bottom:
      '장애물의 영향을 받지 않아 안정적인 통신이 가능해요. <br /> WiFi 통신 장애에도 자동복구 기능으로 걱정 없어요.',
  },
  sync: {
    purple: '동기화 기능',
    big1: '1시간마다',
    big2: '냉난방기와 동기화 기능으로',
    smallBig3: '더 철저하게 <br />냉난방 시스템을 운영해요.',
    big3: '더 철저하게 냉난방 시스템을 운영해요.',
    normal:
      '1시간마다 냉난방기에 가장 최근의 명령을 내려<br /> 리모콘으로 인한 임의조작 변수를 제거해요.',
    gray: '*리모콘으로 인한 외부 임의조작은 리프 어플리케이션에 반영되지 않습니다.',
  },
  advantage: {
    gray1: '불필요한',
    gray2: '최대',
    gray3: '지속적인',
    underline1: '시공 없이',
    underline2: '3년 사용',
    underline3: '사후 관리',
    normal1: '에어컨이 오래 되어도',
    normal2: '기존 설비를 해치지 않아도',
    normal3: '3M 테이프로 붙이기만 하면',
    normal4: '설치 완료',
    normal5: 'Bluetooth 4.2 기반',
    normal6: '저전력으로 설계되어',
    normal7: '장시간 사용 가능',
    normal8: '리프 사용 중에',
    normal9: '불편한 사항이 생겨도',
    normal10: 'A/S를 통해 안정적으로',
    normal11: '더 오래 사용 가능',
  },
  payment: {
    title: '필요에 따라 결제 옵션을 선택하세요.',
    black1: '단품 결제로',
    black2: '리프와 일상의 변화를',
    black3: '부담 없이 누리세요',
    star: '*단품결제는 AI 자동모드를 지원하지 않습니다.',
    white1: '월 구독방식의',
    white2: '멤버십에 가입하시면',
    white3: 'AI자동모드로 더 정밀한',
    white4: '최적의 에너지 운영관리를',
    white5: '경험할 수 있어요',
    starSmall:
      '*리모콘으로 인한 외부 임의조작은 <br/> 리프 어플리케이션에 반영되지 않습니다.',
    whiteSmall3: 'AI자동모드로',
    whiteSmall4: '더 정밀한',
  },
  flow: {
    server: '서버',
    ac: '에어컨',
    hub: '허브',
    hubText1:
      '실내 Wi-Fi 공유기와 연결되어 사용자가<br />멀리서도 다양한 기능을 사용할 수 있도록<br />중계기 역할을 합니다.',
    hubText2:
      '1대 허브에 최대 8대의 센서, 컨트롤러가<br /> Bluetooth로 연결되어 사용할 수 있습니다.',
    sensor: '센서',
    sensorText1:
      '허브와 Bluetooth로 연결되며 실내 온도와 <br />습도를 실시간 센싱 및 학습하는 역할을 합니다.',
    sensorText2:
      '벽에 부착 또는 원하는 곳에 거치하여 <br />사용할 수 있습니다.',
    controller: '컨트롤러',
    controllerText1:
      '허브와 Bluetooth로 연결되며 최대 7대까지<br />허브와 연결하여 사용할 수 있습니다.',
    controllerText2:
      '에어컨에 부착되어<br />에어컨을 IR신호로 실시간 제어합니다.',
  },
  spec: {
    size: '크기',
    color: '색상',
    conn: '연결',
    charge: '충전',
    hubSize: '가로 139.5mm / 높이 72.0mm',
    hubSizeHorizontal: '가로 139.5mm',
    hubSizeVertical: '높이 72.0mm',
    white: '무광 화이트 & 그레이',
    lowEnergy: '저전력 시퀀스로 최대 3년 사용',
    lowEnergySmall: '저전력 시퀀스로 <br/> 최대 3년 사용',
    battery: 'AA 배터리 2pcs',
    black: '무광 블랙',
    sensorSize: '가로 72.0mm / 세로 30.5mm',
    sensorSizeHorizontal: '가로 72.0mm',
    sensorSizeVertical: '세로 30.5mm',
    device: '냉난방기',
    ir: '360° 적외선 (IR) 신호',
    controllerArea: '통신반경 0.75M',
    hubArea: '통신반경 20M 이상',
    divide: '센서/컨트롤러',
  },
};
