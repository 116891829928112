import styled from 'styled-components';
import imgBanner from 'assets/imgs/common/user-guide.png';
import Responsive from './Responsive';
import Background from './Background';

const StyledBackground = styled(Background)`
  height: 120px;

  @media (min-width: 480px) {
    height: 170px;
  }

  @media (min-width: 767px) {
    height: 280px;
  }
`;

const UserGuideBlock = styled(Responsive)`
  padding: 2rem 2rem;

  .index-title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 60px;
  }

  .index {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .content {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 28px;
  }

  @media (max-width: 767px) {
    .index {
      font-size: 16px;
    }

    .content {
      font-size: 14px;
    }
  }

  @media (max-width: 479px) {
    .index {
      font-size: 14px;
    }

    .content {
      font-size: 12px;
    }
  }
`;

const UserGuide = () => {
  return (
    <>
      {/* <StyledBackground img={imgBanner} /> */}
      <UserGuideBlock>
        <div className="index-title">이용약관</div>
        <div className="index">제1조 (목적)</div>
        <div className="content">
          이 약관은 (주)씨드앤 (이하“회사”)과 이용 고객(이하 “회원”)간에
          “회사”가 제공하는 서비스의 가입조건 및 이용에 관한 다음의 제반 사항과
          기타 기본적인 사항을 구체적으로 규정함을 목적으로 합니다.
        </div>
        <div className="index">제2조 (정의)</div>
        <div className="content">
          1. “회원”이란 서비스를 이용하기 위하여 회사와 이용계약을 체결하고
          이용자 아이디와 비밀번호를 부여 받은 자를 말합니다.
          <br />
          2. “비회원”이란 회원에 가입하지 않고 회사가 판매하는
          &quot;Leaf&quot;를 구매할 수 있는 자를 의미합니다.
          <br />
          3. “이용고객”이란 서비스를 이용하기 위해 회사와 이용계약을 체결한 자를
          말합니다.
          <br />
          4. “이용계약”이란 이용고객이 회사가 정한 약관에 동의하고 온라인
          신청양식을 작성하여 서비스 이용을 신청하면 회사가 이용고객에게 이용자
          아이디를 부여하여 서비스를 이용할 수 있도록 하는 것을 말합니다.
          <br />
          5. “아이디”란 “Leaf”이용 및 회원의 식별을 위해 회원이 설정하고 회사가
          승인하여 등록된 문자와 숫자의 조합을 말합니다.
          <br />
          6. “비밀번호”란 회원이 사용하는 이용자 아이디 및 회원의 권익보호를
          위하여 회원이 선정한 문자 및 숫자의 조합을 말합니다.
          <br />
          7. “회원정보”란 성명, 주민등록번호, 연락처 등 회사가 온라인 신청양식에
          기재를 요청하는 회원의 개인정보를 말합니다.
          <br />
          8. “게시물”이란 회사가 제공하는 서비스의 각종 게시판에 회원이 게재
          또는 등록하는 질문, 답변, 자료, 사진, 도형, 영상 등을 의미합니다.
          <br />
          9. “Leaf 서비스”란 이용자가 회사가 제공하는“Leaf”를 구입하고 스마트
          폰에 앱을 다운로드 하여 이용자가 설치하는 공간의 냉난방기기들의 기능을
          원격으로 제어하거나 에너지 절감 서비스를 이용할 수 있는 IoT서비스를
          말합니다.
        </div>
        <div className="index">제3조 (약관 등의 명시와 설명 및 개정)</div>
        <div className="content">
          1. 회사는 서비스의 가입 과정에 본 약관을 게시합니다.
          <br />
          2. 회사는 관련법에 위배되지 않는 범위에서 본 약관을 변경할 수
          있습니다.
          <br />
          3. 회원은 회사가 전항에 따라 변경하는 약관에 동의하지 않을 권리가
          있으며, 이 경우 회원은 회사에서 제공하는 서비스 이용 중단 및
          탈퇴의사를 표시하고 서비스를 이용 종료를 요청할 수 있습니다. 다만,
          회사가 회원에게 변경된 약관의 내용을 통보하면서 회원에게“7일 이내의
          의사 표시를 하지 않을 경우 의사 표시가 표명된 것으로 본다는 뜻”을
          명확히 통지하였음에도 불구하고, 거부의 의사를 표시를 하지 아니한 경우
          회원이 변경된 약관에 동의하는 것으로 봅니다.
        </div>
        <div className="index">제4조 (약관의 해석과 예외준칙)</div>
        <div className="content">
          1. 이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 서비스별
          안내)와 함께 적용합니다.
          <br />
          2. 본 약관에 명시되지 않은 사항이 관계법령에 규정되어 있을 경우에는 그
          규정에 따릅니다.
          <br />
          3. 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하
          “개별약관”)을 정하여 이를 회사 홈페이지 등을 통해 미리 공지할 수
          있습니다.
          <br />
          4. 회사는 전항의 개별약관에 변경이 있을 경우, 시행 14일 이전에 해당
          변경사항을 공지합니다.
          <br />
          5. 회원은 본 약관 및 개별약관의 내용에 변경이 있는지 여부를 주시하여야
          하며, 변경사항의 공지가 있을 시에는 이를 확인하여야 합니다.
        </div>
        <div className="index">제5조 (회사의 면책)</div>
        <div className="content">
          1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
          제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면책됩니다.
          <br />
          2. 회원의 귀책사유로 인한 서비스의 중지 또는 이용장애에 대하여 책임을
          지지 않습니다.
          <br />
          3. 이동통신 사업자가 네트워크서비스를 중지하거나 정상적으로 제공하지
          아니하여 손해가 발생한 경우에는 책임이 면제됩니다.
          <br />
          4. 회원이 서비스를 이용하여 기대하는 수익을 얻지 못한 것에 대하여
          책임을 지지 않으며 서비스에 대한 이용으로 발생하는 손해 등에 대해서는
          책임이 면제됩니다.
          <br />
          5. 회원 간 혹은 회원과 제3자 간 본 서비스를 매개로 하여 발생한 분쟁에
          대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임을 지지
          않습니다.
          <br />
          6. 회원이 서비스 내에서 게시 또는 전송한 정보, 자료, 사실의 신뢰도,
          정확성 등 내용에 대하여는 책임을 지지 않습니다.
          <br />
          7. 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한
          분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도
          없습니다.
          <br />
          8. 회원의 디바이스 환경으로 인하여 발생하는 제반 문제 또는 회사의
          귀책사유가 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서는
          책임을 지지 않습니다.
          <br />
          9. 회사는 회원의 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는
          등 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지
          않습니다.
          <br />
          10. 회사는 신상정보 및 전자우편 주소의 부정확한 기재, 비밀번호 관리의
          소홀 등 회원의 귀책사유로 인해 손해가 발생한 경우 회사는 책임을 지지
          않습니다.
          <br />
          11. 회사는 회원의 단말기 환경이나 회사의 관리범위에 있지 아니한 보안
          문제로 인하여 발생하는 제반 문제 또는 현재의 보안기술 수준으로 방어가
          곤란한 네트워크 해킹 등 회사의 귀책사유 없이 발생하는 문제에 대해서
          책임을지지 않습니다.
          <br />
          12. 회사는 서비스가 제공한 내용에 대한 중요 정보의 정확성, 내용,
          완전성, 적법성, 신뢰성 등에 대하여 보증하거나 책임을지지 않으며,
          사이트의 삭제, 저장실패, 잘못된 인도, 정보에 대한 제공에 대한 궁극적인
          책임을지지 않습니다. 또한, 회사는 회원이 서비스 내 또는 웹사이트상에
          게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성, 완결성, 품질 등
          내용에 대해서는 책임을 지지 않습니다. 회사는 회원이 서비스를 이용하여
          기대하는 효용을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에 대한
          선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
        </div>
        <div className="index">제6조 (관할법원)</div>
        <div className="content">
          1. 본 약관과 이용계약 및 회원 상호간의 분쟁에 대해 회사를 당사자로
          하는 소송이 제기될 경우에는 회사의 본사 소재지를 관할하는 법원을 합의
          관할법원으로 정합니다.
        </div>
        <div className="index">제7조 (이용계약의 체결)</div>
        <div className="content">
          1. 이용계약은 이용고객의 본 이용약관 내용에 대한 동의와 이용신청에
          대하여 회사의 이용승낙으로 성립하여 이용약관에 대한 동의는 이용신청
          당시 해당“회사”웹 및 어플리케이션에‘동의함’버튼을 누름으로써
          의사표시를 합니다.
          <br />
          2. 회사는 이용약관에 동의한 후 이용 신청한 사용자에 대해서 원칙적으로
          접수 순서에 따라 서비스 이용을 승낙함을 원칙으로 합니다. 다만 업무
          수행상 또는 기술상 지장이 있을 경우 일정시간 가입승인을 유보할 수
          있습니다.
          <br />
          3. 회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을
          거부하거나 유보할 수 있습니다.
          <br />
          가) 회사의 실명확인절차에서 실명가입신청이 아님이 확인된 경우
          <br />
          나) 이미 가입된 회원과 이름 및 생년월일(또는 사업자등록번호)이 동일한
          경우
          <br />
          다) 회사에 의하여 이용계약이 해지된 날로부터 1개월 이내에 재이용신청을
          하는 경우
          <br />
          라) 회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에
          이용 계약을 임의 해지하고 재이용신청을 하는 경우
          <br />
          마) 설비에 여유가 없거나 기술상 지장이 있는 경우
          <br />
          바) 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된
          경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우
        </div>
        <div className="index">제8조 (이용계약의 종료)</div>
        <div className="content">
          1. 회원의 해지
          <br />
          1.1. 회원은 언제든지 해당 서비스화면을 통하여 회사에게 해지의사를
          통지함으로써 이용계약을 해지할 수 있습니다. 다만, 회원은 해지의사를
          통지하기 최소한 7일 전에 모든 거래를 완결 시키는데 필요한 조치를
          취하여야만 합니다.
          <br />
          1.2. 전호의 기간 내에 회원이 행한 의사표시로 인해 발생한 불이익에 대한
          책임은 회원 본인이 부담하여야 하며, 이용계약이 종료되면 회사는
          회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
          <br />
          1.3. 회원의 의사로 이용계약을 해지한 후, 추후 재이용을 희망할 경우에는
          재이용 의사가 회사에 통지되고, 이에 대한 회사의 승낙이 있는 경우에만
          서비스 재이용이 가능합니다.
          <br />
          1.4. 회원의 의사로 탈외 처리시, 관련법 및 개인정보취급방지에 따라
          회사가 회원정보를 보유하는 경우와 에너지 관련 데이터를 제외하고 탈퇴
          즉시 회원의 모든 데이터는 소멸 됩니다.
          <br />
          2. 회사의 해지
          <br />
          2.1 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할
          수 있습니다.
          <br />
          가) 가입신청자가 이 약관에 의하여 이전에 화원자격을 상실한 적이 있는
          경우
          <br />
          나) 제3자의 전자우편 주소를 이용하여 신청한 경우
          <br />
          다) 허위의 정보를 기재하거나, 회사가 필수적으로 입력을 요청한 부분을
          기재하지 않은 경우
          <br />
          라) 부정한 용도로 서비스를 사용하고자 하는 경우
          <br />
          마) 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
          사항을 위반하여 신청하는 경우
          <br />
          바) 회사의 정책에 적합하지 않는 회원으로 판단되는 경우나 서비스 제공이
          곤란한 경우
          <br />
          사) 회원의 이용 목적이나 서비스 이용 방법이 회사의 재산권이나 영업권을
          침해하거나 침해할 우려가 있는 경우
          <br />
          아) 비정상적인 방법을 통하여 아이디 및 도메인을 대량으로 생성하는 행위
          <br />
          - 회원은 회사에 언제든지 회원 탈퇴를 요청하여 이용계약을 해지할 수
          있습니다. 회원은 회원가입 시 기재한 개인정보의 내용에 변경이 발생한
          경우, 즉시 변경사항을 정정하여 기재하여야 합니다. 변경의 지체로 인하여
          발생한 회원의 손해에 대해 회사는 책임을 지지 않습니다.
          <br />- 회사는 관련 법률 및 회사의 개인정보취급방침에 정한 바에 따라
          회원에게 요청하는 회원정보 및 기타정보 항목을 추가, 삭제 등 암호화된
          방식으로 변경하여 수집 및 이용할 수 있습니다.
        </div>
        <div className="index">제9조 (서비스의 제공 및 변경)</div>
        <div className="content">
          1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.
          <br />- Leaf, 냉난방(HVAC)관리 서비스
          <br />- 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해
          회원에게 제공하는 일체의 서비스
          <br />
          2. 본 약관에 따른 이용계약 기간은 서비스 신청일로부터 이용계약의 해지
          시 까지 입니다.
          <br />
          3. 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의
          두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수
          있습니다. 또한 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는
          사유에 의하여 현재 제공되는 서비스를 안전히 중단할 수 있습니다. 이
          경우 서비스 일시 중단 사실과 그 사유를 서비스 초기화면 또는 공지사항을
          통해 통지합니다.
          <br />
          4. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
          제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할 수
          있습니다.
          <br />
          5. 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에
          통지할 수 있습니다.
          <br />
          6. 회사는 회원과 별도로 서면 계약을 체결하여 Leaf를 활용한 서비스 및
          제반 서비스의 브랜드 특성을 이용할 수 있는 명시적인 권리를 부여하지
          아니하는 한, 회원에게 회사 또는 서비스의 상호, 상표, 서비스표, 로고,
          도메인 네임 및 기타 식별력 있는 브랜드 특성을 이용할 수 있는 권리를
          부여하지 않습니다.
          <br />
          7. 회사가 제공하는 서비스의 형태와 기능, 디자인 등 필요한 경우 수시로
          변경되거나 중단될 수 있습니다. 회사는 이 경우 개별적인 변경에 대해서
          회원에게 사전통지하지 않습니다. 다만 회원에게 불리한 것으로 판단되는
          경우 전자우편을 통하거나 회사 홈페이지를 통해 이를 공지합니다.
        </div>
        <div className="index">제10조 (개인정보보호 의무)</div>
        <div className="content">
          1. 회사는 정보통신망 법 등 관계 법령이 정하는 바에 따라 회원의
          개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에
          대해서는 관련법 및 회사의 개인정보취급방침이 적용됩니다. 다만, 회사의
          공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보취급방침이
          적용되지 않습니다. 회사는 서비스를 중단하거나 회원이 개인정보 제공
          동의를 철회한 경우에는 신속하게 회원의 개인정보를 파기합니다. 단,
          전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법률에서 정하는
          바에 따라 일정 정보는 보관할 수 있습니다. 회사는 서비스 개선 및 회원
          대상의 서비스 소개 등의 목적으로 회원의 동의하에 관계 법령에서 정하는
          바에 따라 추가적인 개인정보를 수집할 수 있습니다. 회사는 법률에 특별한
          규정이 있는 경우를 제외하고는 회원의 별도 동의 없이 회원의 계정정보를
          포함한 일체의 개인정보를 제3자에게 공개하거나 제공하지 아니합니다.
          회사는 향후 제공하는 서비스에서 회원의 편의를 위해서 회원의 계정
          정보를 사용할 수 있도록 링크 및 기타 방법을 제공할 수 있습니다.
        </div>
        <div className="index">제11조 (회원의 아이디 및 비밀번호)</div>
        <div className="content">
          1. 회원은 아이디와 비밀번호에 관해서 관리책임이 있습니다.
          <br />
          2. 회원은 아이디 및 비밀번호를 제3자가 이용하도록 제공하여서는
          안됩니다.
          <br />
          3. 회사는 회원이 아이디 및 비밀번호를 소홀히 관리하여 발생하는 서비스
          이용상의 손해 또는 회사의 고의 손해 또는 회사의 고의 또는 중대한
          과실이 없는 제3자의 부정이용 등으로 인한 손해에 대해 책임을 지지
          않습니다.
          <br />
          4. 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을
          인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
        </div>
        <div className="index">제12조 (회사의 의무)</div>
        <div className="content">
          1. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여
          노력합니다.
          <br />
          2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 현재 인터넷
          보안기술의 발전수준과 회사가 제공하는 서비스의 성격에 적합한
          보안시스템을 갖추고 운영해야 합니다.
          <br />
          3. 회사는 서비스를 이용하는 회원으로부터 제기되는 의견이나 불만이
          정당하다고 인정할 경우에는 이를 처리하여야 합니다. 이때 처리과정에
          대해서 고객에게 메일 및 메시지 등의 방법으로 전달합니다.
          <br />
          4. 회사는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법,
          전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.
        </div>
        <div className="index">제13조 (회원의 의무)</div>
        <div className="content">
          1. 회원은 다음 각 호에 해당하는 행위를 해서는 안되며, 해당 행위를 하는
          경우에 회사는 회원의 서비스 이용제한 및 법적 조치를 포함한 제재를 가할
          수 있습니다.
          <br />
          가) 이용 신청 또는 회원정보 변경 시 허위내용 등록
          <br />
          나) 타인의 정보 도용
          <br />
          다) 회사의 운영자, 임직원, 회사를 사칭하거나 관련 정보를 도용
          <br />
          라) 회사가 게시한 정보의 변경
          <br />
          마) 회사와 기타 제3자의 저작권, 영업비밀, 특허권 등 지적재산권에 대한
          침해
          <br />
          바) 회사와 다른 회원 및 기타 제3자를 희롱하거나, 위협하거나 명예를
          손상시키는 행위
          <br />
          사) 외설, 폭력적인 메시지, 기타 공서양속에 반하는 정보를 공개 또는
          게시하는 행위
          <br />
          아) 해킹을 통해서 다른 사용자의 정보를 취득하는 행위
          <br />
          자) 기타 현행 법령에 위반되는 불법적인 행위
          <br />
          차) 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를
          도용하여 글을 게시하거나 메일을 발송하는 행위
          <br />
          카) 회사로부터 특별한 권리를 부여 받지 않고 회사의 클라이언트
          프로그램을 변경하거나, 회사의 서버를 해킹하거나, 웹사이트 또는 게지된
          정보의 일부분 또는 전체를 임의로 변경하는 행위
          <br />
          타) 회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는
          저장하는 행위
          <br />- 회사는 회원이 전항에서 금지한 행위를 하는 경우, 위반 행위의
          경중에 따라 서비스의 이용정지/계약의 해지 등 서비스 이용 제한, 수사
          기관에의 고발 조치 등 합당한 조치를 취할 수 있습니다.
          <br />- 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여
          공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타
          회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
          <br />- 회원은 회사의 사전 허락 없이 회사가 정한 이용 목적과 방법에
          반하여 영업/광고활동 등을 할 수 없고, 회원의 서비스 이용이 회사의
          재산권, 영업권 또는 비즈니스 모델을 침해하여서는 안됩니다.
        </div>
        <div className="index">제14조 (광고의 게재)</div>
        <div className="content">
          1. 회사는 서비스 운영과 관련하여 회원정보, 고객이 입력한 정보를
          활용하여 광고를 게재할 수 있습니다. 회원은 서비스 이용 시 노출되는
          맞춤 광고 게재에 대해 동의합니다.
          <br />
          2. 회사는 서비스상에 게재되어 있거나 서비스를 통한 광고주의 판촉활동에
          회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에
          대해 책임을지지 않습니다.
        </div>
        <div className="index">
          제15조 (전자우편 또는 메시지 알림을 통한 정보의 제공)
        </div>
        <div className="content">
          1. 회사는 회원이 서비스 이용에 필요하다고 인정되는 다양한 정보를
          회원이 제공한 전자우편 주소 또는 개인 휴대폰 문자 메시지로 제공할 수
          있습니다.
          <br />
          2. 회사는 서비스 운영을 위해 회원정보를 활용하여 영리목적의 광고성
          전자우편을 전송할 수 있습니다. 회원이 이를 원하지 않는 경우에는
          언제든지 서비스 홈페이지 또는 서비스 내부 설정페이지 등을 통하여
          수신거부를 할 수 있습니다.
          <br />
          3. 회사는 다음 각 호에 해당하는 경우 회원의 동의여부와 상관없이
          전자우편으로 발송할 수 없습니다.
          <br />
          가) 이용 신청에서 입력한 전자우편 주소의 소유를 확인하기 위해서
          인증메일을 발송하는 경우
          <br />
          나) 회원의 정보가 변경되어 확인하기 위해서 인증메일을 발송하는 경우
          <br />
          다) 기타 서비스를 제공함에 있어 회원이 반드시 알아야 하는 중대한
          정보라고 회사가 판단하는 경우
        </div>
      </UserGuideBlock>
    </>
  );
};

export default UserGuide;
