import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(
    window.innerWidth >= 1024 ? 1024 : window.innerWidth,
  );

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      setWindowSize(1024);
    } else {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { windowSize };
};

export default useWindowSize;
