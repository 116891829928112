import styled, { css } from 'styled-components';
import GridImg from 'assets/imgs/main/grid.png';
import GraphImg from 'assets/imgs/main/graph.png';
import GraphImgMobile from 'assets/imgs/main/graph-mobile.png';
import GraphLineImg from 'assets/imgs/main/graph-line.png';
import { GRAPH_DOT } from 'constants/main';
import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import { useState } from 'react';
import BalloonImg1 from 'assets/imgs/main/graph-balloon-d1.png';
import BalloonImg2 from 'assets/imgs/main/graph-balloon-d2.png';
import BalloonImg3 from 'assets/imgs/main/graph-balloon-d3.png';
import BalloonImgM1 from 'assets/imgs/main/graph-balloon-m1.png';
import BalloonImgM2 from 'assets/imgs/main/graph-balloon-m2.png';
import BalloonImgM3 from 'assets/imgs/main/graph-balloon-m3.png';
import useWindowSize from 'hooks/common/useWindowSize';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';
import BalloonImgEn1 from 'assets/imgs/main/graph-balloon-en1.png';
import BalloonImgEn2 from 'assets/imgs/main/graph-balloon-en2.png';
import BalloonImgEn3 from 'assets/imgs/main/graph-balloon-en3.png';
import BalloonImgEn1M from 'assets/imgs/main/graph-balloon-en1-m.png';
import BalloonImgEn2M from 'assets/imgs/main/graph-balloon-en2-m.png';
import BalloonImgEn3M from 'assets/imgs/main/graph-balloon-en3-m.png';

const Graph = () => {
  const { t } = useTranslation('main');
  const isMobile = useWindowSize().windowSize! < 768;
  const [transition, setTransition] = useState(false);
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setTransition(true);
    } else {
      setTransition(false);
    }
  };
  const setTarget = useIntersectionObserver({ onIntersect });
  const { language } = useChangeLanguage();

  return (
    <Container>
      <Title>
        <h1>{t('graph.title')}</h1>
        <p dangerouslySetInnerHTML={{ __html: t('graph.desc') }}></p>
      </Title>
      <GridImage src={GridImg} alt="grid" />
      <GraphArea ref={setTarget}>
        <GraphImage type="desktop" src={GraphImg} alt="graph" />
        <GraphImage type="mobile" src={GraphImgMobile} alt="graph" />
        <GraphLine>
          <Line />
          {GRAPH_DOT.map((dot, i) => (
            <Dot key={dot.label} left={dot.left}>
              <div></div>
              <span>{t(`graph.dotLabel.${i + 1}`)}</span>
            </Dot>
          ))}
        </GraphLine>
        <SectionLine en={isMobile && language === 'en'}>
          <LineImage src={GraphLineImg} />
          <Label>{t('graph.graphLabel')}</Label>
        </SectionLine>
        {isMobile ? (
          <Balloons en={language === 'en'}>
            <img
              src={language === 'en' ? BalloonImgEn1M : BalloonImgM1}
              alt="balloon"
              className={transition ? 'transition' : ''}
            />
            <img
              src={language === 'en' ? BalloonImgEn2M : BalloonImgM2}
              alt="balloon"
              className={transition ? 'transition' : ''}
            />
            <img
              src={language === 'en' ? BalloonImgEn3M : BalloonImgM3}
              alt="balloon"
              className={transition ? 'transition' : ''}
            />
          </Balloons>
        ) : (
          <Balloons en={language === 'en'}>
            <img
              src={language === 'en' ? BalloonImgEn1 : BalloonImg1}
              alt="balloon"
              className={transition ? 'transition' : ''}
            />
            <img
              src={language === 'en' ? BalloonImgEn2 : BalloonImg2}
              alt="balloon"
              className={transition ? 'transition' : ''}
            />
            <img
              src={language === 'en' ? BalloonImgEn3 : BalloonImg3}
              alt="balloon"
              className={transition ? 'transition' : ''}
            />
          </Balloons>
        )}
      </GraphArea>
      <Description>
        <p>
          <span>
            <Trans t={t} i18nKey={'graph.outro.1'} />
          </span>
          <span>
            <Trans t={t} i18nKey={'graph.outro.2'} />
          </span>
          <span>
            <Trans t={t} i18nKey={'graph.outro.3'} />
          </span>
        </p>
        <p>
          <strong>{t('graph.outro.4')}</strong>
        </p>
      </Description>
      <Gradient type="top" />
      <Gradient type="bottom" />
    </Container>
  );
};

export default Graph;

const Container = styled.section`
  position: relative;
  padding-top: 137px;
  padding-bottom: 156px;
  background-color: #4f16a9;

  @media (max-width: 767px) {
    height: 594px;
    padding-top: 60px;
    padding-bottom: 188px;
  }
`;

const GridImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 884px;

  @media (max-width: 767px) {
    width: 360px;
    top: 68px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Title = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto 174px;
  z-index: 15;

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #4f16a9;
    background-color: #fff;
    padding: 0 30px;
    margin-bottom: 20px;
  }

  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    opacity: 0.8;

    strong {
      font-weight: 500;
      opacity: 0.9;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 143px;

    h1 {
      width: fit-content;
      font-size: 18px;
      line-height: 26px;
      margin: 0 auto 20px;
      padding: 2px 6px;
    }

    p {
      font-weight: 400;
      font-size: 10px;
      // 모바일 영어 12
      line-height: 14px;

      strong {
        font-weight: 700;
        opacity: 1;
      }
    }
  }
`;

const GraphArea = styled.div`
  position: relative;
  width: 884px;
  margin: 0 auto 140px;

  @media (max-width: 767px) {
    width: 320px;
  }
`;

const GraphImage = styled.img<{ type: string }>`
  display: ${({ type }) => (type === 'desktop' ? 'block' : 'none')};
  width: 866px;
  margin-bottom: 27px;
  position: relative;
  left: 17px;

  @media (max-width: 767px) {
    display: ${({ type }) => (type === 'desktop' ? 'none' : 'block')};
    width: 100%;
    left: auto;
    margin-bottom: 7px;
  }
`;

const GraphLine = styled.div`
  position: relative;
  width: 868px;
  margin-left: auto;

  @media (max-width: 767px) {
    width: 320px;
  }
`;

const Line = styled.div`
  height: 2px;
  background-color: #fff;
  opacity: 0.6;
  border-radius: 20px;
  position: relative;

  @media (max-width: 767px) {
    width: 320px;
  }
`;

const Dot = styled.div<{ left?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -29px;
  left: ${({ left }) => left};
  transform: translateX(-50%);

  div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ffffff;
    opacity: 0.6;
    margin-bottom: 5px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.019em;
    color: #ffffff;
    white-space: nowrap;
  }

  &:first-child {
    div {
      display: none;
    }
  }

  @media (max-width: 767px) {
    bottom: -8px;

    span {
      display: none;
    }
  }
`;

const SectionLine = styled.div<{ en?: boolean }>`
  position: absolute;
  bottom: 85px;
  left: 0;

  @media (max-width: 767px) {
    bottom: 54px;
    left: 254px;
    ${({ en }) =>
      en &&
      css`
        bottom: 106px;
        left: 190px;
      `}
  }
`;

const LineImage = styled.img`
  width: 8px;
  height: 165px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const Label = styled.span`
  position: absolute;
  top: 50%;
  left: calc(50% - 24px);
  white-space: nowrap;
  transform: translate(-50%, -50%) rotate(-90deg);
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.019em;
  color: #ffffff;

  @media (max-width: 767px) {
    display: block;
    top: auto;
    left: auto;
    transform: translate(0, 0) rotate(0);
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: -0.019em;
    opacity: 0.8;
  }
`;

const Balloons = styled.div<{ en: boolean }>`
  img {
    position: absolute;
    opacity: 0;
    transform: translateY(50px);
    transition: all 600ms ease;

    &.transition {
      opacity: 1;
      transform: translateY(0);
    }

    &:first-child {
      width: ${({ en }) => (en ? '520px' : '430px')};
      transition-delay: 300ms;
      top: -72px;
      left: 276px;
    }

    &:nth-child(2) {
      width: ${({ en }) => (en ? '420px' : '365px')};
      transition-delay: 600ms;
      top: 68px;
      left: 160px;
    }

    &:nth-child(3) {
      width: 162px;
      transition-delay: 900ms;
      bottom: 26px;
      right: -10px;
    }

    @media (max-width: 767px) {
      transform: translateY(25px);

      &:first-child {
        width: 228px;
        top: -45px;
        left: 78px;
      }

      &:nth-child(2) {
        width: 138px;
        top: auto;
        bottom: 5px;
        left: 18px;
      }

      &:nth-child(3) {
        width: 90px;
        bottom: -23px;
        right: 30px;
      }
    }
  }
`;

const Gradient = styled.div<{ type: string }>`
  display: none;

  @media (max-width: 767px) {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 116px;
    background: linear-gradient(180deg, rgba(79, 22, 169, 0) 0%, #4f16a9 100%);
    transform: ${({ type }) => type === 'top' && 'matrix(1, 0, 0, -1, 0, 0);'};
    top: ${({ type }) => type === 'top' && '66px'};
    bottom: ${({ type }) => type === 'bottom' && '68px'};
  }
`;

const Description = styled.div`
  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.019em;
    color: #ffffff;

    &:not(:last-child) {
      margin-bottom: 50px;
    }

    span {
      display: block;
    }

    strong {
      text-align: center;
      font-weight: 500;
      font-size: 30px;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;
