import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { progressFull, rotateCircle } from 'styles/animations';
import { Container } from './TemperatureController';

function TimerController() {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const COUNT_NUMBER = [24, 60];
  useEffect(() => {
    const parentElement = parentRef.current;
    for (let i = 1; i <= 24; i++) {
      const tick = document.createElement('div');
      const line = document.createElement('p');
      tick.className = 'tick';
      tick.style.transform = `rotate(${15 * i}deg)`;
      tick.appendChild(line);
      if (parentElement) {
        parentElement.appendChild(tick);
      }
    }
  }, [COUNT_NUMBER]);

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(30);
  const [transition, setTransition] = useState(false);

  const onMouseEnter = () => {
    setTransition(true);
  };
  const onMouseLeave = () => {
    setTransition(false);
    setCount1(0);
    setCount2(30);
  };

  useEffect(() => {
    if (!transition) return;
    const increment = setInterval(() => {
      if (count1 === COUNT_NUMBER[0]) {
        clearInterval(increment);
        setCount1(COUNT_NUMBER[0]);
        return;
      }
      setCount1(count => count + 1);
    }, 1200 / 24);
    intervalRef.current = increment;
    return () => clearInterval(increment);
  }, [count1, transition]);

  useEffect(() => {
    if (!transition) return;
    const increment2 = setInterval(() => {
      if (count2 === COUNT_NUMBER[1]) {
        clearInterval(increment2);
        setCount2(COUNT_NUMBER[1]);
        return;
      }
      setCount2(count => count + 10);
    }, 1200 / 3);
    intervalRef.current = increment2;
    return () => clearInterval(increment2);
  }, [count2, transition]);

  return (
    <PaddingContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <span>
        {count1 === 0 ? '00' : count1 < 10 ? `0${count1}` : count1}:
        {count2 === 60 ? '00' : count2 < 10 ? `0${count2}` : count2}
      </span>
      <BackgroundCircle ref={parentRef}>
        <InnerCircle>
          <SmallCircleContainer className="circle">
            <SmallCircle />
          </SmallCircleContainer>
        </InnerCircle>
      </BackgroundCircle>
      <Progress>
        <svg>
          <circle cx={77} cy={77} r={69}></circle>
        </svg>
      </Progress>
    </PaddingContainer>
  );
}

const PaddingContainer = styled(Container)`
  padding: 54px;
  position: relative;
  &:hover circle {
    animation: ${progressFull} 1.5s forwards;
  }
  &:hover .circle {
    animation: ${rotateCircle} 1.5s forwards;
  }
  & > span {
    transition: all 1.5s;
    position: absolute;
    font-weight: 500;
    font-size: 30px;
    line-height: 43px;
    z-index: 10;
  }
  &:hover {
    color: #6f36c9;
  }
  @media (max-width: 767px) {
    transform-origin: 0 0;
    transform: scale(0.68);
  }
`;

const BackgroundCircle = styled.div`
  width: 154px;
  height: 154px;
  background: #f0f3ff;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > .tick {
    width: 50%;
    height: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 1;
    p {
      width: 1px;
      height: 10px;
      background: black;
      opacity: 0.3;
    }
  }
`;

const InnerCircle = styled.div`
  width: 122px;
  height: 122px;
  background: #fafbff;
  box-shadow: 0px -5px 10px rgba(255, 255, 255, 0.3),
    -2px 5px 10px rgba(0, 0, 0, 0.2), inset 0px 3px 1px #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const SmallCircleContainer = styled.div`
  width: 122px;
  height: 122px;
  position: absolute;
`;

const SmallCircle = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  left: 63px;
  top: 10px;
  border-radius: 50%;
  background: #eeeeee;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
`;

const Progress = styled.div`
  position: absolute;
  width: 261px;
  height: 261px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  svg {
    width: 154px;
    height: 154px;
    transform: rotate(-90deg);
    circle {
      stroke-dasharray: 440;
      stroke-dashoffset: 430;
      fill: transparent;
      stroke-width: 14px;
      stroke: #99ef60;
      stroke-linecap: round;
    }
  }
`;

export default TimerController;
