import client from './client';

export const createPost = (data: FormData) => client.post('/careers', data);

export const updatePost = ({ id, data }: { id: string; data: FormData }) =>
  client.patch(`/careers/${id}`, data);

export const getPosts = async () => {
  return (await client.get('/careers')).data.data;
};

export const getPositionDetail = async (id: number) => {
  return (await client.get(`/careers/${id}`)).data;
};

export const getPost = (id: number) => client.get(`/careers/${id}`);

export const deletePosition = (id: number) => client.delete(`/careers/${id}`);

export const submitApplication = async (data: FormData) =>
  await client.post('/careers/apply', data);

export const apply = (data: FormData) => client.post('/careers/apply', data);
