import styled, { css } from 'styled-components';
import PurpleWaveImg from 'assets/imgs/about/wave.png';
import WhiteWaveImg from 'assets/imgs/about/white-wave.png';

const Wave = ({ animation }: { animation: number }) => {
  return (
    <Container animation={animation}>
      <Title>Better for Us, Better for Earth</Title>
      <MobileTitle>
        Better for Us
        <br />
        Better for Earth
      </MobileTitle>
      <Images>
        <WaveImg image={PurpleWaveImg} animation={animation} />
        <WaveImg image={WhiteWaveImg} animation={animation} />
      </Images>
      <Gradient />
    </Container>
  );
};

const Container = styled.div<{ animation: number }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 366px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 800ms ease-in-out;

  ${({ animation }) =>
    animation === 5 &&
    css`
      opacity: 1;
    `}

  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

const Title = styled.h1`
  color: #4f16a9;
  font-weight: 800;
  font-size: 60px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 200px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileTitle = styled.h1`
  display: none;

  @media (max-width: 767px) {
    /* mobile Headline */
    display: block;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #4f16a9;
    margin-bottom: 112px;
  }
`;

const Images = styled.div`
  position: relative;
  height: 119px;

  @media (max-width: 767px) {
    height: 44px;
  }
`;

const WaveImg = styled.div<{ image: string; animation: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: contain;
  background-position: center;
  background-repeat: repeat-x;

  &:last-child {
    left: -50%;
  }

  ${({ animation }) =>
    animation === 5 &&
    css`
      &:first-child {
        animation: wave-purple 5s ease-out forwards;
      }

      &:last-child {
        animation: wave-white 5s ease-out forwards;
      }
    `}

  @keyframes wave-purple {
    0% {
      left: 0;
    }
    100% {
      left: -50%;
    }
  }

  @keyframes wave-white {
    0% {
      left: -50%;
    }
    100% {
      left: 0;
    }
  }
`;

const Gradient = styled.div`
  position: relative;
  top: -5px;
  width: 100%;
  height: 564px;
  background: linear-gradient(180deg, #4f16a9 0%, rgba(79, 22, 169, 0) 100%);

  @media (max-width: 767px) {
    position: absolute;
    height: 500px;
    top: auto;
    bottom: -500px;
    left: 0;
    background-color: #4f16a9;
    z-index: 0;
  }
`;

export default Wave;
