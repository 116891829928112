import styled from 'styled-components';

const LoadingSpinnerContainer = styled.div`
  margin-top: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinner = styled.div`
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = () => {
  return (
    <LoadingSpinnerContainer>
      <LoadingSpinner></LoadingSpinner>
    </LoadingSpinnerContainer>
  );
};

export default Spinner;
