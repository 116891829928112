import Header from 'components/common/Header';
import styled from 'styled-components';
import { useState } from 'react';
import Start from 'components/service/Start';
import ControlDesc from 'components/service/ControlDesc';
import UserDesc from 'components/service/UserDesc';
import AiDesc from 'components/service/AiDesc';
import HubDesc from 'components/service/HubDesc';
import SyncDesc from 'components/service/SyncDesc';
import AdvantagesDesc from 'components/service/AdvantagesDesc';
import PaymentOptions from 'components/service/PaymentOptions';
import Footer from 'components/common/Footer';
import Flow from 'components/service/Flow';
import Specification from 'components/service/Specification';
import Floating from 'components/common/FloatingButton';
import useHeader from 'hooks/common/useHeader';

export type Mode = 'outline' | 'spec' | '';

const BREAKPOINT = {
  desktop: 530,
  mobile: 320,
};

function ServicesPage() {
  const [mode, setMode] = useState<Mode>('');
  const headerType = useHeader(BREAKPOINT);

  return (
    <Container>
      <MainHeader type={headerType} />
      <Start mode={mode} setMode={setMode} />

      {mode !== 'spec' ? (
        <>
          <ControlDesc />
          <UserDesc />
          <AiDesc />
          <HubDesc />
          <SyncDesc />
          <AdvantagesDesc />
          <PaymentOptions />
        </>
      ) : (
        <>
          <Flow />
          <Specification />
        </>
      )}
      <Floating />
      <Footer />
    </Container>
  );
}

const MainHeader = styled(Header)`
  position: fixed;
`;

const Container = styled.div`
  letter-spacing: -0.04em;
`;

export default ServicesPage;
