import styled from 'styled-components';
import CircleImg from 'assets/imgs/common/success-circle.gif';
import ErrorImg from 'assets/imgs/main/ask-error.png';
import Button from 'components/common/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/common/useWindowSize';
import { useTranslation, Trans } from 'react-i18next';

const AskResult = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useWindowSize().windowSize! < 768;
  const { t } = useTranslation('main');
  return (
    <>
      {pathname === '/ask/result/success' ? (
        <Container>
          <Header>
            <Trans t={t} i18nKey={'askSuccess.title'} />
          </Header>
          <Desc>
            <Trans t={t} i18nKey={'askSuccess.desc'} />
          </Desc>
          <Circle src={CircleImg} />
          <Button size="large" onClick={() => navigate('/')}>
            <Trans t={t} i18nKey={'askSuccess.button'} />
          </Button>
        </Container>
      ) : (
        <ErrorContainer>
          <ErrorImage src={ErrorImg} />
          <ErrorHeader>
            <Trans t={t} i18nKey={'askFail.title'} />
          </ErrorHeader>
          <ErrorCode>{t('askFail.errorCode')} : XXX</ErrorCode>
          <Text>{t('askFail.desc.1')}</Text>
          {isMobile ? (
            <Text>
              {t('askFail.desc.2')}
              <br />
              {t('askFail.desc.3')}
            </Text>
          ) : (
            <Text>
              {t('askFail.desc.2')}
              {t('askFail.desc.3')}
            </Text>
          )}
          <ErrorButton size="large" onClick={() => navigate(-1)}>
            {isMobile
              ? t('askFail.button.mobile')
              : t('askFail.button.desktop')}
          </ErrorButton>
        </ErrorContainer>
      )}
    </>
  );
};

export default AskResult;

const Container = styled.main`
  width: 100%;
  padding-top: 200px;
  padding-bottom: 667px;
  display: flex;
  flex-direction: column;
  align-items: center;

  br {
    display: none;
  }

  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 160px;

    br {
      display: block;
    }
  }
`;

const ErrorContainer = styled(Container)`
  padding-top: 104px;
  padding-bottom: 324px;

  @media (max-width: 767px) {
    padding-top: 57px;
    padding-bottom: 184px;
  }
`;

const Header = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 70px;
  color: #111111;
  opacity: 0.8;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 767px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
  }
`;

const Desc = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #7c7c7c;
  margin-bottom: 64px;
  text-align: center;

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 38px;
  }
`;

const Circle = styled.img`
  display: block;
  width: 92px;
  height: 92px;
  margin-bottom: 100px;

  @media (max-width: 767px) {
    width: 68px;
    height: 68px;
    margin-bottom: 60px;
  }
`;

const ErrorHeader = styled(Header)`
  margin-bottom: 10px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const ErrorCode = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #111111;
    opacity: 0.5;
  }
`;

const ErrorImage = styled.img`
  width: 280px;
  height: 280px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    width: 145px;
    height: 145px;
    margin-bottom: 52px;
  }
`;

const ErrorButton = styled(Button)`
  margin-top: 50px;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
`;
