import React from 'react';
import styled from 'styled-components';
import clock from 'assets/imgs/service/clock.svg';
import { useTranslation, Trans } from 'react-i18next';

function ScheduleController() {
  const { t } = useTranslation('services');
  return (
    <Container>
      <Schedule>
        <Clock src={clock} />
        <Date>
          <Time>10:00 ~ 20:00</Time>
          <Week>
            <Colored>
              <Trans t={t} i18nKey="mon" />
            </Colored>
            <Trans t={t} i18nKey="thuToSun" />
          </Week>
        </Date>
      </Schedule>
      <Schedule>
        <Clock src={clock} />
        <Date>
          <Time>09:30 ~ 20:00</Time>
          <Week>
            <Trans t={t} i18nKey="monToTue" />
            <Colored>
              <Trans t={t} i18nKey="wedToFri" />
            </Colored>
            <Trans t={t} i18nKey="SatToSun" />
          </Week>
        </Date>
      </Schedule>
      <Schedule>
        <Clock src={clock} />
        <Date>
          <Time>09:00 ~ 20:30</Time>
          <Week>
            <Trans t={t} i18nKey="monToFri" />{' '}
            <Colored>
              <Trans t={t} i18nKey="SatToSun" />
            </Colored>
          </Week>
        </Date>
      </Schedule>
    </Container>
  );
}

const Container = styled.div`
  padding: 41px 20px;
  width: 261px;
  height: 261px;
  background: #f8f9ff;
  box-shadow: 4px 4px 10px rgba(106, 124, 185, 0.25),
    inset 3px 3px 1px rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover > div:nth-child(1) {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
  }
  &:hover > div:nth-child(2) {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  &:hover > div:nth-child(3) {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  @media (max-width: 767px) {
    transform-origin: 0 0;
    transform: scale(0.68);
  }
`;

const Schedule = styled.div`
  position: relative;
  width: 220px;
  height: 53px;
  background: #fefeff;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 7px 15px 15px 15px;
  display: flex;
`;

const Clock = styled.img`
  position: relative;
  width: 23px;
  height: 23px;
  top: 8px;
  margin-right: 22px;
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;
`;

const Time = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.04em;
`;

const Week = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3em;
  color: rgba(0, 0, 0, 0.2);
`;

const Colored = styled.span`
  color: #afff7a;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.32em;
`;

export default ScheduleController;
