import styled from 'styled-components';
import { SEEDN_GREEN } from './palette';

export const StrongText = styled.span`
  color: ${SEEDN_GREEN};
`;

export const Z_INDEX = {
  SECTION: '20',
  SCROLL_TEXT: '15',
  SCROLL_IMAGE: '5',
  SCROLL_LAYER: '10',
  SCROLL_TRANSPARENT: '20',
  HEADER: '30',
  HEADER_MENU: '50',
  HEADER_LAYER: '25',
  MODAL: '40',
  MODAL_LAYER: '35',
};

/* Mobile Typography */

export const Headline = styled.h1`
  font-weight: 700;
  font-size: 24px;
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 18px;
`;

export const Sub1 = styled.strong`
  font-weight: 700;
  font-size: 16px;
`;

export const Sub2 = styled.span`
  font-weight: 500;
  font-size: 14px;
`;

export const Body1 = styled.span`
  font-weight: 400;
  font-size: 12px;
`;

export const Body2 = styled.span`
  font-weight: 400;
  font-size: 10px;
`;
