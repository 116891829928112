import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Responsive from 'components/common/Responsive';
import { IconBack } from 'styles/Icons';
import { DARK_THEME, THEME } from 'styles/palette';
import TextEditor from 'components/common/TextEditor';
import { useWriter } from 'hooks/careers/useWriter';
import { useEffect, useRef, useState } from 'react';
import ConfirmModal from 'components/common/ConfirmModal';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import Dropdown from 'components/common/Dropdown';

const WriterBlock = styled.div`
  max-width: 768px;
  min-width: 370px;
  margin: 0 auto;
  padding: 60px 20px;
  flex-direction: column;

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .position {
      width: 80%;
      font-size: 32px;
      font-weight: 600;
      border: none;
      outline: none;

      &::placeholder {
        color: #aaa;
      }
    }
  }

  .division-line {
    height: 6px;
    width: 64px;
    border-radius: 1px;
    background-color: rgb(73, 80, 87);
    margin: 24px 0;
  }

  .sub-wrapper {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;

    & > div {
      width: 210px;
    }

    label {
      margin-left: 15px;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }

    input {
      width: 50%;
      padding: 10px 15px;
      border: none;
      border-radius: 10px;
    }

    input[type='file'] {
      display: none;
    }

    .image-delete-btn {
      margin-left: 20px;
      border: none;
      border-radius: 10px;
      background-color: black;
      color: white;
      font-size: 10px;
      cursor: pointer;

      &::before {
        content: 'X';
      }
    }
  }

  .sub-wrapper + .sub-wrapper {
    margin-top: 10px;
  }

  .submit {
    padding: 15px 30px;
    background-color: ${THEME};
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    :hover {
      background-color: ${DARK_THEME};
    }

    :disabled {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .text-editor-wrapper {
    padding: 50px 0;
  }

  .dropdown {
    position: relative;
    background-color: #e6e6e6;
    border-radius: 4px;
    margin-left: 5px;
  }

  .dropdown:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-top: 5px solid #aaa;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }

  @media (max-width: 420px) {
    .top {
      a {
        min-width: fit-content;
        position: absolute;
        top: 10%;
        left: 20px;
      }
    }
  }
`;

const StyledSelect = styled.select`
  font-size: 12px;
  font-weight: normal;
  min-width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const Writer = ({ edit = false }) => {
  const {
    position,
    stack,
    contract,
    group,
    mainImage,
    error,
    createPost,
    updatePost,
    onChange,
    onChangeFile,
    onDeleteImage,
  } = useWriter(edit);
  const [isError, setIsError] = useState(false);
  const { positionDetail } = useSelector((state: RootState) => ({
    positionDetail: state.careers.positionDetail,
  }));
  const { postId } = useParams();
  const titleInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    titleInputRef.current?.focus();
    if (error) {
      setIsError(true);
    }
  }, [error]);

  return (
    <>
      {isError && (
        <ConfirmModal
          onClose={() => setIsError(false)}
          onClick={() => setIsError(false)}
          msg="서버 오류가 발생했습니다."
        />
      )}
      <Responsive>
        <WriterBlock>
          <div className="top">
            <input
              className="position"
              type="text"
              name="position"
              placeholder="포지션을 입력하세요"
              value={position}
              onChange={onChange}
              autoComplete="off"
              ref={titleInputRef}
            />
            <Link to="/careers">
              <div
                style={{ display: 'flex', fontWeight: 600, cursor: 'pointer' }}
              >
                <IconBack />
                <div style={{ marginLeft: 10 }}>나가기</div>
              </div>
            </Link>
          </div>
          <div className="division-line" />
          <div className="sub-wrapper">
            <div>고용 형태</div>
            <div className="dropdown">
              <StyledSelect name="contract" id="contract" onChange={onChange}>
                <option value="">고용형태 선택</option>
                <option value="정규직">정규직</option>
                <option value="Internship">Internship</option>
              </StyledSelect>
            </div>
          </div>
          <div className="sub-wrapper">
            <div>직군</div>
            <Dropdown onChange={onChange} />
          </div>
          <div className="sub-wrapper">
            <div>스택</div>
            <input
              type="text"
              name="stack"
              placeholder="비어 있음"
              value={stack}
              onChange={onChange}
              autoComplete="off"
            />
          </div>
          <div className="sub-wrapper">
            <div>이미지</div>
            <label htmlFor="mainImage">
              {mainImage?.name
                ? mainImage.name
                : mainImage
                ? '기존 이미지'
                : '이미지 선택 (권장 사이즈 1600 x 300)'}
            </label>
            <input
              type="file"
              name="mainImage"
              id="mainImage"
              onChange={onChangeFile}
            />
            <button
              className="image-delete-btn"
              name="image-delete-btn"
              onClick={onDeleteImage}
            />
          </div>
          <div className="text-editor-wrapper">
            <TextEditor markup={edit ? positionDetail.content : null} />
          </div>
          <div
            style={{
              paddingTop: 30,
              paddingBottom: 30,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              className="submit"
              onClick={edit ? () => updatePost(Number(postId)) : createPost}
              disabled={!position || !contract || !group}
            >
              {edit ? '수정' : '작성'}하기
            </button>
          </div>
        </WriterBlock>
      </Responsive>
    </>
  );
};

export default Writer;
