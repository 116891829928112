import styled, { css } from 'styled-components';
import { SEEDN_NEW } from 'styles/palette';
import { ReactComponent as IconLeaf } from 'assets/icons/main/floating-leaf.svg';
import { ReactComponent as IconKakao } from 'assets/icons/main/floating-kakao.svg';
import { ReactComponent as IconAsk } from 'assets/icons/main/floating-ask.svg';
import { useNavigate } from 'react-router-dom';
import FloatingButton from 'components/careers/FloatingButton';
import { useState } from 'react';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

const Floating = () => {
  const { language } = useChangeLanguage();
  const navigate = useNavigate();
  const [mouseover, setMouseOver] = useState(false);
  return (
    <FloatingButtons
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <LeafButton mouseover={mouseover} en={language === 'en'}>
        <IconLeaf />
        <Blur />
      </LeafButton>
      <Buttons>
        <SubButton
          className="button"
          color="#FFD600"
          onClick={() => window.open('https://pf.kakao.com/_pkMaj/chat')}
        >
          <IconKakao />
        </SubButton>
        <SubButton color={SEEDN_NEW} onClick={() => navigate('/ask')}>
          <IconAsk />
        </SubButton>
      </Buttons>
    </FloatingButtons>
  );
};

const FloatingButtons = styled.div`
  position: fixed;
  bottom: 70px;
  right: 100px;
  z-index: 120;

  @media (max-width: 767px) {
    bottom: 30px;
    right: 24px;
  }
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
`;

const LeafButton = styled(FloatingButton)<{ mouseover?: boolean; en: boolean }>`
  width: 80px;
  height: 80px;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  z-index: 50;
  transition: all 200ms ease-in-out;

  &::after {
    opacity: 0;
    content: ${({ en }) => (en ? `'Contact'` : `'문의하기'`)};
    z-index: 100;
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
    transition: all 200ms ease-in-out;
  }

  ${({ mouseover }) =>
    mouseover &&
    css`
      background-color: transparent;
      box-shadow: none;

      ${Blur} {
        background-color: ${SEEDN_NEW};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        filter: blur(2.5px);
      }

      &::after {
        opacity: 1;
      }

      svg {
        display: none;
      }
    `}

  &:hover {
    background-color: transparent;
    box-shadow: none;

    ${Blur} {
      background-color: ${SEEDN_NEW};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      filter: blur(2.5px);
    }

    &::after {
      opacity: 1;
    }

    svg {
      display: none;
    }
  }

  &:hover ~ div * {
    opacity: 1;
  }

  @media (max-width: 767px) {
    width: 70px;
    height: 70px;
  }
`;

const Buttons = styled.div`
  * {
    opacity: 0;
    cursor: auto;
  }

  &:hover * {
    opacity: 1;
    cursor: pointer;
  }
`;

const SubButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  transition: all 300ms ease-in;

  * {
    transition: all 300ms ease-in;
  }

  &:first-child {
    top: calc(-15px - 55px);
    left: -8px;
  }

  &:last-child {
    top: -8px;
    left: calc(-15px - 55px);

    svg {
      position: relative;
      left: -1px;
    }
  }
`;

export default Floating;
