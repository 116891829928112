import styled from 'styled-components';
import { ReactComponent as IconSearch } from 'assets/icons/common/search.svg';
import { GREY, SEEDN_NEW } from 'styles/palette';

const FaqHeader = () => {
  return (
    <Container>
      <H1>자주하는 질문을 확인해보세요.</H1>
      <H2>
        찾으시는 내용이 없나요?
        <br />
        씨드앤 카카오채널 (채널명 확인) 로 문의해주세요.
      </H2>
      <SearchBox>
        <SearchInput placeholder={`'스케쥴 기능'이라고 검색해보세요.`} />
        <SearchButton>
          <IconSearch stroke={SEEDN_NEW} />
        </SearchButton>
      </SearchBox>
    </Container>
  );
};

export default FaqHeader;

const Container = styled.section`
  width: 924px;
  margin: 0 auto 136px;

  @media (max-width: 768px) {
    width: 320px;
    margin-bottom: 50px;
  }
`;

const H1 = styled.h1`
  padding-top: 125px;
  margin-bottom: 30px;
  font-family: 'Nanum Gothic', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;

  @media (max-width: 768px) {
    padding-top: 60px;
    margin-bottom: 10px;
    font-family: 'Noto Sans KR';
    font-size: 18px;
    line-height: 26px;
  }
`;

const H2 = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
    opacity: 0.6;
    margin-bottom: 27px;
  }
`;

const SearchBox = styled.div`
  position: relative;
  height: 60px;

  @media (max-width: 768px) {
    width: 312px;
    height: 44px;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  border: 3px solid rgba(112, 51, 204, 0.0980392);
  border-radius: 10px;
  padding: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;

  &::placeholder {
    color: ${GREY};
  }

  @media (max-width: 768px) {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 20px;
    border: 2px solid #f1ebfa;
    border-radius: 6px;
  }
`;

const SearchButton = styled.button`
  width: 23px;
  height: 23px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    right: 16px;
  }
`;
