import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { BigText, Container, NormalText, PurpleText } from './ControlDesc';
import { Circle, StyledProps } from './UserDesc';
import leafLogin from 'assets/imgs/service/leafLogin.png';
import leafGroup from 'assets/imgs/service/leafGroup.png';
import useWindowSize from 'hooks/common/useWindowSize';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

interface IEnglish {
  isEnglish: boolean;
}

function SyncDesc() {
  const [transition, setTransition] = useState(false);
  const { t } = useTranslation('services');
  const [offset, setOffset] = useState(0);
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const STEP = isSmallScreen ? 120 : 350;
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setTransition(true);
    }
  };

  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';

  useEffect(() => {
    if (transition) {
      setTimeout(() => {
        setOffset(STEP);
      }, 1000);
      setTimeout(() => {
        setOffset(STEP * 2);
      }, 2000);
    }
  }, [transition]);
  const threshold = 1;
  const setTarget = useIntersectionObserver({ onIntersect, threshold });
  return (
    <MarginContainer>
      <RightCircle ref={setTarget} offset={offset} />
      <PurpleText>
        <Trans t={t} i18nKey="sync.purple" />
      </PurpleText>
      <BigText>
        <span>
          <Trans t={t} i18nKey="sync.big1" />
        </span>
        <span>
          <Trans t={t} i18nKey="sync.big2" />
        </span>
        <span>
          {isSmallScreen ? (
            <>
              <Trans t={t} i18nKey="sync.smallBig3" />
            </>
          ) : (
            <>
              <Trans t={t} i18nKey="sync.big3" />
            </>
          )}
        </span>
      </BigText>
      <MarginNormalText>
        <Trans t={t} i18nKey="sync.normal" />
      </MarginNormalText>
      <GrayText isEnglish={isEnglish}>
        <Trans t={t} i18nKey="sync.gray" />
      </GrayText>
      <Phone
        src={
          offset > 350 || (isSmallScreen && offset > 120)
            ? leafGroup
            : leafLogin
        }
        draggable="false"
        offset={offset}
      />
    </MarginContainer>
  );
}

const MarginContainer = styled(Container)`
  position: relative;
  margin-bottom: 537px;
  @media (max-width: 767px) {
    margin-bottom: 48.5px;
  }
`;

const MarginNormalText = styled(NormalText)`
  margin-bottom: 0;
  height: 80px;
`;

const RightCircle = styled(Circle)<StyledProps>`
  position: absolute;
  top: -170px;
  right: 10px;
`;

const GrayText = styled.div<IEnglish>`
  font-weight: 500;
  font-size: 14px;
  line-height: ${props => (props.isEnglish ? '25px' : '40px')};
  color: #111111;
  opacity: 0.5;
  margin-top: ${props => props.isEnglish && '50px'};

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #111111;
    opacity: 0.3;
    width: 166px;
  }
`;

const Phone = styled.img<StyledProps>`
  width: 168px;
  height: 337px;
  position: absolute;
  right: 86px;
  transition: all 0.5s;
  ${({ offset }) => css`
    clip-path: circle(160px at 50% ${offset === 350 ? 320 : 70}px);
    top: ${offset === 350 ? offset - 330 : offset - 80}px;
  `}
  @media (max-width: 767px) {
    width: 66px;
    height: 132.5px;
    right: 40px;
    ${({ offset }) => css`
      clip-path: circle(63px at 50% ${offset === 120 ? 126 : 30}px);
      top: ${offset === 120 ? offset - 233 : offset - 137}px;
    `}
  }
`;

export default SyncDesc;
