import styled from 'styled-components';
import { SEEDN_NEW } from 'styles/palette';

interface Props {
  error?: string;
  [x: string]: any;
}

const Input = ({ error, ...props }: Props) => {
  return <StyledInput error={error} {...props} />;
};

const StyledInput = styled.input<{ error?: string }>`
  display: flex;
  padding: 0 15px;
  height: 60px;
  border: ${({ error }) =>
    !error ? '2px solid #111' : '1px solid transparent'};
  box-shadow: ${({ error }) =>
    error === '확인.'
      ? `0 0 0 3px ${SEEDN_NEW} inset`
      : error && error !== '확인.'
      ? `0 0 0 3px #ff0000 inset`
      : ''};
  border-radius: 10px;
  color: #111;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  width: 100%;
  -webkit-appearance: none;

  &::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #aaa;
  }

  &[type='file'] {
    display: none;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
    height: 50px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export default Input;
