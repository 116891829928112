import { SEEDN_GREEN, SEEDN_MO, GREY_MO } from 'styles/palette';
import styled from 'styled-components';

interface IButton {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  color?: string;
  onClick?: (e?: any) => void;
}

const ApplyButton = ({
  children,
  className,
  disabled,
  color,
  onClick,
}: IButton) => {
  return (
    <StyledButton
      disabled={disabled}
      className={className}
      color={color}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<IButton>`
  padding: 8px 0;
  width: 100%;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;
  transition: all 200ms ease-in;
  background-color: ${({ color }) => (color === 'grey' ? '#aaa' : SEEDN_GREEN)};
  background-color: ${({ disabled }) => disabled && '#aaa'};

  &:hover {
    background-color: ${({ color }) => (color === 'grey' ? GREY_MO : SEEDN_MO)};
    background-color: ${({ disabled }) => disabled && '#aaa'};
  }

  @media (max-width: 767px) {
    padding: 10px 48px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

export default ApplyButton;
