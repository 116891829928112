import styled from 'styled-components';

const BackgroundBlock = styled.div<any>`
  min-width: 360px;
  background: ${(props: any) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: ${props => props.bgColor};

  @media (max-width: 767px) {
    background: ${(props: any) =>
      props.mobileImg ? `url(${props.mobileImg})` : `url(${props.img})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: ${props => props.bgColor};
  }

  @media (max-width: 479px) {
    background: ${(props: any) =>
      props.mobileImgSmall
        ? `url(${props.mobileImgSmall})`
        : `url(${props.img})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: ${props => props.bgColor};
  }
`;

const Background = ({
  img,
  mobileImg,
  mobileImgSmall,
  bgColor,
  children,
  ...rest
}: any) => {
  return (
    <BackgroundBlock
      img={img}
      mobileImg={mobileImg}
      mobileImgSmall={mobileImgSmall}
      bgColor={bgColor}
      {...rest}
    >
      {children}
    </BackgroundBlock>
  );
};

export default Background;
