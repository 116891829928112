import styled, { css } from 'styled-components';
import {
  englishSlideIn,
  englishSlideOut,
  engmobileSlidein,
  engmobileSlideout,
  mobileSlidein,
  mobileSlideout,
  slidein,
  slideout,
} from 'styles/animations';
import { Mode } from 'pages/ServicesPage';
import { useTranslation, Trans } from 'react-i18next';
import useWindowSize from 'hooks/common/useWindowSize';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

interface StyledProps {
  mode?: Mode;
  isEnglish?: boolean;
}

interface Props {
  mode: Mode;
  setMode: (mode: Mode) => void;
}

function Start({ mode, setMode }: Props) {
  const { t } = useTranslation('services');
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';

  return (
    <Container>
      <MaxWidth>
        <GrayText
          style={{
            top: isEnglish
              ? isSmallScreen
                ? '100px'
                : '165px'
              : isSmallScreen
              ? '110px'
              : '155px',
          }}
        >
          {isSmallScreen ? (
            <Trans t={t} i18nKey="hero.mobilesmall" />
          ) : (
            <Trans t={t} i18nKey="hero.small" />
          )}
        </GrayText>
        <Text isEnglish={isEnglish}>
          <span>
            <Trans t={t} i18nKey="hero.big1" />
          </span>
          <span>
            <Trans t={t} i18nKey="hero.big2" />
          </span>
          <span>
            <Trans t={t} i18nKey="hero.big3" />
          </span>
        </Text>
        {/* <Button>
          <button>결제하기</button>
        </Button> */}
        <Tabs isEnglish={isEnglish}>
          <div onClick={() => setMode('outline')}>
            <Trans t={t} i18nKey="hero.outline" />
          </div>
          <div onClick={() => setMode('spec')}>
            <Trans t={t} i18nKey="hero.spec" />
          </div>
        </Tabs>
        <Indicator mode={mode} isEnglish={isEnglish} />
      </MaxWidth>
    </Container>
  );
}

const Container = styled.section`
  position: relative;
  background: #000;
  height: 550px;
  color: white;
  font-family: 'Nanum Gothic', sans-serif;
  @media (max-width: 767px) {
    height: 406px;
  }
`;

const MaxWidth = styled.div`
  position: relative;
  max-width: 1023px;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    max-width: 360px;
  }
`;

const GrayText = styled.div<StyledProps>`
  font-weight: 400;
  font-size: 20px;
  opacity: 0.8;
  position: absolute;
  left: 60px;
  top: 156px;
  @media (max-width: 767px) {
    left: 24px;
    top: 115px;
  }
`;

const Text = styled.div<StyledProps>`
  line-height: ${props => props.isEnglish && '60px'};
  position: absolute;
  font-weight: 700;
  font-size: 40px;
  width: 490px;
  height: 183px;
  left: 60px;
  top: 206px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 767px) {
    left: 24px;
    top: 151px;
    font-weight: 700;
    font-size: 24px;
    height: 105px;
    line-height: 35px;
    margin-top: ${props => props.isEnglish && '30px'};
  }
`;

const Button = styled.div`
  position: absolute;
  width: 162px;
  height: 46px;
  right: 80px;
  top: 280px;
  background: #6f36c9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > button {
    color: white;
    font-size: 24px;
    font-weight: 700;
  }
  @media (max-width: 767px) {
    width: 124px;
    height: 35px;
    right: 212px;
    top: 296px;
    & > button {
      font-size: 16px;
    }
  }
`;

const Tabs = styled.div<StyledProps>`
  position: absolute;
  top: 487px;
  left: 60px;
  font-weight: 500;
  font-size: 24px;
  width: ${props => (props.isEnglish ? '220px' : '180px')};
  height: 61px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div:hover {
    color: #8f55ec;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    top: 371px;
    left: 25px;
    width: ${props => (props.isEnglish ? '133px' : '123px')};
    font-size: 16px;
    line-height: 23px;
    height: 23px;
  }
`;

const Indicator = styled.div<StyledProps>`
  width: ${props => (props.isEnglish ? '120px' : '43px')};
  left: 60px;
  background: #8f55ec;
  height: 3px;
  position: absolute;
  bottom: 0;
  ${({ mode, isEnglish }) => css`
    animation: ${mode === 'outline'
        ? isEnglish
          ? englishSlideIn
          : slidein
        : mode === 'spec'
        ? isEnglish
          ? englishSlideOut
          : slideout
        : null}
      0.5s forwards;
  `}
  @media (max-width: 767px) {
    left: 24px;
    width: ${props => (props.isEnglish ? '80px' : '32px')};
    ${({ mode, isEnglish }) => css`
      animation: ${mode === 'outline'
          ? isEnglish
            ? engmobileSlidein
            : mobileSlidein
          : mode === 'spec'
          ? isEnglish
            ? engmobileSlideout
            : mobileSlideout
          : null}
        0.5s forwards;
    `}
  }
`;

export default Start;
