import ConfirmModal from 'components/common/ConfirmModal';
import Responsive from 'components/common/Responsive';
import { AppDispatch, RootState } from 'index';
import { IconDelete, IconEdit } from 'styles/Icons';
import { DARK_THEME, SEEDN_GREEN, THEME } from 'styles/palette';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { usePostViewer } from 'hooks/careers/usePostViewer';
import { copyWriteForm, deletePost } from 'slices/careersSlice';
import useCopyToClipboard from 'hooks/common/useCopyToClipboard';
import Header from 'components/common/Header';
import Button from 'components/careers/CareerButton';
import FloatingButton from 'components/careers/FloatingButton';
import { ReactComponent as IconBack } from 'assets/icons/common/back.svg';
import { ReactComponent as IconLink } from 'assets/icons/common/link.svg';
import { ReactComponent as IconHeart } from 'assets/icons/common/heart.svg';
import { ReactComponent as IconChecked } from 'assets/icons/common/check.svg';
import { ReactComponent as IconCopyLink } from 'assets/icons/common/link.svg';

const PostViewer = () => {
  const { postId } = useParams();
  const { positionDetail } = usePostViewer(postId);
  const { authCheck } = useSelector(({ auth }: RootState) => auth);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const stringHTML = positionDetail.content;
  const dispatch = useDispatch<AppDispatch>();
  const { copyToClipboard } = useCopyToClipboard();
  const [copied, setCopied] = useState<boolean>(false);
  const [active, setActive] = useState({
    back: false,
    link: false,
    heart: false,
  });
  const [heart, setHeart] = useState(false);

  const onCopy = () => {
    copyToClipboard(`https://www.seedn.co.kr${String(location.pathname)}`, '');
    setCopied(prev => true);
  };

  return (
    <>
      {positionDetail.mainImage && (
        <MainImg src={positionDetail.mainImage} alt="img" />
      )}
      <StyledResponsive>
        <PostViewerBlock>
          <div className="header">
            <div className="position">{positionDetail.position}</div>
            {authCheck && (
              <div className="icons-wrapper">
                <IconEdit
                  onClick={() => {
                    dispatch(copyWriteForm(positionDetail));
                    navigate(`/careers/write/${postId}`);
                  }}
                />
                <IconDelete onClick={() => setIsConfirmModal(true)} />
              </div>
            )}
          </div>
          <div className="main-wrapper">
            <div className="post-wrapper">
              <div
                className="draft"
                dangerouslySetInnerHTML={{ __html: stringHTML }}
              ></div>
            </div>
            <div className="apply-wrapper">
              <div className="apply-row">
                <div className="label">고용 형태</div>
                <div className="content">{positionDetail.contract}</div>
              </div>
              <div className="apply-row">
                <div className="label">직군</div>
                <div className="content">{positionDetail.group}</div>
              </div>
              <div className="apply-row">
                <div className="label">공유하기</div>
                {copied ? (
                  <>
                    <IconChecked />
                    <Tag>URL이 복사되었습니다.</Tag>
                  </>
                ) : (
                  <IconCopyLink
                    onClick={onCopy}
                    style={{ cursor: 'pointer', transform: 'scale(1.2)' }}
                  />
                )}
              </div>
              <ButtonGroup>
                <Link to={`/careers/apply/${postId}`}>
                  <Button width="100%">지원하기</Button>
                </Link>
                <Link to={`/careers`}>
                  <Button
                    width="100%"
                    color="grey"
                  >{`채용공고 돌아가기`}</Button>
                </Link>
              </ButtonGroup>
            </div>
          </div>
          <FloatingButtonGroup>
            <FloatingButton
              color="grey"
              className={active.back ? 'active' : ''}
              onClick={() => {
                navigate(-1);
                setActive({ ...active, back: !active.back });
              }}
            >
              <IconBack />
            </FloatingButton>
            <FloatingButton
              tag={'링크 복사됨'}
              className={active.link ? 'active' : ''}
              onClick={() => {
                onCopy();
                setActive({ ...active, link: !active.link });
              }}
            >
              <IconLink />
            </FloatingButton>
            <FloatingButton
              tag={'지원하기'}
              className={active.heart ? 'active' : heart ? 'heart' : ''}
              onClick={() => {
                if (active.heart) {
                  setHeart(true);
                  navigate(`/careers/apply/${postId}`);
                }
                setActive({ ...active, heart: !active.heart });
              }}
            >
              <IconHeart />
            </FloatingButton>
          </FloatingButtonGroup>
        </PostViewerBlock>
      </StyledResponsive>
      {isConfirmModal && (
        <ConfirmModal
          onClose={() => setIsConfirmModal(false)}
          onClick={async () => {
            await dispatch(deletePost(Number(postId)));
            navigate('/careers');
          }}
          msg="정말 삭제하시겠습니까?"
        />
      )}
    </>
  );
};

export default PostViewer;

const MainImg = styled.img`
  width: 100%;
  max-height: 300px;
  object-fit: cover;
`;

const StyledResponsive = styled(Responsive)`
  min-height: 60%;
`;

const PostViewerBlock = styled.div`
  width: auto;
  margin: 0 30px;
  padding-top: 50px;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 26px;
    justify-content: space-between;

    .position {
      font-weight: 900;
      font-size: 36px;
      line-height: 52px;
      color: #000;
    }

    .icons-wrapper {
      display: flex;

      div {
        padding: 8px;
        display: flex;
        justify-content: center;
        background-color: ${THEME};
        border-radius: 10px;
        color: #fff;
        font-size: 18px;

        :hover {
          background-color: ${DARK_THEME};
        }
      }

      div + div {
        margin-left: 10px;
      }
    }
  }

  .main-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  .post-wrapper {
    width: auto;
    flex: 1;
    .bottom {
      margin: 70px 0 50px 0;
      display: flex;
      color: rgb(107, 118, 132);
    }

    .draft {
      width: fit-content;
      margin-bottom: 160px;

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 16px;
      }

      h3:first-of-type {
        margin-top: 124px;
      }
    }

    ul {
      padding-left: 24px;
      &:not(:last-child) {
        margin-bottom: 160px;
      }

      li {
        color: rgb(78, 89, 104);
        margin: 8px 0px;
        line-height: 24px;
        list-style: disc;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;

        color: #272727;
      }
    }
  }

  .apply-wrapper {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 100px;
    width: 260px;
    margin-left: 70px;
    border-radius: 3px;
    height: fit-content;

    .apply-row {
      position: relative;
      display: flex;
      align-items: center;
      padding: 14px 0;
      border-bottom: 1px solid #848484;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #000;

      &:first-child {
        border-top: 1px solid #848484;
      }

      .label {
        width: 120px;
      }

      .content {
        font-weight: 700;
      }

      svg {
        height: 17px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    width: auto;
    margin: 0;
    padding: 0 24px;
    position: relative;

    .header {
      padding: 50px 0 30px;

      .position {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
      }
    }

    .main-wrapper {
      flex-direction: column-reverse;

      .post-wrapper .draft {
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          display: inline;
        }
        h3 {
          margin-bottom: 12px;
          font-weight: 700;
          font-size: 16px;
          line-height: 23px;
        }
        ul {
          margin-bottom: 70px;
        }
        li {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }

      .apply-wrapper {
        position: static;
        margin: 0;
        margin-bottom: 70px;

        .apply-row {
          padding: 6px 0;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;

          &:nth-child(3) {
            display: none;
          }

          .content {
            color: ${SEEDN_GREEN};
          }
        }
      }
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 69px;

  @media (max-width: 767px) {
    margin-top: 40px;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 16px;

    button {
      width: 100%;
      padding: 10px 34px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
    }
  }
`;

const FloatingButtonGroup = styled.div`
  display: none;
  z-index: 30;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 30px;
    right: 16px;
  }
`;

const Tag = styled.span`
  position: absolute;
  bottom: -44px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 22px;
  border-radius: 18px;
  background-color: #e7e7e7;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: #272727;
  animation: fade 500ms ease 2500ms forwards;

  @keyframes fade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
