import { AppDispatch, RootState } from 'index';
import { getPositionDetail } from 'slices/careersSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const usePostViewer = (id?: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const { positionDetail } = useSelector((state: RootState) => ({
    positionDetail: state.careers.positionDetail,
  }));

  useEffect(() => {
    (async () => {
      await dispatch(getPositionDetail(Number(id)));
    })();
  }, [dispatch, id]);

  return { positionDetail };
};
