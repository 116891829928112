import { apply, getPost } from 'apis/careers';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEmail, validatePhone } from 'utils/validate';

interface Inputs {
  name: string;
  phone: string;
  email: string;
  resume: File | null;
  portfolio: File | null;
  career: File | null;
}

const useApplyForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { postId } = useParams();
  const [position, setPosition] = useState('');
  const [inputs, setInputs] = useState<Inputs>({
    name: '',
    phone: '',
    email: '',
    resume: null,
    portfolio: null,
    career: null,
  });
  const [localError, setLocalError] = useState({
    name: '',
    phone: '',
    email: '',
    resume: '',
  });
  const [error, setError] = useState('');
  const [confirmModal, setConfirmModal] = useState({
    back: false,
    submit: false,
  });
  const [resultModal, setResultModal] = useState(false);
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getPost(Number(postId));
        setPosition(data.data.position);
      } catch (e) {
        setResultModal(true);
        setError(`오류가 발생했습니다. 다시 시도해주세요.`);
        setIsReload(true);
      }
    })();
  }, [postId]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'name') {
      if (!value) {
        setLocalError(prev => ({
          ...prev,
          [name]: '이름을 입력해주세요.',
        }));
      } else {
        setLocalError(prev => ({
          ...prev,
          [name]: '확인.',
        }));
      }
    }

    if (name === 'phone') {
      const validateResult = validatePhone(value);
      setLocalError(prev => ({
        ...prev,
        [name]: validateResult.phone,
      }));
    }

    if (name === 'email') {
      const validateResult = validateEmail(value);
      setLocalError(prev => ({
        ...prev,
        [name]: validateResult.email,
      }));
    }

    setInputs(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onCloseModal = (isSuccess = true) => {
    if (isSuccess) {
      navigate('/careers');
    } else {
      setResultModal(false);
      setError('');
      if (isReload) {
        navigate(0);
      }
    }
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (!e.target.files) return;
    const file = e.target.files[0];
    e.target.value = '';

    if (file.size > 1024 * 1024 * 27) {
      setResultModal(true);
      setError('첨부파일 사이즈는 30MB 이내로 가능합니다.');
      return;
    }

    setInputs(prev => ({
      ...prev,
      [name]: file,
    }));
  };

  const onSubmit = () => {
    if (!position) {
      setResultModal(true);
      setConfirmModal({ back: false, submit: false });
      setError(`오류가 발생했습니다. 다시 시도해주세요.`);
      setIsReload(true);
      return;
    }
    const { name, phone, email, ...files } = inputs;

    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('position', position);

    const promises = Object.entries(files).map(async ([key, value]) => {
      if (!value) return;
      formData.delete('resume');
      formData.delete('portfolio');
      formData.append(key, value);
      return await apply(formData);
    });

    (async () => {
      try {
        setIsLoading(true);
        await Promise.all(promises);
        setIsLoading(false);
        setResultModal(true);
        setConfirmModal({ back: false, submit: false });
      } catch (e) {
        setIsLoading(false);
        setResultModal(true);
        setConfirmModal({ back: false, submit: false });
        setError(
          `죄송합니다, 서버 문제로 지원서가 제출되지 않았습니다.
          아래 이메일로 지원서를 제출해주세요.
          (recruit@seedn.co.kr)`,
        );
      }
    })();
  };

  return {
    position,
    inputs,
    localError,
    error,
    resultModal,
    setInputs,
    onCloseModal,
    onChange,
    onChangeFile,
    onSubmit,
    confirmModal,
    setConfirmModal,
    isLoading,
  };
};

export default useApplyForm;
