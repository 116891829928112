import styled from 'styled-components';
import BackgroundImage from 'assets/imgs/careers/careers-bg.webp';
import BackgroundImageMobile from 'assets/imgs/careers/careers-bg-mobile.jpg';
import { StrongText, Z_INDEX } from 'styles';
import { useRef } from 'react';
import useScrollAnimation from 'hooks/careers/useScrollAnimation';

const ScrollSection = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const text1 = useRef<HTMLDivElement>(null);
  const text2 = useRef<HTMLDivElement>(null);
  const text3 = useRef<HTMLDivElement>(null);
  const layer = useRef<HTMLDivElement>(null);
  const image = useRef<HTMLImageElement>(null);
  const mobileImage = useRef<HTMLDivElement>(null);

  const scrollHeight = useScrollAnimation({
    scrollRef,
    text1,
    text2,
    text3,
    layer,
    image,
    mobileImage,
  });

  return (
    <Container ref={scrollRef}>
      <ScrollArea>
        <ImageWrapper height={scrollHeight}>
          <Layer ref={layer}></Layer>
          <img
            className="desktop"
            ref={image}
            src={BackgroundImage}
            alt="배경"
          />
          <MobileImageWrapper ref={mobileImage}>
            <img className="mobile" src={BackgroundImageMobile} alt="배경" />
          </MobileImageWrapper>
        </ImageWrapper>

        <div>
          <Sticky ref={text1}>
            <h1>
              <StrongText>씨드앤</StrongText>과 온도를 혁신할
              <br />
              <StrongText>새로운 동료</StrongText>를 찾습니다.
            </h1>
          </Sticky>
          <Sticky ref={text2}>
            <h3>
              씨드앤은 도시 탄소배출의 70%이상을 차지하는
              <br />
              건물의 냉난방 에너지 문제를 해결합니다.
            </h3>
          </Sticky>
          <Sticky ref={text3}>
            <h3>
              구성원들은 환경변화의 심각성에 공감하고
              <br />
              지속가능한 환경과 사람 중심의 온도 경험으로
              <br />
              세상의 변화를 만드는 데 뜻을 함께하고 있습니다.
            </h3>
          </Sticky>
        </div>
        <Transparent />
      </ScrollArea>
    </Container>
  );
};

export default ScrollSection;

const Container = styled.section`
  width: 100%;
  position: relative;
`;

const ScrollArea = styled.div``;

const ImageWrapper = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};

  img {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -10;

    &.mobile {
      display: none;
    }
  }

  @media (max-width: 767px) {
    height: ${({ height }) => `${height}px`};

    img.desktop {
      display: none;
    }

    img.mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      width: 100%;
      height: auto;
      z-index: ${Z_INDEX.SCROLL_IMAGE};
    }
  }
`;

const MobileImageWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    position: sticky;
    top: 0;
    display: flex;
    height: 100vh;
    background-color: #000;
  }
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0);
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX.SCROLL_LAYER};
`;

const Sticky = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  text-align: center;
  opacity: 0;
  color: #fff;
  z-index: ${Z_INDEX.SCROLL_TEXT};
  white-space: nowrap;

  h1 {
    font-size: 52px;
  }
  h3 {
    font-size: 26px;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 18px;
      line-height: 26px;
    }
    h3 {
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      letter-spacing: -0.02em;
    }
  }
`;

const Transparent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX.SCROLL_TRANSPARENT};
`;
