import { useLoginForm } from 'hooks/auth/useLoginForm';
import { DARK_THEME, THEME } from 'styles/palette';
import styled from 'styled-components';

const LoginFormBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 60px;

  .title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  input {
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 10px;
  }

  input + input {
    margin-top: 15px;
  }

  button {
    width: 100%;
    background-color: ${THEME};
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 15px 40px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;

    :hover {
      background-color: ${DARK_THEME};
    }
  }
`;
interface Props {
  onClose: () => void;
}

const LoginForm = ({ onClose }: Props) => {
  const { userId, password, error, onChange, login } = useLoginForm(onClose);

  return (
    <LoginFormBlock>
      <div className="title">관리자 로그인</div>
      <input
        type="text"
        placeholder="Id"
        name="userId"
        value={userId}
        onChange={onChange}
        autoFocus
      />
      <input
        type="password"
        placeholder="Password"
        name="password"
        value={password}
        onChange={onChange}
      />
      {error && (
        <div style={{ color: 'red', fontSize: 12, marginTop: 15 }}>{error}</div>
      )}
      <button type="button" onClick={login}>
        로그인
      </button>
    </LoginFormBlock>
  );
};

export default LoginForm;
