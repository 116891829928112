import { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import realSensor from 'assets/imgs/service/realSensor.png';
import realController from 'assets/imgs/service/realController.png';
import halfController from 'assets/imgs/service/halfcontroller.png';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

export interface StyledProps {
  type: number;
  isEnglish?: boolean;
}

function Divided() {
  const [type, setType] = useState(0);
  const { t } = useTranslation('services');
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';
  const onClick = () => {
    if (type < 2) {
      setType(prev => prev + 1);
    } else {
      setType(0);
    }
  };
  return (
    <Container onClick={onClick}>
      <BlackBox type={type}>
        <p>
          <Trans t={t} i18nKey="payment.black1" />
        </p>
        <p>
          <Trans t={t} i18nKey="payment.black2" />
        </p>
        {isEnglish && (
          <>
            <p>
              <Trans t={t} i18nKey="payment.tempblack2" />
            </p>
          </>
        )}
        <p style={{ letterSpacing: '-0.04em' }}>
          <Trans t={t} i18nKey="payment.black3" />
        </p>
        {type === 1 && (
          <SmallText>
            <Trans t={t} i18nKey="payment.star" />
          </SmallText>
        )}
        <FakeSensor src={realSensor} type={type} />
        <Sensor src={realSensor} type={type} />
        <HideLeft></HideLeft>
      </BlackBox>
      <WhiteBox type={type} isEnglish={isEnglish}>
        <p>
          <Trans t={t} i18nKey="payment.white1" />
        </p>
        <p>
          <Trans t={t} i18nKey="payment.white2" />
        </p>
        <p>
          <Trans t={t} i18nKey="payment.white3" />
        </p>
        <p>
          <Trans t={t} i18nKey="payment.white4" />
        </p>
        <p>
          <Trans t={t} i18nKey="payment.white5" />
        </p>
        <FakeController src={halfController} type={type} />
        <Controller src={realController} type={type} />
        <HideRight></HideRight>
      </WhiteBox>
    </Container>
  );
}

const Container = styled.div`
  width: 1024px;
  height: 791px;
  top: 0;
  display: flex;
  margin-bottom: 150px;
  position: relative;
`;
const HideLeft = styled.div`
  position: absolute;
  width: 310px;
  height: 99%;
  top: 2px;
  right: 2px;
  background: black;
  z-index: -1;
`;
const HideRight = styled.div`
  position: absolute;
  width: 440px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background: white;
`;

const BlackBox = styled.div<StyledProps>`
  z-index: 2;
  background: black;
  padding: 50px;
  padding-top: 291px;
  transition: width 0.7s;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  ${({ type }) =>
    type === 1
      ? css`
          width: 913px;
          z-index: 10;
        `
      : type === 2
      ? css`
          width: 512px;
          z-index: 1;
        `
      : css`
          width: 512px;
          z-index: 0;
        `}
  & > p {
    color: white;
    font-weight: 700;
    font-size: 30px;
    text-align: right;
    height: 70px;
    line-height: 70px;
  }
  &::after {
    content: '1';
    position: absolute;
    width: 40px;
    height: 40px;
    background: #6f36c9;
    top: 376px;
    right: 370px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: #ffffff;
    transition: all 0.7s;
    ${({ type }) => {
      if (type === 2) {
        return css`
          filter: blur(1px);
          right: 436px;
          opacity: 0.5;
        `;
      }
    }}
  }
`;

const WhiteBox = styled.div<StyledProps>`
  background: white;
  font-weight: 700;
  font-size: 30px;
  padding: 50px;
  padding-top: 221px;
  transition: width 0.7s;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  ${({ type }) =>
    type === 1
      ? css`
          width: 512px;
          z-index: 3;
        `
      : type === 2
      ? css`
          width: 913px;
          z-index: 10;
        `
      : css`
          width: 512px;
        `}
  & > p {
    z-index: 100;
    height: 70px;
    line-height: 70px;
  }
  &::after {
    content: '2';
    position: absolute;
    width: 40px;
    height: 40px;
    background: #6f36c9;
    top: ${props => (props.isEnglish ? '150px' : '376px')};
    left: 372px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: #ffffff;
    transition: all 0.7s;
    ${({ type }) => {
      if (type === 1) {
        return css`
          filter: blur(1px);
          left: 426px;
          opacity: 0.5;
        `;
      }
    }}
  }
`;

const FakeSensor = styled.img<StyledProps>`
  position: absolute;
  top: 60px;
  left: 384px;
  width: 287px;
  height: 336px;
  transition: all 0.7s;
  opacity: 0;
  ${({ type }) => {
    if (type === 2) {
      return css`
        transform: translateX(-360px);
        opacity: 0.5;
      `;
    }
  }};
`;

const fadeout = keyframes`
  0% {
    transform: scale(1.8) translate(-100px, 30px);
    opacity:1;
  }
  99% {
    transform: scale(1.8) translate(-100px, 30px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(0,0);
    opacity: 1;
  }
`;

const Sensor = styled.img<StyledProps>`
  position: absolute;
  top: 60px;
  left: 384px;
  width: 286px;
  height: 336px;
  z-index: 0;
  transition: transform 0.7s, opacity 0.7s;
  ${({ type }) => {
    if (type === 1) {
      return css`
        z-index: -1;
        transform: scale(1.8) translate(-100px, 30px);
      `;
    }
    if (type === 2) {
      return css`
        animation: ${fadeout} 0.7s;
      `;
    }
  }};
`;

const FakeController = styled.img<StyledProps>`
  position: absolute;
  left: 0px;
  width: 142px;
  height: 252px;
  bottom: -10px;
  transition: all 0.7s;
  opacity: 1;
  ${({ type }) => {
    if (type === 1) {
      return css`
        left: 360px;
        opacity: 0.5;
      `;
    }
    if (type === 2) {
      return css`
        left: 400px;
        bottom: 200px;
        opacity: 0;
        z-index: -2;
      `;
    }
  }}
`;

const Controller = styled.img<StyledProps>`
  position: absolute;
  left: 500px;
  width: 378px;
  height: 389px;
  bottom: -10px;
  transition: all 0.7s;
  opacity: 0;
  ${({ type }) => {
    if (type === 2) {
      return css`
        left: 450px;
        transform: scale(1.3);
        opacity: 1;
      `;
    }
  }}
`;

const SmallText = styled.div`
  font-size: 14px;
  line-height: 40px;
  text-align: right;
  letter-spacing: -0.04em;
  color: white;
  margin-top: 10px;
`;

export default Divided;
