import CareersApplyForm from 'components/careers/ApplyForm';
import CareersPostsViewer from 'components/careers/PostsViewer';
import CareersPostViewer from 'components/careers/PostViewer';
import CareersWriter from 'components/careers/Writer';
import Private from 'components/common/Private';
import UserGuide from 'components/common/UserGuide';
import AboutPage from 'pages/AboutPage';
import CareersPage from 'pages/CareersPage';
import InfoPage from 'pages/InfoPage';
import MainPage from 'pages/MainPage';
import AskPage from 'pages/AskPage';
import FindEmail from 'pages/FindEmail';
import FindPassword from 'pages/FindPassword';
import { Route, Routes } from 'react-router-dom';
import FaqPage from 'pages/FaqPage';
import SignUpPage from 'pages/SignUpPage';
import SignUpForm from 'components/signup/SignUpForm';
import SignUpSuccess from 'components/signup/SignUpSuccess';
import LoginPage from 'pages/LoginPage';
import MyPage from 'pages/MyPage';
import MyPageIndex from 'components/mypage/MyPageIndex';
import ServicesPage from 'pages/ServicesPage';
import AskResult from 'components/ask/AskResult';
import AskForm from 'components/ask/AskForm';

const App = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<MainPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="careers" element={<CareersPage />}>
          <Route index element={<CareersPostsViewer />} />
          <Route path=":postId" element={<CareersPostViewer />} />
          <Route path="write">
            <Route index element={<CareersWriter />} />
            <Route path=":postId" element={<CareersWriter edit={true} />} />
          </Route>
          <Route path="apply/:postId" element={<CareersApplyForm />} />
        </Route>
        <Route path="services" element={<ServicesPage />} />
        <Route path="info" element={<InfoPage />}>
          <Route index element={<UserGuide />} />
          <Route path="private" element={<Private />} />
        </Route>
        <Route path="ask" element={<AskPage />} />
        <Route path="faq" element={<FaqPage />} />
        {/* <Route path="signup" element={<SignUpPage />}>
          <Route index element={<SignUpForm />} />
          <Route path="success" element={<SignUpSuccess />} />
        </Route> */}
        {/* <Route path="login">
          <Route index element={<LoginPage />} />
          <Route path="find_email" element={<FindEmail />} />
          <Route path="find_password" element={<FindPassword />} />
        </Route> */}
        <Route path="ask" element={<AskPage />}>
          <Route index element={<AskForm />} />
          <Route path="result/:result" element={<AskResult />} />
        </Route>
      </Route>
      {/* <Route path="mypage" element={<MyPage />}>
        <Route index element={<MyPageIndex />} />
      </Route> */}
    </Routes>
  );
};

export default App;
