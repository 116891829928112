import styled from 'styled-components';
import sensorImage from 'assets/imgs/service/sensor.svg';
import RealSensor from 'assets/imgs/service/realSensor.png';
import { Circle, Hover, HubLeaf, Shadow, Text, TextBox } from './HubCircle';
import useWindowSize from 'hooks/common/useWindowSize';
import { Props } from './HubCircle';
import { useTranslation, Trans } from 'react-i18next';

function SensorCircle({ setMode }: Props) {
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const { t } = useTranslation('services');
  const onClick = () => {
    setMode('sensor');
  };
  const isEnglish = localStorage.getItem('i18nextLng') === 'en';
  return (
    <>
      <StyledSensorCircle onClick={onClick}>
        <img src={sensorImage} />
        <Text>
          <Trans t={t} i18nKey="flow.sensor" />
        </Text>
        {!isSmallScreen && (
          <Hover style={{ paddingTop: isEnglish ? 0 : '24px' }}>
            <RealSensorImage
              src={RealSensor}
              style={{ marginTop: isEnglish ? '24px' : 0 }}
            />
            <SmallTextBox>
              <div>
                <Trans t={t} i18nKey="flow.sensorText1" />
              </div>
              <div>
                <Trans t={t} i18nKey="flow.sensorText2" />
              </div>
            </SmallTextBox>
          </Hover>
        )}
      </StyledSensorCircle>
      <SensorLeaf />
      {isSmallScreen && <SensorShadow />}
    </>
  );
}

export const StyledSensorCircle = styled(Circle)`
  left: 553px;
  z-index: 0;
  &:hover > div {
    opacity: 1;
  }
  @media (max-width: 767px) {
    left: 52px;
    top: 355px;
    & > img {
      width: 54px;
      height: 54px;
    }
  }
`;

const RealSensorImage = styled.img`
  width: 140px;
  height: 175px;
  position: relative;
  left: -10px;
  top: 10px;
`;

export const SmallTextBox = styled(TextBox)`
  padding-top: 10px;
`;

export const SensorLeaf = styled(HubLeaf)`
  z-index: -1;
  left: 660px;
  @media (max-width: 767px) {
    left: 92px;
    top: 355px;
  }
`;
const SensorShadow = styled(Shadow)`
  position: absolute;
  left: 43px;
  top: 347px;
`;

export default SensorCircle;
