import useChangeLanguage from 'hooks/common/useChangeLanguage';
import styled from 'styled-components';
import { SEEDN_NEW } from 'styles/palette';

const LanguageSwitchButton = ({ ...props }) => {
  const { language, changeLanguage } = useChangeLanguage();
  return (
    <SwitchButton language={language} onClick={changeLanguage} {...props}>
      <span className="circle"></span>
      <span className="language">{language === 'ko' ? 'KO' : 'EN'}</span>
    </SwitchButton>
  );
};

const SwitchButton = styled.div<{ language: string }>`
  display: flex;
  flex-direction: ${({ language }) =>
    language === 'ko' ? 'row' : 'row-reverse'};
  align-items: center;
  gap: 10px;
  width: 74px;
  height: 34px;
  padding: 4px;
  background-color: ${({ language }) =>
    language === 'ko' ? '#fff' : SEEDN_NEW};
  box-shadow: inset 0px 1px 3px 2px rgba(0, 0, 0, 0.05);
  border-radius: 17.5px;
  transition: all 400ms ease-in-out;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  .circle {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: ${({ language }) =>
      language === 'ko' ? SEEDN_NEW : '#fff'};
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  }

  .language {
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: ${({ language }) => (language === 'ko' ? SEEDN_NEW : '#fff')};
  }

  @media (min-width: 767px) {
    box-shadow: ${({ language }) =>
      language === 'ko' && `inset 0px 1px 5px 2px rgba(0, 0, 0, 0.1);`};
  }
`;

export default LanguageSwitchButton;
