const about = {
  intro: {
    circles: {
      1: {
        title: 'Innovation',
        h3: `<span>SeedN creates</span>
        an eco friendly society.`,
        p: `SeedN stands for the seed of energy called Leaf,<br/> which grows into a tree for each building,<br />  and then connects like a network.
        <br />
        With a vision to expand from small shops to buildings, <br />and eventually to cities, SeedN embodies a vision<br /> to form a network anywhere in the world.
        <br />
        SeedN strives to bring innovation to the energy system,<br /> including the HVAC market.`,
        mobileP: `SeedN has a vision to expand from <br /> small shops to buildings and eventually to cities<br /> to form a network anywhere in the world.
        <br />
        SeedN strives to bring innovation<br /> to the energy system, including the HVAC market.
        <br />`,
      },
      2: {
        title: 'Energy',
        h3: `<span>Collecting wasted energy<br />to make more energy.`,
        p: `We are moving towards an AI-based energy management solution <br />that enables energy to be used in the right place <br />at the right temperature.
        <br />
        We are creating standards for AI solutions based on data.`,
        mobileP: `We are moving towards an AI-based<br/> energy management solution
        that enables energy <br/>to be used in the right place
        at the right temperature.<br/>
        We are creating standards for<br/> AI solutions based on data.
`,
      },
      3: {
        title: 'AIoT',
        h3: `Beyond convenience<br/>
        AIoT smart management system`,
        p: `Everyone can control
        the temperature easily.<br/> But it is not easy to manage a space where everyone is satisfied with the temperature.<br />
        SeedN makes standard of temperature and humidity<br /> based on continuous R&D and PoC field verification.
        <br />
        Beyond the limits of existing building management systems optimized for<br/> large buildings, we are achieving system innovation that can be applied to all spaces.
        <br />
        <br />
        We are striving for a world with the right temperature <br/> and continuing to innovate to change people's behavior patterns.`,
        mobileP: `SeedN makes standard of temperature and humidity<br/>
        based on continuous R&D and PoC field verification.<br/><br/> We are striving for a world with the right temperature<br/>
        and continuing to innovate to change <br/>people's behavior patterns.`,
      },
    },
    background: {
      1: {
        h3: `SeedN is creating an <br /> energy-friendly society.`,
        p: `'SeedN' represents the idea of a network, where <br /> Leafra, an energy seed, grows into a tree and connects <br /> like a network between buildings. <br /> SeedN aims to form a network anywhere in the world, <br /> from small stores to buildings and even cities. <br /> It embodies a vision of bringing innovation to <br /> the energy system and HVAC market.`,
      },
      2: {
        h3: `<span>Collecting </span> wasted energy <br />to create <span>more energy.</span>`,
        p: `SeedN is not only creating standards for temperature, <br /> but also for energy management. <br /> We believe that small energy savings accumulated daily <br />can become significant energy savings after a month or year.`,
      },
      3: {
        h3: `More than convenience, <br /> an AIoT smart <span>management system.</span>`,
        p: `SeedN focuses on temperature control and establishes <br /> new standards for it. <br /> By combining temperature and humidity data collected by <br /> Leaf sensors with building and weather data using AI technology, <br /> it derives the optimal temperature for each space. <br /> Continuous R&D and PoC allow standardization of temperature <br /> and humidity according to the characteristics of the space. <br /> SeedN's innovative system overcomes the limitations of <br /> existing building management systems optimized for large buildings <br /> and can be applied to any space.`,
      },
    },
  },
  number: {
    title: 'SeedN by the numbers',
    items: {
      1: {
        title: '8 years',
        desc: `
        <p><span>8 years of accumulated</span> <span>technical know-how</span><br /> <span>in AIoT, data analysis, and more </span><span>since its founding in 2015</span> </p>
          `,
      },
      2: {
        title: 'Over 300',
        desc: `
        <p><span>Over 300 commercial facilities,</span><br /><span>operating Leaf service</span></p> 
          `,
      },
      3: {
        title: 'Over 100 million',
        desc: `
        <p><span>Over 100 million</span><br /><span>environmental data points</span><br /><span>collected in the field</p></span><small>As of January 2023</small>
      `,
      },
      4: {
        title: '17%',
        desc: `
        <p><span>Energy efficiency improved</span><br /> <span>by an average of 17%</span> <br /> <span>compared to previous temperature</span><br /> <span>control methods after using Leaf</span> </p>
    `,
      },
    },
  },
  history: {
    title: {
      1: `SeedN is exploring new ventures<br /> beyond the HVAC market,<br/>`,
      2: `in the energy industry.`,
    },
    items: {
      2015: {
        year: '2015',
        month: 'May',
        desc: 'SeedN Corporation founded',
      },
      2016: {
        year: '2016',
        1: {
          month: 'Jun',
          desc: {
            1: 'Won the grand prize at the Kangwon Provincial<br/> Meteorological Administration’s <br/>"Weather Data + Startup Contest',
            2: 'Won the K-Global IoT Challenge by Korea Internet & Security<br/> Agency (KISA) Director General’s Award',
            3: 'Completed development of the first version of Leaf<br/> and began beta testing',
          },
        },
        2: {
          month: 'Dec',
          desc: 'Selected for K-GLOBAL 300',
        },
      },
      2017: {
        year: '2017',
        month: 'Sep',
        desc: {
          1: 'Won gold medal in the Gadget category <br/>at the Pittsburgh International Invention Exhibition',
          2: 'Completed development of the second version<br/> of Leaf and began beta testing',
        },
      },
      2018: {
        year: '2018',
        month: 'Dec',
        desc: 'Completed development of the third version of Leaf',
      },
      2019: {
        year: '2019',
        1: {
          month: 'Apr',
          desc: 'Officially launched Leaf online',
        },
        2: {
          month: 'May',
          desc: {
            1: 'Signed an NDA and a contract for the supply of<br/> a remote control system for AC with Samsung S1',
            2: 'Developed Samsung S1 BlueScan HVAC remote control system',
          },
        },
        3: {
          month: 'Jun',
          desc: {
            1: 'Won the Excellent Innovation Award<br/> at the 2019 COEX World IT Show and was selected<br/> as an excellent innovation case',
            2: 'Won an excellence award in the Korea Energy Agency’s<br/> call for the utilization of energy-efficient technologies',
          },
        },
      },
      2020: {
        year: '2020',
        1: {
          month: 'Jun',
          desc: 'Signed a nationwide supply contract with<br/> "Two Some Place" franchise stores',
        },
        2: {
          month: 'Aug',
          desc: 'Developed the fourth version of "Leaf"',
        },
      },
      2021: {
        year: '2021',
        1: {
          month: 'Aug',
          desc: {
            1: 'Attracted 500 million won investment<br/> from Kakao Ventures in Pre-A round',
            2: 'Relocated headquarters (Heyground Seoul Forest Branch)',
          },
        },
        2: {
          month: 'Nov',
          desc: 'Selected as COMEUP2021 Rookie',
        },
        3: {
          month: 'Dec',
          desc: 'Selected as a TIPS by the Ministry of SMEs and Startups',
        },
      },
      2022: {
        year: '2022',
        1: {
          month: 'Jan',
          desc: 'Established a company-affiliated research center',
        },
        2: {
          month: 'Jun',
          desc: {
            1: `Provided "eco friendly" integrated security services <br/>with SK Schieldes`,
            2: `Developed IoT-based temperature<br/> and humidity sensing products for refrigerators`,
          },
        },
        3: {
          month: 'Jul',
          desc: 'Selected as IBK Changgong Mapo 9th Innovative Startup Company',
        },
        4: {
          month: 'Oct',
          desc: {
            1: 'Attracted 2 billion won investment<br/> from Hana Financial Group',
            2: 'Selected as Hana Financial Group ESG Double Impact Matching Fund',
            3: 'Won the Korea Electronics Show (KES) Innovation Award',
          },
        },
        5: {
          month: 'Nov',
          desc: {
            1: 'Signed MOU with SK Telecom, SK Shieldus, and Bitfinder for eco-friendly convergence security',
            2: 'Launched SeedN-ADT smart air heathing and cooling',
            3: 'Won the Korea Social Investment Global Startup Investment Competition',
            4: 'Signed MOU with Pinpoint Building AIoT Solution',
            5: 'Selected as COMEUP2022 Rookie',
          },
        },
        6: {
          month: 'Dec',
          desc: 'Infinitum-SKT Fund, Reventures, and Korea Social Investment Pre-A investment from Korea Investment Corporation',
        },
      },
      2023: {
        year: '2023',
        1: {
          month: 'Jan',
          desc: 'Attracted Pre-A investment from Hana Investment & Securities',
        },
        2: {
          month: 'Apr',
          desc: 'Selected as ESG Korea 2023 by SK Telecom',
        },
      },
    },
  },
  story: {
    title: `We are leading the way on a path <br /> that no one has walked before.`,
    desc: `As multiple types of spaces come together to form a building,
     <br /> understanding and expertise in space and building are essential. <br /> We have built on a sincerity for buildings, 
     <br /> and have accumulated expertise in building energy management. 
    <br /> We strive for constant technological innovation, 
    <br /> lowering the barriers to understanding <br/> 
    the HVAC market and energy industry, <br /> and working to improve user convenience.`,
  },
};

export default about;
