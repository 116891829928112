import styled from 'styled-components';
import { SEEDN_NEW } from 'styles/palette';
import IconLeaf from 'assets/icons/main/customer-leaf.png';
import Image1 from 'assets/imgs/main/customer1.jpg';
import Image2 from 'assets/imgs/main/customer2.jpg';
import Image3 from 'assets/imgs/main/customer3.jpg';
import Image4 from 'assets/imgs/main/customer4.jpg';
import Image5 from 'assets/imgs/main/customer5.jpg';
import Image6 from 'assets/imgs/main/customer6.jpg';
import BarGraph from './BarGraph';
import { useTranslation, Trans } from 'react-i18next';
import useWindowSize from 'hooks/common/useWindowSize';

const Customer = () => {
  const { t } = useTranslation('main');
  const isMobile = useWindowSize().windowSize! < 768;
  return (
    <Container>
      <Title>
        <h2>
          <span>
            <Trans t={t} i18nKey={'customer.title.1'} />
          </span>
          <span>
            <Trans t={t} i18nKey={'customer.title.2'} />
          </span>
        </h2>
        {!isMobile && <StyledIconLeaf src={IconLeaf} />}
      </Title>
      <div>
        <Article>
          <Images>
            <img src={Image1} alt="customer" />
            <img src={Image2} alt="customer" />
            <img src={Image3} alt="customer" />
          </Images>
          <Comment>
            <span>
              <Trans t={t} i18nKey={'customer.comment1.message'} />
            </span>
            <small>{t('customer.comment1.from')}</small>
          </Comment>
        </Article>
        <Article>
          <Images>
            <img src={Image4} alt="customer" />
            <img src={Image5} alt="customer" />
            <img src={Image6} alt="customer" />
          </Images>
          <Comment>
            <span>
              <Trans t={t} i18nKey={'customer.comment2.message'} />
            </span>
            <small>{t('customer.comment2.from')}</small>
          </Comment>
        </Article>
      </div>
      <BarGraph />
    </Container>
  );
};

export default Customer;

const Container = styled.section`
  padding-top: 226px;

  @media (max-width: 767px) {
    padding-top: 160px;
  }
`;

const Title = styled.div`
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 130px;

  h2 {
    font-weight: 500;
    font-size: 30px;
    color: #fff;
    padding: 20px 42px 20px 61px;
    background-color: ${SEEDN_NEW};
    width: fit-content;
    border-radius: 100px 0px 0px 100px;
    text-align: right;
  }

  @media (max-width: 1200px) {
    span {
      display: block;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;

    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      padding: 10px 20px 10px 50px;

      span {
        display: block;
      }
    }
  }
`;

const StyledIconLeaf = styled.img`
  position: absolute;
  top: -19px;
  left: -114px;
  width: 80px;

  @media (max-width: 767px) {
    width: 52px;
    top: 0;
    left: -82px;
  }
`;

const Article = styled.article`
  &:not(:last-child) {
    margin-bottom: 133px;
  }

  &:last-child {
    margin-bottom: 400px;
  }

  @media (max-width: 767px) {
    &:not(:last-child) {
      margin-bottom: 70px;
    }

    &:last-child {
      margin-bottom: 180px;
    }
  }
`;

const Images = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: 322px;
  margin: 0 auto 60px;
  gap: 23px;

  @media (max-width: 767px) {
    height: 113px;
    gap: 8px;
    margin-bottom: 20px;
  }
`;

const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  * {
    text-align: center;
  }

  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    opacity: 0.7;
  }

  small {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    opacity: 0.5;
  }

  @media (max-width: 767px) {
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }

    small {
      font-size: 10px;
      line-height: 11px;
    }
  }
`;
