const main = {
  start: {
    title: `Solving the Climate Crisis<br/>
    with Appropriate Temperature<br/> for the World.`,
    balloons: {
      1: `"It's summer, but the café is so cold."`,
      2: `"Here is too dry because of the heater." `,
      3: `"The electricity bill is too high because of AC."`,
      4: `"It's always too cold or too stuffy."`,
    },
    outro: {
      1: 'Everyone can relate,',
      2: 'but these have been <br/>difficult problems to solve,',
      3: 'your life will change with SeedN.',
    },
  },
  illust: {
    title: {
      1: "SeedN's data-based<br/> temperature management",
      2: 'Bringing innovation to energy operation management.',
    },
    articles: {
      1: {
        title: `Collecting temperature and<br/> humidity data
          around people.`,
        desc: `We spend our time under the air conditioner. <br />
          Therefore, the temperature around the air conditioner
          <br /> may be different from the actual temperature around us.
          <br />
          SeedN collects temperature and humidity data <br /> around people,
          not around the air conditioner.
    `,
      },
      2: {
        title: `Analyzing and learning <br />the collected data
    
            using<br /> SeedN's AI technology.`,
        desc: `Seed AI considers not only the temperature<br/> and humidity data collected by sensors,
            <br />
            but also factors that affect indoor temperature changes,<br/> such as building data and weather data,
            <br />
            and reflects them in the learning process.`,
      },
      3: {
        title: `Systematic temperature management<br /> based on 
            the learned data`,
        desc: `Using the accumulated data, <br /> Seed AI predicts indoor temperature<br /> and humidity changes and thermal energy changes,
            <br />
            and derives the optimal combination of air conditioners
            <br />
            such as operating mode, set temperature, <br />  etc. to keep the indoor environment comfortable.`,
      },
      4: {
        title: `The most efficient <br />energy operation management
        <br />with a smart control service`,
        desc: `Like Self-driving cars,
            <br />
            Leaf can operate air conditioners autonomously <br />without human intervention.
            <br />
            It can increase energy efficiency by more than 10%.`,
      },
    },
  },
  graph: {
    title: 'Creating Standards for Temperature',
    desc: `<span>Based on the <strong>real-time temperature</span> and humidity data collected,</strong> <br /> we set a <strong>proper temperature range</strong><br/> to prevent<span> overcooling and to maintain comfort.</span>`,
    dotLabel: {
      1: '9:00',
      2: '10:00',
      3: '11:00',
    },
    graphLabel: 'Proper Temperature Range',
    outro: {
      1: 'We avoid overcooling or overheating due to the difference<br/> between the air conditioner temperature and the person’s temperature.',
      2: '',
      3: 'We update the proper temperature range every<br/> 10 minutes by reflecting real-time variables.',
      4: 'We create standards for temperature by predicting changes in advance.',
    },
  },
  customer: {
    title: {
      1: 'Customers have experienced changes ',
      2: 'in their daily lives with SeedN Leaf service.',
    },
    comment1: {
      message: `“It’s so convenient from the administrator's point of view. <br /> This month, the electricity bill decreased by 20% compared to last month.”`,
      from: '-Global clothing brand H',
    },
    comment2: {
      message: `“The number of customer complaints has decreased. <br /> Plus, since they notify us to check the AC performance<br/> before it gets worse, <br /> we can respond in advance.”`,
      from: '-Domestic large franchise cafe company T',
    },
  },
  barGraph: {
    title: {
      smallText: `With SeedN, changes in daily life <br /> also bring changes in protecting the environment.`,
      largeText: {
        1: 'We reduced CO2 by 00kg.',
        2: 'Same amount with 00 pine trees',
      },
      desc: 'Based on July to September 2020 data',
    },
    bar: {
      1: 'July',
      2: 'August',
      3: 'September',
    },
    box: {
      p: {
        1: 'After using SeedN Leaf,',
        2: `the original energy <br /> consumption decreased from <br /> 79,595 Kwh to <strong>38,413 Kwh</strong>.`,
      },
      small: 'Comparison for 3 months',
    },
    caption: {
      // 두줄, 가운데정렬
      1: 'Original air conditioning<br/> power energy consumption',
      2: 'Energy consumption after<br/> SeedN Leaf was introduced',
    },
  },
  askSection: {
    title: `Join SeedN's Energy Innovation.`,
    button: 'Contact Us',
  },
  enterprise: {
    1: 'Many companies are',
    2: 'experiencing changes with SeedN.',
  },
  ask: {
    content: 'For the World, <br />Create the Right temperature with SeedN.',
    btn: 'Contact Us Online',
    modal: {
      title: 'Contact Us',
      desc: 'We will contact you as soon as possible<br/> after confirmation.',
      // 모바일에서만 br
      company: 'Company Name',
      name: 'Name',
      email: 'Email',
      contact: 'Contact',
      content: 'Content',
      subIndex: '(Up to 200)',
      cancel: 'cancel',
      send: 'send',
    },
  },
  askSuccess: {
    title: `Your inquiry <br />has been submitted.`,
    desc: `We will get in touch with you as soon as possible <br />using the information you provided.`,
    button: 'Go to Home',
  },
  askFail: {
    title: `Failed to submit <br />your inquiry.`,
    errorCode: 'Error code',
    desc: {
      1: 'We apologize for the inconvenience.',
      2: 'If the same problem persists, ',
      // 띄어쓰기
      3: 'please contact us through<br/> our Kakao Channel or customer service phone number.',
    },
    button: {
      desktop: 'Go back to the previous page',
      mobile: 'Go back',
    },
    //플로팅버튼
  },
};

export default main;
