import { createGlobalStyle } from 'styled-components';
import fontComfortaa from 'assets/fonts/Comfortaa-Bold.ttf';
import fontEliceBold from 'assets/fonts/EliceDigitalBaeumOTF_Bold.otf';
import fontEliceRegular from 'assets/fonts/EliceDigitalBaeumOTF_Regular.otf';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    overflow-y: overlay;
  }

  body {
    height: 100vh;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR', sans-serif;
    overflow-x: hidden;
    letter-spacing: -0.04em;
    color: #111111;
    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }

    &::-webkit-scrollbar-thumb {
      outline: none;
      border-radius: 10px;
      border: 4px solid transparent;
      box-shadow: inset 6px 6px 0 rgba(34, 34, 34, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 4px solid transparent;
      box-shadow: inset 6px 6px 0 rgba(34, 34, 34, 0.5);
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      background-color: transparent;
    }
  }

  #root {
    position: relative;
  }

  * {
    box-sizing: border-box;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    font-family: 'Noto Sans KR', sans-serif;
    padding: 0;
    letter-spacing: -0.04em;
    color: #000;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  textarea {
    font-family: 'Noto Sans KR', sans-serif;
    resize: none;
  }
  
  textarea:focus,
  textarea:active {
    outline: none;
  }

  ul,
  li {
    padding-left: 0;
    list-style: none;
    margin: 0;
  }

  input {
    font-family: 'Noto Sans KR', sans-serif;
    outline: none;
    font-family: 'Noto Sans KR';
  }

  @font-face {
    font-family: "Comfortaa";
    src: url(${fontComfortaa});
    font-display: block;
  }

  @font-face {
    font-family: 'EliceDigitalBaeum';
    src: url(${fontEliceBold}), url(${fontEliceRegular})
  }

  span {
    cursor: default;
  }
`;

export default GlobalStyle;
