import React from 'react';
import styled from 'styled-components';
import { Container } from './ControlDesc';
import ControllerImg from 'assets/imgs/service/controllerImg.png';
import SensorImg from 'assets/imgs/service/sensorImg.png';
import Gradient from './Gradient';
import useWindowSize from 'hooks/common/useWindowSize';
import { useTranslation, Trans } from 'react-i18next';

function AdvantagesDesc() {
  const { windowSize } = useWindowSize();
  const { t } = useTranslation('services');
  const isSmallScreen = windowSize < 768;
  return (
    <MediaContainer>
      <ImageBox>
        <Controller src={ControllerImg} />
        <Sensor src={SensorImg} />
      </ImageBox>
      {isSmallScreen && (
        <GradientBox>
          <Gradient />
        </GradientBox>
      )}
      <Descriptions>
        <Desc>
          <GrayText>
            <Trans t={t} i18nKey="advantage.gray1" />
          </GrayText>
          <Underline>
            <Trans t={t} i18nKey="advantage.underline1" />
          </Underline>
          <NormalText>
            <p>
              <Trans t={t} i18nKey="advantage.normal1" />
            </p>
            <p>
              <Trans t={t} i18nKey="advantage.normal2" />
            </p>
            <p>
              <Trans t={t} i18nKey="advantage.normal3" />
            </p>
            <p>
              <Trans t={t} i18nKey="advantage.normal4" />
            </p>
          </NormalText>
        </Desc>
        <Desc>
          <GrayText>
            <Trans t={t} i18nKey="advantage.gray2" />
          </GrayText>
          <Underline>
            <Trans t={t} i18nKey="advantage.underline2" />
          </Underline>
          <NormalText>
            <p>
              <Trans t={t} i18nKey="advantage.normal5" />
            </p>
            <p>
              <Trans t={t} i18nKey="advantage.normal6" />
            </p>
            <p>
              <Trans t={t} i18nKey="advantage.normal7" />
            </p>
          </NormalText>
        </Desc>
        <Desc>
          <GrayText>
            <Trans t={t} i18nKey="advantage.gray3" />
          </GrayText>
          <Underline>
            <Trans t={t} i18nKey="advantage.underline3" />
          </Underline>
          <NormalText>
            <p>
              <Trans t={t} i18nKey="advantage.normal8" />
            </p>
            <p>
              <Trans t={t} i18nKey="advantage.normal9" />
            </p>
            <p>
              <Trans t={t} i18nKey="advantage.normal10" />
            </p>
            <p>
              <Trans t={t} i18nKey="advantage.normal11" />
            </p>
          </NormalText>
        </Desc>
      </Descriptions>
    </MediaContainer>
  );
}

const MediaContainer = styled(Container)`
  margin-bottom: 350px;
  @media (max-width: 767px) {
    margin: 0 auto 160px;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 303px;
    position: relative;
  }
`;

const ImageBox = styled.div`
  height: 492px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 99.6px;
`;

const GradientBox = styled.div`
  height: 492px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 99.6px;
  position: relative;
  @media (max-width: 767px) {
    width: 360px;
    height: 303px;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 10;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Controller = styled.img`
  width: 438px;
  height: 492px;
  margin-bottom: 99.63px;
  @media (max-width: 767px) {
    width: 180px;
    height: 303px;
    flex-basis: 50%;
    z-index: -1;
    position: absolute;
    left: 0;
  }
`;

const Sensor = styled.img`
  width: 438px;
  height: 492px;
  margin-bottom: 99.63px;
  @media (max-width: 767px) {
    width: 180px;
    height: 303px;
    flex-basis: 50%;
    z-index: -1;
    position: absolute;
    right: 0;
  }
`;

const Descriptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 350px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const GrayText = styled.div`
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
  color: #111111;
  opacity: 0.5;
`;

const Underline = styled.div`
  border-bottom: 4px solid black;
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 40px;
  line-height: 70px;
  opacity: 0.8;
  margin-bottom: 53px;
`;

const NormalText = styled.div`
  font-weight: 350;
  font-size: 20px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default AdvantagesDesc;
