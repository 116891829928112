import React from 'react';
import styled from 'styled-components';

const ResponsiveBlock = styled.div`
  min-width: 360px;

  @media (min-width: 768px) {
    width: 1024px;
    margin: 0 auto;
  }
`;

const Responsive = ({ children, ...rest }: { children: React.ReactNode }) => {
  return <ResponsiveBlock {...rest}>{children}</ResponsiveBlock>;
};

export default Responsive;
