import Intro from 'components/about/Intro';
import Slogan from 'components/about/Slogan';
import Number from 'components/about/Number';
import History from 'components/about/History';
import Story from 'components/about/Story';
import Footer from 'components/common/Footer';
import Header from 'components/common/Header';
import useHeader from 'hooks/common/useHeader';
import styled from 'styled-components';
import News from 'components/about/News';
import Floating from 'components/common/FloatingButton';

const BREAKPOINT = {
  desktop: 800,
  mobile: 670,
};

const AboutPage = () => {
  const headerType = useHeader(BREAKPOINT);
  return (
    <>
      <AboutHeader type={headerType} />
      <Slogan />
      <Intro />
      <Number />
      <History />
      <Story />
      {/* <News /> */}
      <Floating />
      <Footer />
    </>
  );
};

export default AboutPage;

const AboutHeader = styled(Header)`
  position: fixed;
`;
