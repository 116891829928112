import { DARK_THEME, THEME } from 'styles/palette';
import styled from 'styled-components';
import Modal from './Modal';

const ConfirmModalBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    font-size: 20px;
    font-weight: 700;
    padding: 40px;
  }

  button {
    color: #fff;
    background-color: ${THEME};
    border-radius: 8px;
    border: none;
    padding: 10px 30px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    :hover {
      background-color: ${DARK_THEME};
    }
  }
`;

interface Props {
  onClose: () => void;
  onClick: () => void;
  msg: string;
}

const ConfirmModal = ({ onClose, onClick, msg }: Props) => {
  return (
    <Modal onClose={onClose}>
      <ConfirmModalBlock>
        <div>{msg}</div>
        <button type="button" onClick={onClick}>
          확인
        </button>
      </ConfirmModalBlock>
    </Modal>
  );
};

export default ConfirmModal;
