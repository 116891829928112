import styled from 'styled-components';
import intersect from 'assets/imgs/service/Intersect.png';
import { progress, rotateHandle } from 'styles/animations';
import { useEffect, useRef, useState } from 'react';

function TemperatureController() {
  const [temp, setTemp] = useState(18);
  const [transition, setTransition] = useState(false);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const onMouseEnter = () => {
    setTransition(true);
  };
  const onMouseLeave = () => {
    setTransition(false);
    setTemp(18);
  };
  useEffect(() => {
    if (!transition) return;
    const increment = setInterval(() => {
      if (temp === 30) {
        clearInterval(increment);
        setTemp(30);
        return;
      }
      setTemp(prev => prev + 1);
    }, 100);
    intervalRef.current = increment;
    return () => clearInterval(increment);
  }, [temp, transition]);
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Indent src={intersect} />
      <span>{temp}°C</span>
      <Progress>
        <svg>
          <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor="#8FED52"></stop>
            <stop offset="100%" stopColor="#D3FFB7"></stop>
          </linearGradient>
          <circle
            cx={132}
            cy={128}
            r={70}
            strokeWidth="4"
            fill="none"
            stroke="url(#linearColors)"
          ></circle>
        </svg>
      </Progress>
      <HandleContainer className="handle">
        <Handle />
      </HandleContainer>
    </Container>
  );
}

export const Container = styled.div`
  width: 261px;
  height: 261px;
  background: #f8f9ff;
  position: relative;
  box-shadow: 4px 4px 10px rgba(106, 124, 185, 0.25),
    inset 3px 3px 1px rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1.5s;
  &:hover circle {
    animation: ${progress} 1.5s forwards;
  }
  & > span {
    font-size: 30px;
    line-height: 43px;
    letter-spacing: -0.04em;
    position: absolute;
    top: 106px;
  }
  &:hover .handle {
    animation: ${rotateHandle} 1.5s forwards;
  }
  &:hover {
    color: #6f36c9;
  }
  @media (max-width: 767px) {
    transform-origin: 0 0;
    transform: scale(0.68);
  }
`;

const Indent = styled.img`
  position: absolute;
  width: 181px;
  height: 181px;
`;

const Progress = styled.div`
  position: absolute;
  width: 261px;
  height: 261px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    circle {
      stroke-dasharray: 440;
      stroke-dashoffset: 410;
      fill: transparent;
      stroke-width: 18px;
      stroke-linecap: round;
      @media (max-width: 767px) {
        stroke: #99ef60;
      }
    }
  }
`;

const HandleContainer = styled.div`
  width: 150px;
  height: 150px;
  position: absolute;
`;

const Handle = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 29px;
  height: 29px;
  position: relative;
  left: 92px;
  top: -5px;
  border-radius: 50%;
`;

export default TemperatureController;
