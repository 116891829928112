import { keyframes } from 'styled-components';

export const smoothAppear = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideout = keyframes`
    0% {
        left: 60px;
        width: 43px;
    }
    100% {
        left: 150px;
        width: 90px;
    }
`;

export const englishSlideOut = keyframes`
    0% {
        left: 60px;
        width: 123px;
    }
    100% {
      left: 224px;
        width: 60px;
    }
`;

export const mobileSlideout = keyframes`
    0% {
        left: 24px;
        width: 32px;
    }
    100% {
        left: 85px;
        width: 63px;
    }
`;

export const engmobileSlideout = keyframes`
    0% {
        left: 24px;
        width: 80px;
    }
    100% {
        left: 120px;
        width: 43px;
    }
`;

export const slidein = keyframes`
    0% {
        left: 150px;
        width: 90px;
    }
    100% {
        left: 60px;
        width: 43px;
    }
`;

export const englishSlideIn = keyframes`
    0% {
        left: 220px;
        width: 100px;
    }
    100% {
        left: 60px;
        width: 123px;
    }
`;

export const mobileSlidein = keyframes`
    0% {
      left: 85px;
        width: 63px;
    }
    100% {
      left: 24px;
        width: 32px;
    }
`;

export const engmobileSlidein = keyframes`
    0% {
      left: 85px;
        width: 43px;
    }
    100% {
      left: 24px;
      width: 80px;
    }
`;

export const stretch = keyframes`
    0% {
      stroke-dashoffset: 270;
    }
    100% {
      stroke-dashoffset: 0;
    }
`;

export const mobileStretch = keyframes`
    0% {
      stroke-dashoffset: 90;
    }
    100% {
      stroke-dashoffset: 0;
    }
`;

export const progressFull = keyframes`
  0% {
    stroke-dashoffset: 430;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

export const progress = keyframes`
  0% {
    stroke-dashoffset: 430;
  }
  100% {
    stroke-dashoffset: 60;
  }
`;

export const rotateCircle = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(352deg);
  }
`;

export const rotateHandle = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(285deg);
  }
`;
