import Footer from 'components/common/Footer';
import Header from 'components/common/Header';
import FaqHeader from 'components/faq/FaqHeader';
import FaqList from 'components/faq/FaqList';

const FaqPage = () => {
  return (
    <>
      <Header />
      <FaqHeader />
      <FaqList />
      <Footer />
    </>
  );
};

export default FaqPage;
