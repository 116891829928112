import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import styled, { css } from 'styled-components';
import 'swiper/css';
import { useState } from 'react';

const SLIDE_LENGTH = 7;

const HistoryCarousel = ({ data }: any) => {
  const [slideCount, setSlideCount] = useState(0);
  const onRealIndexChange = (swiper: SwiperCore) =>
    setSlideCount(swiper.realIndex);
  return (
    <>
      <StyledSwiper
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        onRealIndexChange={onRealIndexChange}
      >
        {data.map((text: any, i: number) => (
          <Slide key={i}>{text}</Slide>
        ))}
      </StyledSwiper>
      <Dots>
        {Array.from({ length: SLIDE_LENGTH }).map((_, i) => (
          <Dot key={i} index={i} count={slideCount} />
        ))}
      </Dots>
    </>
  );
};

const StyledSwiper = styled(Swiper)`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const Slide = styled(SwiperSlide)`
  position: relative;
  width: 383px;
  height: fit-content;
  font-family: 'Inter';
  line-height: 150%;
  padding-left: 24px;
  * {
    white-space: nowrap;
  }
`;

const Dots = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  top: 27px;
  right: 20px;
`;

const Dot = styled.span<{ index: number; count: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(145, 58, 203, 0.2);
  transition: all 800ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #913acb;
  }

  ${({ count, index }) =>
    count > index &&
    css`
      transform: translateX(-100vw);
    `}
`;

export default HistoryCarousel;
