import styled from 'styled-components';
import Responsive from 'components/common/Responsive';
import Background from 'components/common/Background';
import BackgroundImg from 'assets/imgs/about/background.png';
import LowQualityBackgroundImg from 'assets/imgs/about/lowQualityBackground.webp';
import { useEffect, useState } from 'react';

const Slogan = () => {
  const [src, setSrc] = useState(LowQualityBackgroundImg);
  useEffect(() => {
    const img = new Image();
    img.src = BackgroundImg;
    img.onload = () => {
      setSrc(BackgroundImg);
    };
  }, []);
  return (
    <StyledBackground img={src}>
      <Text>Better for Us, Better for Earth</Text>
      <MobileText>
        Better for Us
        <br />
        Better for Earth
      </MobileText>
    </StyledBackground>
  );
};

const StyledBackground = styled(Background)`
  background-color: rgba(1, 1, 13, 0.7);
  background-blend-mode: darken;
`;

const Text = styled(Responsive)`
  display: flex;
  justify-content: center;
  padding: 363px 0 418px;
  font-weight: 800;
  font-size: 60px;
  line-height: 50px;
  text-align: center;
  color: #fff;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileText = styled(Responsive)`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    padding: 254px 0 338px;
    font-family: 'Nanum Gothic', sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #fff;
  }
`;

export default Slogan;
