import { useState } from 'react';
import styled, { css } from 'styled-components';
import realSensor from 'assets/imgs/service/realSensor.png';
import realController from 'assets/imgs/service/realController.png';
import { StyledProps } from './Divided';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

function MobileDivided() {
  const [type, setType] = useState(0);
  const { t } = useTranslation('services');
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';
  const onClick = () => {
    if (type < 2) {
      setType(prev => prev + 1);
    } else {
      setType(0);
    }
  };
  return (
    <Container onClick={onClick}>
      <BlackBackground type={type} />
      <WhiteBackground type={type} />
      <TopTextContainer type={type}>
        <Top type={type} isEnglish={isEnglish}>
          <p>
            <Trans t={t} i18nKey="payment.black1" />
          </p>
          <p>
            <Trans t={t} i18nKey="payment.black2" />
          </p>
          <p>
            <Trans t={t} i18nKey="payment.black3" />
          </p>
        </Top>
        {type === 1 && (
          <GrayText>
            <Trans t={t} i18nKey="payment.starSmall" />
          </GrayText>
        )}
        <Sensor src={realSensor} type={type} />
      </TopTextContainer>
      <BottomTextContainer type={type}>
        <Bottom type={type}>
          <p>
            <Trans t={t} i18nKey="payment.white1" />
          </p>
          <p>
            <Trans t={t} i18nKey="payment.white2" />
          </p>
          <p>
            <Trans t={t} i18nKey="payment.whiteSmall3" />
          </p>
          <p>
            <Trans t={t} i18nKey="payment.whiteSmall4" />
          </p>
          <p>
            <Trans t={t} i18nKey="payment.white4" />
          </p>
          <p>
            <Trans t={t} i18nKey="payment.white5" />
          </p>
        </Bottom>
        <Controller src={realController} type={type} />
      </BottomTextContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 90.7px;
  width: 100%;
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (min-width: 768px) {
    display: none;
  }
`;

const BlackBackground = styled.div<StyledProps>`
  background: black;
  width: 100%;
  height: 235px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.7s;
  ${({ type }) => {
    if (type === 1) {
      return css`
        height: 400px;
        z-index: 19;
      `;
    }
  }}
`;

const WhiteBackground = styled(BlackBackground)`
  top: unset;
  height: unset;
  bottom: 0;
  transition: all 0.7s;
  background-color: white;
  ${({ type }) => {
    if (type === 2) {
      return css`
        height: 400px;
        z-index: 19;
      `;
    }
  }}
`;

const TopTextContainer = styled.div<StyledProps>`
  width: 360px;
  height: 235px;
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  top: 0;
  z-index: 10;
  transition: height 0.7s;
  ${({ type }) => {
    if (type === 1) {
      return css`
        height: 400px;
        z-index: 20;
      `;
    }
  }}
`;

const BottomTextContainer = styled.div<StyledProps>`
  width: 360px;
  height: 235px;
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  transition: height 0.7s;
  z-index: 5;
  bottom: 0;
  height: 235px;
  ${({ type }) => {
    if (type === 2) {
      return css`
        height: 400px;
        z-index: 20;
      `;
    }
  }}
`;

const Top = styled.div<StyledProps>`
  width: 145px;
  height: 89px;
  color: white;
  position: absolute;
  bottom: ${props => (props.isEnglish ? '83px' : '62px')};
  right: 35.5px;
  & > p {
    font-weight: 500;
    font-size: 16.5101px;
    line-height: 29px;
    text-align: right;
  }
  &::after {
    content: '1';
    position: absolute;
    width: 18.3px;
    height: 18.3px;
    background: #6f36c9;
    top: 34px;
    left: -30px;
    border-radius: 50%;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18.3px;
    transition: all 0.7s;
    color: #ffffff;
    ${({ type }) => {
      if (type === 1) {
        return css`
          transform: translateY(-40px);
        `;
      }
      if (type === 2) {
        return css`
          transform: translateY(-90px);
        `;
      }
    }}
  }
`;

const Bottom = styled.div<StyledProps>`
  width: 207px;
  height: 177px;
  position: absolute;
  top: 29px;
  right: 28px;
  transition: top 0.7s;
  & > p {
    font-weight: 500;
    font-size: 16.5101px;
    line-height: 29px;
  }
  &::after {
    transition: transform 0.7s;
    content: '2';
    position: absolute;
    width: 18.3px;
    height: 18.3px;
    background: #6f36c9;
    top: 80px;
    left: 183px;
    border-radius: 50%;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18.3px;
    color: #ffffff;
    ${({ type }) => {
      if (type === 1) {
        return css`
          transform: translateY(120px);
        `;
      }
    }}
  }
  ${({ type }) => {
    if (type === 1) {
      return css`
        top: -10px;
      `;
    }
  }}
`;

const Sensor = styled.img<StyledProps>`
  position: absolute;
  width: 134px;
  height: 170px;
  top: 160px;
  left: 0;
  transition: transform 0.7s;
  ${({ type }) => {
    if (type === 1) {
      return css`
        transform: scale(1.8) translate(30px, -70px);
      `;
    }
    if (type === 2) {
      return css`
        transform: translateY(-165px);
        z-index: 0;
      `;
    }
  }}
`;

const Controller = styled.img<StyledProps>`
  position: absolute;
  width: 126px;
  height: 126px;
  top: -60px;
  left: 2px;
  transition: transform 0.7s;
  ${({ type }) => {
    if (type === 2) {
      return css`
        transform: scale(1.8) translate(50px, 170px);
      `;
    }
    if (type === 1) {
      return css`
        transform: translateY(165px);
      `;
    }
  }}
`;

const GrayText = styled.div`
  height: 30px;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #ffffff;
  opacity: 0.5;
  position: absolute;
  bottom: 20px;
  right: 30px;
`;

export default MobileDivided;
