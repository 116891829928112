import React from 'react';
import styled from 'styled-components';
import Button from './CareerButton';
import { ReactComponent as IconAlert } from 'assets/icons/common/alert.svg';
import { Z_INDEX } from 'styles';

interface IProps {
  type: string;
  onClose: (type: string) => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const ApplyModal = ({ type, onClose, onSubmit }: IProps) => {
  return (
    <>
      <Container>
        <Title>
          {type === 'submit' ? (
            <>
              <span>확인 버튼을 누르면</span>{' '}
              <span>지원서 제출이 완료됩니다.</span>
            </>
          ) : (
            <>
              <StyledIconAlert />
              <span>입력한 내용이 초기화됩니다.</span>
            </>
          )}
        </Title>
        <ButtonGroup>
          <Button
            color={type === 'submit' ? 'grey' : ''}
            borderRadius="10px"
            onClick={() => onClose(type)}
          >
            취소
          </Button>
          <Button
            borderRadius="10px"
            color={type === 'back' ? 'grey' : ''}
            onClick={onSubmit}
          >
            확인
          </Button>
        </ButtonGroup>
      </Container>
      <Layer />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000000;
  border-radius: 20px;
  background-color: #fff;
  padding: 50px 120px;
  z-index: 40;

  @media (max-width: 767px) {
    width: 312px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 10px;
    padding: 30px 8px 8px;
  }
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 64px;

  button {
    width: 200px;
  }

  @media (max-width: 767px) {
    width: 100%;
    gap: 8px;

    button {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 4px;
    }
  }
`;

const StyledIconAlert = styled(IconAlert)`
  display: none;

  @media (max-width: 767px) {
    display: block;
    margin: 0 auto 8px;
  }
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${Z_INDEX.MODAL_LAYER};
`;

export default ApplyModal;
