import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

interface English {
  isEnglish: boolean;
}

function Gradient() {
  const { t } = useTranslation('services');
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';
  return (
    <Container>
      <DescTop>
        <SmallText>
          <Trans t={t} i18nKey="advantage.gray1" />
        </SmallText>
        <Underline>
          <Trans t={t} i18nKey="advantage.underline1" />
        </Underline>
        <NormalText>
          <p>
            <Trans t={t} i18nKey="advantage.normal1" />
          </p>
          <p>
            <Trans t={t} i18nKey="advantage.normal2" />
          </p>
          <p>
            <Trans t={t} i18nKey="advantage.normal3" />
          </p>
          <p>
            <Trans t={t} i18nKey="advantage.normal4" />
          </p>
        </NormalText>
      </DescTop>
      <DescMiddle>
        <SmallText>
          <Trans t={t} i18nKey="advantage.gray2" />
        </SmallText>
        <Underline>
          <Trans t={t} i18nKey="advantage.underline2" />
        </Underline>
        <NormalText>
          <p>
            <Trans t={t} i18nKey="advantage.normal5" />
          </p>
          <p>
            <Trans t={t} i18nKey="advantage.normal6" />
          </p>
          <p>
            <Trans t={t} i18nKey="advantage.normal7" />
          </p>
        </NormalText>
      </DescMiddle>
      <DescBottom isEnglish={isEnglish}>
        <SmallText>
          <Trans t={t} i18nKey="advantage.gray3" />
        </SmallText>
        <Underline>
          <Trans t={t} i18nKey="advantage.underline3" />
        </Underline>
        <NormalText>
          <p>
            <Trans t={t} i18nKey="advantage.normal8" />
          </p>
          <p>
            <Trans t={t} i18nKey="advantage.normal9" />
          </p>
          <p>
            <Trans t={t} i18nKey="advantage.normal10" />
          </p>
          <p>
            <Trans t={t} i18nKey="advantage.normal11" />
          </p>
        </NormalText>
      </DescBottom>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  z-index: 1;
  width: 364px;
  height: 1267px;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 34.38%,
    rgba(0, 0, 0, 0.6) 61.98%,
    rgba(0, 0, 0, 0) 100%
  );
  @media (min-width: 768px) {
    display: none;
  }
`;

const DescTop = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
  color: #ffffff;
  position: absolute;
  top: 364px;
  left: 29px;
`;

const DescMiddle = styled(DescTop)`
  top: 583px;
  left: 134px;
  text-align: center;
`;

const DescBottom = styled(DescTop)<English>`
  top: 782px;
  left: ${props => (props.isEnglish ? '200px' : '231px')};
  align-items: flex-end;
  text-align: right;
`;

const SmallText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  margin-bottom: 6px;
`;

const Underline = styled.div`
  height: 36px;
  border-bottom: 2px solid white;
  width: 120px;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 19px;
`;

const NormalText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
`;

export default Gradient;
