import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useDispatch } from 'react-redux';
import { onChangeWriteForm } from 'slices/careersSlice';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

interface Props {
  markup: string | null;
}

const Block = styled.div`
  height: 600px;
`;

const TopBar = styled.div`
  height: 20px;
  margin-bottom: 30px;
  display: flex;

  button {
    margin: 0 8px;
    background-color: #fff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    cursor: pointer;
  }
`;

const EditorWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
  }
`;

const TextEditor = ({ markup }: Props) => {
  const dispatch = useDispatch();

  const initialMarkup = markup
    ? markup
    : `
  <h3>팀 소개</h3>
  <ul><li>입력</li></ul>
  <h3>주요 업무</h3>
  <ul><li>입력</li></ul>
  <h3>자격 요건</h3>
  <ul><li>입력</li></ul>
  <h3>우대 조건</h3>
  <ul><li>입력</li></ul>
  <h3>채용 전형</h3>
  <ul><li>입력</li></ul>
`;
  const blocksFromHTML = stateFromHTML(initialMarkup);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createWithContent(blocksFromHTML),
  );
  const textEditorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentBlockKey = editorState.getSelection().getStartKey();
    const currentBlockIndex = editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()
      .findIndex(k => k === currentBlockKey);
    const totalBlockIndex = editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq().size;

    if (currentBlockIndex + 1 === totalBlockIndex) {
      textEditorRef.current?.scrollTo({
        top: textEditorRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [editorState]);

  useEffect(() => {
    dispatch(
      onChangeWriteForm({
        name: 'content',
        value: stateToHTML(editorState.getCurrentContent()),
      }),
    );
  }, [dispatch, editorState]);

  const stylingText = (event: any, style: any) => {
    event.preventDefault();
    let nextState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(nextState);
  };

  const stylingBlock = (event: any, blockType: any) => {
    event.preventDefault();
    let nextState = RichUtils.toggleBlockType(editorState, blockType);
    setEditorState(nextState);
  };

  return (
    <Block>
      <TopBar>
        <button onMouseDown={event => stylingBlock(event, 'header-one')}>
          H1
        </button>
        <button onMouseDown={event => stylingBlock(event, 'header-two')}>
          H2
        </button>
        <button onMouseDown={event => stylingBlock(event, 'header-three')}>
          H3
        </button>
        <div
          style={{
            width: 1,
            height: '100%',
            border: '0.5px solid rgba(0,0,0,0.5)',
          }}
        />
        <button onMouseDown={event => stylingText(event, 'BOLD')}>B</button>
        <button onMouseDown={event => stylingText(event, 'ITALIC')}>I</button>
        <button onMouseDown={event => stylingText(event, 'UNDERLINE')}>
          U
        </button>
        <div
          style={{
            width: 1,
            height: '100%',
            border: '0.5px solid rgba(0,0,0,0.5)',
          }}
        />
        <button
          onMouseDown={event => stylingBlock(event, 'unordered-list-item')}
        >
          UL
        </button>
        <button onMouseDown={event => stylingBlock(event, 'ordered-list-item')}>
          OL
        </button>
      </TopBar>
      <EditorWrapper ref={textEditorRef}>
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          placeholder="내용을 적어주세요..."
        />
      </EditorWrapper>
    </Block>
  );
};

export default TextEditor;
