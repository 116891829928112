import { SEEDN_GREEN } from 'styles/palette';
import styled from 'styled-components';

interface IFloatingButton {
  children: React.ReactNode;
  className?: string;
  color?: string;
  tag?: string;
  [x: string]: any;
}

const FloatingButton = ({
  children,
  className,
  color,
  tag,
  ...props
}: IFloatingButton) => {
  return (
    <StyledButton color={color} className={className} tag={tag} {...props}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<IFloatingButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  background-color: ${({ color }) => (color === 'grey' ? '#aaa' : SEEDN_GREEN)};
  fill: #fff;
  transition: all 200ms ease-in-out;

  &:hover,
  &:active,
  &.active {
    background-color: #fff;
    fill: ${({ color }) => (color === 'grey' ? '#aaa' : SEEDN_GREEN)};

    &::before {
      content: ${({ tag }) => tag === '지원하기' && `"지원하기"`};
      position: absolute;
      top: 0;
      left: -95px;
      transform: translateY(50%);
      width: 85px;
      padding: 4px 0;
      border-radius: 4px;
      background-color: ${SEEDN_GREEN};
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &.active {
    &::before {
      content: ${({ tag }) => tag === '링크 복사됨' && `'링크 복사됨'`};
    }
  }

  &.heart {
    background-color: #fff;
    fill: #f37b6a;

    &::before {
      display: none;
    }
  }
`;

export default FloatingButton;
