import styled from 'styled-components';
import CoverImage from 'assets/imgs/about/story.jpg';
import MobileCoverImage from 'assets/imgs/about/story-mobile.jpg';
import { useTranslation, Trans } from 'react-i18next';

const Story = () => {
  const { t } = useTranslation('about');
  return (
    <Container>
      <DesktopImg src={CoverImage} />
      <MobileImg src={MobileCoverImage} />
      <Header dangerouslySetInnerHTML={{ __html: t('story.title') }} />
      <Desc>
        <Trans t={t} i18nKey={'story.desc'} />
      </Desc>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  width: 1024px;
  margin: 0 auto 300px;
  padding-bottom: 200px;
  background-color: #f9f9f9;
  z-index: 20;

  @media (max-width: 767px) {
    width: 100%;
    margin: 160px auto 96px;
    padding-bottom: 150px;
  }
`;

const DesktopImg = styled.img`
  width: 100%;
  margin-bottom: 120px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileImg = styled.img`
  display: none;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    margin-bottom: 40px;
  }
`;

const Header = styled.h1`
  margin-bottom: 80px;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  opacity: 0.8;

  @media (max-width: 767px) {
    /* mobile h2 */
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;

    span {
      display: block;
    }
  }
`;

const Desc = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  opacity: 0.6;

  @media (max-width: 767px) {
    /* mobile body1 */
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
`;

export default Story;
