import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  userId: string;
  password: string;
  isRememberEmailChecked: boolean;
}

const initialState: InitialState = {
  userId: '',
  password: '',
  isRememberEmailChecked: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetId: state => {
      state.userId = '';
    },
    resetPassword: state => {
      state.password = '';
    },
    onChangeId: (state, { payload }) => {
      state.userId = payload;
    },
    onChangePassword: (state, { payload }) => {
      state.password = payload;
    },
    handleRememberEmailCheck: (state, { payload }) => {
      state.isRememberEmailChecked = payload;
    },
  },
});

export const {
  resetId,
  resetPassword,
  onChangeId,
  onChangePassword,
  handleRememberEmailCheck,
} = loginSlice.actions;

export default loginSlice.reducer;
