import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { BigText, Container, NormalText, PurpleText } from './ControlDesc';
import leafLogin from 'assets/imgs/service/leafLogin.png';
import leafGroup from 'assets/imgs/service/leafGroup.png';
import useWindowSize from 'hooks/common/useWindowSize';
import { useTranslation, Trans } from 'react-i18next';

export interface StyledProps {
  offset: number;
}

function UserDesc() {
  const [transition, setTransition] = useState(false);
  const [offset, setOffset] = useState(0);
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const STEP = isSmallScreen ? 130 : 350;
  const { t } = useTranslation('services');
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setTransition(true);
    }
  };

  useEffect(() => {
    if (transition) {
      setTimeout(() => {
        setOffset(STEP);
      }, 1000);
      setTimeout(() => {
        setOffset(STEP * 2);
      }, 2000);
    }
  }, [transition]);
  const threshold = 1;
  const setTarget = useIntersectionObserver({ onIntersect, threshold });
  return (
    <MarginContainer>
      <Circle ref={setTarget} offset={offset}></Circle>
      <PurpleTextRight>
        {' '}
        <Trans t={t} i18nKey="user.multi" />
      </PurpleTextRight>
      <BigTextRight>
        {isSmallScreen ? (
          <>
            <span>
              <Trans t={t} i18nKey="user.bigRightSmall1" />
            </span>
            <span>
              <Trans t={t} i18nKey="user.bigRightSmall2" />
            </span>
            <span>
              <Trans t={t} i18nKey="user.bigRightSmall3" />
            </span>
            <span>
              <Trans t={t} i18nKey="user.bigRightSmall4" />
            </span>
            <span>
              <Trans t={t} i18nKey="user.bigRightSmall5" />
            </span>
          </>
        ) : (
          <>
            <span>
              <Trans t={t} i18nKey="user.bigRight1" />
            </span>
            <span>
              <Trans t={t} i18nKey="user.bigRight2" />
            </span>
            <span>
              <Trans t={t} i18nKey="user.bigRight3" />
            </span>
          </>
        )}
      </BigTextRight>
      <NormalTextRigth>
        <Trans t={t} i18nKey="user.normal" />
      </NormalTextRigth>
      <Phone
        src={
          offset > 350 || (isSmallScreen && offset > 130)
            ? leafGroup
            : leafLogin
        }
        draggable="false"
        offset={offset}
      />
    </MarginContainer>
  );
}

const MarginContainer = styled(Container)`
  position: relative;
  margin-top: 0;
  margin-bottom: 400px;
  @media (max-width: 767px) {
    margin-bottom: 134px;
  }
`;

export const Circle = styled.div<StyledProps>`
  position: relative;
  width: 320px;
  height: 320px;
  background: #4f16a9;
  box-shadow: inset 0px 2px 20px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin-bottom: 29px;
  transition: all 0.5s;
  ${({ offset }) => css`
    transform: translateY(${offset}px);
  `}
  overflow: hidden;

  @media (max-width: 767px) {
    width: 126px;
    height: 126px;
  }
`;

const PurpleTextRight = styled(PurpleText)`
  text-align: right;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const BigTextRight = styled(BigText)`
  text-align: right;
`;

const NormalTextRigth = styled(NormalText)`
  text-align: right;
`;

const Phone = styled.img<StyledProps>`
  width: 168px;
  height: 337px;
  position: absolute;
  left: 127px;
  transition: all 0.5s;
  ${({ offset }) => css`
    clip-path: circle(160px at 50% ${offset === 350 ? 320 : 70}px);
    top: ${offset === 350 ? offset - 160 : offset + 90}px;
  `}
  @media (max-width: 767px) {
    width: 66px;
    height: 132.5px;
    left: 49px;
    ${({ offset }) => css`
      clip-path: circle(63px at 50% ${offset === 130 ? 126 : 30}px);
      top: ${offset === 130 ? offset - 63 : offset + 34}px;
    `}
  }
`;
export default UserDesc;
