import styled from 'styled-components';
import LeafImg from 'assets/imgs/main/ask-leaf.jpg';
import { Z_INDEX } from 'styles';
import { SEEDN_NEW } from 'styles/palette';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

const Ask = () => {
  const { t } = useTranslation('main');
  return (
    <Container>
      <img src={LeafImg} alt="leaf" />
      <Layer />
      <Text>
        <h1>{t('askSection.title')}</h1>
        <Link to="/ask">{t('askSection.button')}</Link>
      </Text>
    </Container>
  );
};

export default Ask;

const Container = styled.section`
  position: relative;
  width: 1024px;
  margin: 0 auto;
  img {
    width: 100%;
    display: block;
  }

  @media (max-width: 767px) {
    width: 100%;
    img {
      height: auto;
    }
  }
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111111;
  opacity: 0.4;
  z-index: ${Z_INDEX.SCROLL_LAYER};
`;

const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 20;
  white-space: nowrap;

  h1 {
    font-weight: 700;
    font-size: 41px;
    line-height: 82px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  a {
    padding: 8px 90px;
    background-color: ${SEEDN_NEW};
    border-radius: 10px;
    color: #ffffff;
    font-weight: 700;
    font-size: 22px;
    line-height: 40px;
  }

  @media (max-width: 767px) {
    h1 {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
    }

    a {
      padding: 7px 46px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
