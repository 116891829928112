import styled from 'styled-components';

interface IProps {
  src: string;
  alt: string;
  link: string;
}

const SNSIcon = ({ src, alt, link }: IProps) => {
  return (
    <IconLink href={link} target="_blank">
      <img src={src} alt={alt} />
    </IconLink>
  );
};

const IconLink = styled.a`
  display: flex;

  img {
    display: block;
    width: 37px;
    height: 37px;

    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }
`;

export default SNSIcon;
