import { AppDispatch, RootState } from 'index';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts } from 'slices/careersSlice';

export const usePostsViewer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { authCheck, posts, isLoading } = useSelector((state: RootState) => ({
    authCheck: state.auth.authCheck,
    posts: state.careers.posts,
    isLoading: state.careers.isLoading,
  }));

  useEffect(() => {
    (async () => {
      await dispatch(getPosts());
    })();
  }, [dispatch]);

  return { authCheck, posts, isLoading };
};
