import React from 'react';
import styled from 'styled-components';
import { GREY, GREY_MO, SEEDN_NEW } from 'styles/palette';

interface IButton {
  size?: 'small' | 'large';
  color?: 'grey' | 'purple' | 'white';
  children: React.ReactNode;
  [x: string]: any;
}

const Button = ({
  size = 'small',
  color = 'purple',
  children,
  ...props
}: IButton) => {
  return (
    <StyledButton size={size} color={color} {...props}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<IButton>`
  width: ${({ size }) => (size === 'large' ? '518px' : '253px')};
  height: 60px;
  border-radius: 10px;
  background-color: ${({ color }) =>
    color === 'grey' ? GREY : color === 'white' ? '#F1EBFA' : SEEDN_NEW};
  color: ${({ color }) => (color === 'white' ? SEEDN_NEW : '#fff')};
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  transition: all 200ms ease-in;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ color }) =>
        color === 'grey' ? GREY_MO : color === 'white' ? SEEDN_NEW : '#6421A7'};
      color: #fff;
    }
  }

  @media (max-width: 767px) {
    width: ${({ size }) => (size === 'large' ? '312px' : '148px')};
    height: 40px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export default Button;
