import React from 'react';
import styled from 'styled-components';

const ModalBlock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalMain = styled.div`
  border-radius: 10px;
  background: white;
  padding: 0 30px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: lightgray;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: gray;
  }
`;

const Modal = ({
  children,
  onClose,
  ...rest
}: {
  children: React.ReactNode;
  onClose?: any;
}) => {
  const onCloseByOuter = (event: any) => {
    const classname = event.target.className;
    if (
      classname.split(' ').filter((classname: any) => classname === 'outer')[0]
    ) {
      onClose();
    }
  };

  return (
    <ModalBlock className="outer" onClick={onCloseByOuter}>
      <ModalMain {...rest}>{children}</ModalMain>
    </ModalBlock>
  );
};

export default Modal;
