import { SCROLL_HEIGHT } from 'constants/careers';
import { useState, useEffect, RefObject } from 'react';

interface IProps {
  scrollRef: RefObject<HTMLDivElement>;
  text1: RefObject<HTMLDivElement>;
  text2: RefObject<HTMLDivElement>;
  text3: RefObject<HTMLDivElement>;
  layer: RefObject<HTMLDivElement>;
  image: RefObject<HTMLImageElement>;
  mobileImage: RefObject<HTMLDivElement>;
}

const useScrollAnimation = ({
  scrollRef,
  text1,
  text2,
  text3,
  layer,
  image,
  mobileImage,
}: IProps) => {
  const [yOffset, setYOffset] = useState(0);

  const sceneInfo = {
    scrollHeight: SCROLL_HEIGHT,
    values: {
      text1_opacity_in: [0, 1, { start: 0.1, end: 0.2 }],
      text1_opacity_out: [1, 0, { start: 0.2, end: 0.3 }],
      text2_opacity_in: [0, 1, { start: 0.4, end: 0.5 }],
      text2_opacity_out: [1, 0, { start: 0.7, end: 0.8 }],
      text3_opacity_in: [0, 1, { start: 0.6, end: 0.7 }],
      text3_opacity_out: [1, 0, { start: 0.7, end: 0.8 }],
      image_opacity_in: [1, 1, { start: 0.2, end: 0.6 }],
      image_opacity_out: [1, 0, { start: 0.6, end: 0.85 }],
      layer_opacity_in: [0.5, 0.8, { start: 0.2, end: 0.6 }],
      layer_opacity_out: [0.8, 0, { start: 0.65, end: 0.8 }],
      text1_translateY_in: [0, -20, { start: 0.1, end: 0.2 }],
      text2_translateY_in: [10, -150, { start: 0.3, end: 0.4 }],
      text3_translateY_in: [20, 0, { start: 0.45, end: 0.6 }],
      text1_translateY_out: [-20, -40, { start: 0.25, end: 0.3 }],
      text2_translateY_out: [-150, -180, { start: 0.6, end: 0.7 }],
      text3_translateY_out: [0, -20, { start: 0.6, end: 0.7 }],
    },
  };

  const setLayout = () => {
    if (scrollRef.current) {
      scrollRef.current.style.height = `${sceneInfo.scrollHeight}px`;
    }
  };

  const calcValues = (values: any[], currentYOffset: number) => {
    let rv;
    const scrollHeight = sceneInfo.scrollHeight;
    const scrollRatio = currentYOffset / scrollHeight;

    if (values.length === 3) {
      const partScrollStart = values[2].start * scrollHeight;
      const partScrollEnd = values[2].end * scrollHeight;
      const partScrollHeight = partScrollEnd - partScrollStart;

      if (
        currentYOffset >= partScrollStart &&
        currentYOffset <= partScrollEnd
      ) {
        rv =
          ((currentYOffset - partScrollStart) / partScrollHeight) *
            (values[1] - values[0]) +
          values[0];
      } else if (currentYOffset < partScrollStart) {
        rv = values[0];
      } else if (currentYOffset > partScrollEnd) {
        rv = values[1];
      }
    } else {
      rv = scrollRatio * (values[1] - values[0]) + values[0];
    }
    return rv;
  };

  const playAnimation = () => {
    if (scrollRef.current) {
      const scrollHeight = sceneInfo.scrollHeight;
      const currentYOffset = yOffset - scrollRef.current.scrollTop;
      const scrollRatio = currentYOffset / scrollHeight;

      const text1_opacity_in = calcValues(
        sceneInfo.values.text1_opacity_in,
        currentYOffset,
      );
      const text1_opacity_out = calcValues(
        sceneInfo.values.text1_opacity_out,
        currentYOffset,
      );
      const text2_opacity_in = calcValues(
        sceneInfo.values.text2_opacity_in,
        currentYOffset,
      );
      const text2_opacity_out = calcValues(
        sceneInfo.values.text2_opacity_out,
        currentYOffset,
      );
      const text3_opacity_in = calcValues(
        sceneInfo.values.text3_opacity_in,
        currentYOffset,
      );
      const text3_opacity_out = calcValues(
        sceneInfo.values.text3_opacity_out,
        currentYOffset,
      );

      if (scrollRatio <= 0.25) {
        if (text1.current) {
          text1.current.style.opacity = String(text1_opacity_in);
          text1.current.style.transform = `translate3d(-50%, ${calcValues(
            sceneInfo.values.text1_translateY_in,
            currentYOffset,
          )}%, 0)`;
        }
      } else {
        if (text1.current) {
          text1.current.style.opacity = String(text1_opacity_out);
          text1.current.style.transform = `translate3d(-50%, ${calcValues(
            sceneInfo.values.text1_translateY_out,
            currentYOffset,
          )}%, 0)`;
        }
      }
      if (scrollRatio <= 0.33) {
        if (text2.current) {
          text2.current.style.opacity = String(text2_opacity_in);
        }
      } else if (scrollRatio > 0.33 && scrollRatio <= 0.55) {
        if (text2.current) {
          text2.current.style.opacity = '1';
          text2.current.style.transform = `translate3d(-50%, ${calcValues(
            sceneInfo.values.text2_translateY_in,
            currentYOffset,
          )}%, 0)`;
        }
      }
      if (scrollRatio <= 0.6) {
        if (text3.current) {
          text3.current.style.opacity = String(text3_opacity_in);
          text3.current.style.transform = `translate3d(-50%, ${calcValues(
            sceneInfo.values.text3_translateY_in,
            currentYOffset,
          )}%, 0)`;
        }
      } else {
        if (text2.current) {
          text2.current.style.opacity = String(text2_opacity_out);
          text2.current.style.transform = `translate3d(-50%, ${calcValues(
            sceneInfo.values.text2_translateY_out,
            currentYOffset,
          )}%, 0)`;
        }
        if (text3.current) {
          text3.current.style.opacity = String(text3_opacity_out);
          text3.current.style.transform = `translate3d(-50%, ${calcValues(
            sceneInfo.values.text3_translateY_out,
            currentYOffset,
          )}%, 0)`;
        }
      }
      if (scrollRatio <= 0.7) {
        if (image.current && mobileImage.current && layer.current) {
          image.current.style.opacity = calcValues(
            sceneInfo.values.image_opacity_in,
            currentYOffset,
          );
          mobileImage.current.style.opacity = calcValues(
            sceneInfo.values.image_opacity_in,
            currentYOffset,
          );
          layer.current.style.opacity = calcValues(
            sceneInfo.values.layer_opacity_in,
            currentYOffset,
          );
        }
      } else {
        if (image.current && mobileImage.current && layer.current) {
          image.current.style.opacity = calcValues(
            sceneInfo.values.image_opacity_out,
            currentYOffset,
          );
          mobileImage.current.style.opacity = calcValues(
            sceneInfo.values.image_opacity_out,
            currentYOffset,
          );
          layer.current.style.opacity = calcValues(
            sceneInfo.values.layer_opacity_out,
            currentYOffset,
          );
        }
      }
    }
  };

  useEffect(() => {
    setLayout();
    window.addEventListener('resize', setLayout);
    document.body.addEventListener('scroll', () => {
      setYOffset(document.body.scrollTop);
      playAnimation();
    });

    return () => {
      window.removeEventListener('resize', setLayout);
      document.body.removeEventListener('scroll', () => {
        setYOffset(document.body.scrollTop);
        playAnimation();
      });
    };
  });

  return sceneInfo.scrollHeight;
};

export default useScrollAnimation;
