import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import GlobalStyle from 'styles/GlobalStyle';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import careersReducer from 'slices/careersSlice';
import authReducer from 'slices/authSlice';
import languageReducer from 'slices/languageSlice';
import './i18n';
import ScrollToTop from 'components/common/ScrollToTop';
import loginReducer from 'slices/loginSlice';
import dateRangeReducer from 'slices/dateRangeSlice';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    auth: authReducer,
    careers: careersReducer,
    language: languageReducer,
    login: loginReducer,
    dateRange: dateRangeReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ScrollToTop />
      <GlobalStyle />
      <App />
    </Provider>
  </BrowserRouter>,
);
