import styled from 'styled-components';

interface IProp {
  color: string;
  children: React.ReactNode;
}

const ProcessCircle = ({ color, children }: IProp) => {
  return <Circle color={color}>{children}</Circle>;
};

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  margin-right: -30px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 767px) {
    width: 72px;
    height: 72px;
    margin-right: -14px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    text-align: center;
  }
`;

export default ProcessCircle;
