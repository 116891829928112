import LogoSkShieldus from 'assets/imgs/main/logos-skshieldus.webp';
import LogoSkTelecom from 'assets/imgs/main/logos-skt.webp';
import LogoS1 from 'assets/imgs/main/logos-s1.webp';
import LogoKakaoventures from 'assets/imgs/main/logos-kakaoventures.webp';
import LogoTwosome from 'assets/imgs/main/logos-twosome.webp';
import LogoCoffeebean from 'assets/imgs/main/logos-coffeebean.webp';
import LogoHm from 'assets/imgs/main/logos-hm.webp';
import LogoSamkoo from 'assets/imgs/main/logos-samkoo.webp';
import LogoPinpoint from 'assets/imgs/main/logos-pinpoint.png';
import LogoHanaw from 'assets/imgs/main/logos-hanaw.png';
import LogoHanaFN from 'assets/imgs/main/logos-hanafn.png';
import LogoSocialInvestment from 'assets/imgs/main/logos-social-investment.png';
import LogoInfinitumPartners from 'assets/imgs/main/logos-infinitum-partners.png';
import LogoReventures from 'assets/imgs/main/logos-reventures.png';
import LogoParan from 'assets/imgs/main/logos-paran.png';
import LogoAwair from 'assets/imgs/main/logos-awair.png';
import imgProductDesc1 from 'assets/imgs/main/product-desc1.png';
import imgProductDesc2 from 'assets/imgs/main/product-desc2.png';
import imgMobileProductDesc1 from 'assets/imgs/main/product-desc1-mobile.webp';
import imgMobileProductDesc2 from 'assets/imgs/main/product-desc2-mobile.webp';
import solution1 from 'assets/imgs/main/solution1.png';
import solution2 from 'assets/imgs/main/solution2.png';
import solution3 from 'assets/imgs/main/solution3.png';
import iconLinkedin from 'assets/icons/common/linkedin.png';
import iconNaverBlog from 'assets/icons/common/naver-blog.png';
import iconInstagram from 'assets/icons/common/instagram.png';
import iconNotion from 'assets/icons/common/notion.svg';
import ImageBalloon1 from 'assets/imgs/main/balloon1.png';
import ImageBalloon2 from 'assets/imgs/main/balloon2.png';
import ImageBalloon3 from 'assets/imgs/main/balloon3.png';
import ImageBalloon4 from 'assets/imgs/main/balloon4.png';
import ImageBalloon5 from 'assets/imgs/main/balloon5.png';
import ImageBalloon6 from 'assets/imgs/main/balloon6.png';
import ImageBalloon7 from 'assets/imgs/main/balloon7.png';
import ImageBalloon8 from 'assets/imgs/main/balloon8.png';
import ImageBalloon9 from 'assets/imgs/main/balloon9.png';
import Illust1 from 'assets/imgs/main/illust1.png';
import Illust2 from 'assets/imgs/main/illust2.png';
import Illust3 from 'assets/imgs/main/illust3.png';
import Illust4 from 'assets/imgs/main/illust4.png';

export const ILLUST_ARTICLES = [
  {
    gap: '63px',
    illustImg: Illust1,
    desktopWidth: '245px',
    mobileWidth: '217px',
  },
  {
    gap: '53px',
    illustImg: Illust2,
    desktopWidth: '252px',
    mobileWidth: '200px',
  },
  {
    gap: '50px',
    illustImg: Illust3,
    desktopWidth: '269px',
    mobileWidth: '216px',
  },
  {
    gap: '90px',
    illustImg: Illust4,
    desktopWidth: '233px',
    mobileWidth: '200px',
  },
];

export const ENTERPRISE_LOGOS = [
  {
    src: LogoKakaoventures,
  },
  {
    src: LogoSkTelecom,
  },
  {
    src: LogoSkShieldus,
  },
  {
    src: LogoHm,
  },
  {
    src: LogoS1,
  },
  {
    src: LogoTwosome,
  },
  {
    src: LogoPinpoint,
    width: '98px',
    mobileWidth: '34px',
    mobileTop: '30%',
  },
  {
    src: LogoHanaw,
    width: '92px',
    mobileWidth: '32px',
    mobileTop: '35%',
  },
  {
    src: LogoHanaFN,
    width: '158px',
    mobileWidth: '55px',
  },
  {
    src: LogoSocialInvestment,
    width: '136px',
    mobileWidth: '48px',
  },
  {
    src: LogoInfinitumPartners,
    width: '132px',
    mobileWidth: '46px',
  },
  {
    src: LogoReventures,
    width: '158px',
    mobileWidth: '55px',
  },
  {
    src: LogoParan,
    width: '132px',
    mobileWidth: '46px',
  },
  {
    src: LogoAwair,
    width: '116px',
    mobileWidth: '41px',
  },
  {
    src: LogoSamkoo,
  },
];

export const GRAPH_DOT = [
  {
    left: '0%',
    label: '9시',
  },
  {
    left: '51%',
    label: '10시',
  },
  {
    left: '100%',
    label: '11시',
  },
];

export const BALLOON_IMAGES = [
  {
    src: ImageBalloon1,
    text: 1,
  },
  {
    src: ImageBalloon2,
  },
  {
    src: ImageBalloon3,
  },
  {
    src: ImageBalloon4,
    text: 2,
  },
  {
    src: ImageBalloon5,
    text: 3,
  },
  {
    src: ImageBalloon6,
  },
  {
    src: ImageBalloon7,
  },
  {
    src: ImageBalloon8,
    text: 4,
  },
  {
    src: ImageBalloon9,
  },
];

export const PRODUCT_DESC_CONTENTS = [
  {
    id: `1`,
    index: 'Thermal Care AI',
    content:
      '실시간 실내 온도 변화를 학습하여\n쾌적하고 건강한 온도를 제공합니다.',
    img: imgProductDesc1,
    mobileImg: imgMobileProductDesc1,
  },
  {
    id: `2`,
    index: 'Autonomous AI Technology',
    content: '실시간 사람의 제어없이 알아서 제어하는\n편리함을 제공합니다.',
    img: imgProductDesc2,
    mobileImg: imgMobileProductDesc2,
  },
];

export const SOLUTION_LIST = [
  {
    name: '리프 for Store',
    content:
      '무인 매장부터 크고 작은 다양한\n규모의 온도를 관리하는 서비스입니다.',
    tags: ['#무인매장', '#식음료 프랜차이즈', '#리테일'],
    img: solution1,
  },
  {
    name: '리프 for Office',
    content: '사무실, 회의실 등 사무 공간의 온도를\n관리하는 서비스입니다.',
    tags: ['#사무실', '#회의실', '#회사사옥'],
    img: solution2,
  },
  {
    name: '리프 for Enterprise',
    content: '기업형 고객을 위한 솔루션입니다.',
    tags: ['#창고실', '#서버실', '#기지국', '#냉동고'],
    img: solution3,
  },
];

export const SNS_LINK = {
  instagram: 'https://www.instagram.com/seedn.leaf/',
  notion: 'https://seednvillage.notion.site/ca0a2e491fd5452fbae618468ac9f527',
  blog: 'https://blog.naver.com/seedn_corp',
  linkedIn:
    'https://www.linkedin.com/search/results/all/?keywords=%EC%94%A8%EB%93%9C%EC%95%A4&sid=.CV',
  kakaoTalk: 'https://pf.kakao.com/_pkMaj/chat',
};

export const SNS_ICONS = [
  {
    src: iconInstagram,
    alt: 'instagram',
    link: SNS_LINK.instagram,
  },
  {
    src: iconNotion,
    alt: 'notion',
    link: SNS_LINK.notion,
  },
  {
    src: iconNaverBlog,
    alt: 'blog',
    link: SNS_LINK.blog,
  },
  {
    src: iconLinkedin,
    alt: 'linkedin',
    link: SNS_LINK.linkedIn,
  },
  // {
  //   src: iconKakaoTalk,
  //   alt: 'kakaotalk',
  //   link: SNS_LINK.kakaoTalk,
  // },
];
