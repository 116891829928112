import styled from 'styled-components';
import hubImage from 'assets/imgs/service/hub.svg';
import smallArrow from 'assets/imgs/service/smallArrow.svg';
import bigArrow from 'assets/imgs/service/bigArrow.svg';
import RealHub from 'assets/imgs/service/realHub.png';
import twoSideArrow from 'assets/imgs/service/twoSideArrow.svg';
import { TopDownArrow } from './Flow';
import useWindowSize from 'hooks/common/useWindowSize';
import { Mode } from './Specification';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

export interface Props {
  setMode: (mode: Mode) => void;
}

function HubCircle({ setMode }: Props) {
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const { t } = useTranslation('services');
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';
  const onClick = () => {
    setMode('hub');
  };
  return (
    <>
      <HubCircleStyle onClick={onClick}>
        <img src={hubImage} />
        <Arrow src={smallArrow} />
        <BigArrow src={bigArrow} />
        <TopDownArrow
          src={twoSideArrow}
          style={{ width: '28px', height: '14px' }}
        />
        <Text>
          <Trans t={t} i18nKey="flow.hub" />
        </Text>
        {!isSmallScreen && (
          <Hover style={{ paddingTop: isEnglish ? 0 : '24px' }}>
            <RealHubImage
              src={RealHub}
              style={{ marginTop: isEnglish ? '30px' : 0 }}
            />
            <TextBox>
              <div>
                <Trans t={t} i18nKey="flow.hubText1" />
              </div>
              <div>
                <Trans t={t} i18nKey="flow.hubText2" />
              </div>
            </TextBox>
          </Hover>
        )}
      </HubCircleStyle>
      <HubLeaf />
      {isSmallScreen && <Shadow />}
    </>
  );
}

export const Circle = styled.div`
  position: absolute;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  left: 82px;
  background: #f9faff;
  box-shadow: 6px 6px 20px rgba(12, 84, 192, 0.15), inset 2px 2px 5px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  @media (max-width: 767px) {
    left: 140px;
    width: 80px;
    height: 80px;
    box-shadow: 2px 2px 15px rgba(12, 84, 192, 0.2),
      inset 0.897674px 0.897674px 2.24419px #ffffff;
  }
`;

export const HubCircleStyle = styled(Circle)`
  left: 318px;
  z-index: 20;
  &:hover > div {
    opacity: 1;
  }
  @media (max-width: 767px) {
    left: 140px;
    top: 200px;
    & > img {
      width: 30px;
      height: 60px;
    }
  }
  @media (hover: hover) {
    &:hover > div {
      opacity: 1;
    }
  }
`;

export const RealHubImage = styled.img`
  width: 125px;
  height: 150px;
  position: relative;
  top: -10px;
  left: -10px;
`;

export const Hover = styled.div`
  height: 152px;
  width: 424px;
  border-radius: 500px 10px 10px 500px;
  background: #f9faff;
  box-shadow: 6px 6px 20px rgba(12, 84, 192, 0.2), inset 2px 2px 5px #ffffff;
  position: absolute;
  z-index: 100;
  pointer-events: none;
  transform: translateX(136px);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  display: flex;
  align-items: center;
  padding: 24px 15px 24px 24px;
`;

export const HubLeaf = styled.div`
  position: absolute;
  width: 39.6px;
  height: 22.2px;
  background: #afff7a;
  border-radius: 11px;
  transform: rotate(-45deg);
  top: 10px;
  left: 425px;
  @media (max-width: 767px) {
    top: 200px;
    left: 180px;
    z-index: -1;
  }
`;

export const Arrow = styled.img`
  position: absolute;
  left: 168px;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const BigArrow = styled.img`
  position: absolute;
  left: 68px;
  top: 225px;
  width: 139px;
  height: 151px;
  pointer-events: none;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Text = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  pointer-events: none;
  top: 168px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    color: #111111;
    bottom: -30px;
    top: unset;
  }
`;

export const TextBox = styled.div`
  width: 249px;
  height: 104px;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.8;
  & > div {
    line-height: 20px;
  }
  & > div:first-child {
    margin-bottom: 10px;
  }
`;

export const Shadow = styled.div`
  position: absolute;
  left: 132px;
  top: 192px;
  width: 96px;
  height: 96px;
  background: #f1f1f1;
  opacity: 0.5;
  border-radius: 50%;
  z-index: -10;
`;

export default HubCircle;
