import client from './client';

interface Props {
  email: string;
  helpMessage: string;
  phone: string;
  company: string;
  manager: string;
}

export const help = ({ email, helpMessage, phone, company, manager }: Props) =>
  client.post('/help', {
    email,
    helpMessage,
    phone,
    company,
    manager,
  });
