const about = {
  intro: {
    circles: {
      1: {
        title: '혁신',
        h3: `<span>씨드앤은 </span>
        <span>에너지 친화적인 </span>사회를 만들어 갑니다.`,
        p: `'씨드앤(SeedN)'은 건물마다 리프라는 에너지 씨앗(Seed)이 자라
        <br />
        나무가 되어 네트워크(Network)처럼 연결됨을 의미합니다.
        <br />
        작은 매장부터 건물, 더 나아가 도시로 단위를 확장해 가면서
        <br />
        세계 어디서든 네트워크를 형성하겠다는 비전을 내포하고 있습니다.
        <br />
        씨드앤은 에너지제도 혁신을 비롯한
        <br />
        HVAC 시장에 혁신을 가져오기 위해 노력합니다.`,
        mobileP: `씨드앤(SeedN)은
        <br />
        작은 매장부터 건물,
        <br />
        더 나아가 도시로 단위를 확장해 가면서
        <br />
        세계 어디서든 네트워크를 형성하겠다는 비전에 따라
        <br />
        에너지제도 혁신을 비롯한
        <br />
        HVAC 시장에 혁신을 가져오기 위해 노력합니다.
        <br />`,
      },
      2: {
        title: '에너지',
        h3: `<span>낭비되는 에너지를 모아<br />큰 에너지를 </span><span>만듭니다.</span>`,
        p: `우리는 적정온도를 넘어 적정에너지를 통해
      <br />
      에너지를 적재적소에 쓸 수 있도록
      <br />
      데이터에 기반한 에너지 관리방식의 기준을 만드는
      <br />
      인공지능 솔루션을 향해 나아가고 있습니다.`,
        mobileP: `우리는 적정온도를 넘어 적정에너지를 통해
      <br />
      에너지를 적재적소에 쓸 수 있도록
      <br />
      데이터에 기반한 에너지 관리방식의 기준을 만드는
      <br />
      인공지능 솔루션을 향해 나아가고 있습니다.`,
      },
      3: {
        title: 'AIoT',
        h3: `<span>편리함, 그 이상의 </span>
        <span>AIoT 스마트 관리 시스템</span>`,
        p: `누구나 쉽게 제어할 수 있는 온도,
        <br />
        하지만 누구나 만족할 수 있는
        <br />
        온도의 공간으로 관리하는 것은 쉽지 않습니다.
        <br />
        <br />
        씨드앤은 지속적인 R&D와 PoC를 통한 현장 검증을 바탕으로
        <br />
        온습도 표준화를 가능하게 합니다.
        <br />
        대형 건물에 최적화되어 있던 기존 건물관리시스템의 한계를 뛰어 넘어
        <br />
        모든 공간에 적용 가능한 시스템 혁신을 이루어내고 있습니다.
        <br />
        <br />
        우리는 내가 원하는 온도의 공간이 당연한 세상을 지향하며
        <br />
        사람들의 행동 패턴을 바꿀 혁신을 위한 도전을 지속하고 있습니다.`,
        mobileP: `씨드앤은 지속적인 R&D와 PoC를 통한
        <br />
        현장 검증을 바탕으로
        <br />
        온습도 표준화를 가능하게 합니다.
        <br />
        <br />
        우리는 내가 원하는 온도의 공간이
        <br />
        당연한 세상을 지향하며
        <br />
        사람들의 행동 패턴을 바꿀
        <br />
        혁신을 위한 도전을 지속하고 있습니다.
        <br />`,
      },
    },
    background: {
      1: {
        h3: `씨드앤은
        <br />
        에너지 친화적인 사회를
        <br />
        만들어 갑니다.`,
        p: `'씨드앤(SeedN)'은 건물마다
        <br />
        리프라는 에너지 씨앗(Seed)이 자라
        <br />
        나무가 되어 네트워크(Network)처럼
        <br />
        연결됨을 의미합니다.
        <br />
        작은 매장부터 건물, 더 나아가
        <br />
        도시로 단위를 확장해 가면서
        <br />
        세계 어디서든 네트워크를 형성하겠다는
        <br />
        비전을 내포하고 있습니다.
        <br />
        씨드앤은 에너지제도 혁신을 비롯한
        <br />
        HVAC 시장에
        <br />
        혁신을 가져오기 위해 노력합니다.`,
      },
      2: {
        h3: `<span>낭비되는 </span>
        에너지를 모아
        <br />큰 에너지를 
        <span>만듭니다.</span>`,
        p: `씨드앤은 온도에 대한 기준을 만드는 것에서 그치지 않고,
        <br />
        에너지 관리방식의 기준을 만들어 가고 있습니다.
        <br />
        하루하루 작은 단위에서 절감한 에너지들이 쌓여서
        <br />한 달, 1년이 지났을 때 큰 에너지가 될 수 있음을 믿고 있습니다.`,
      },
      3: {
        h3: `편리함, 그 이상의
        <br />
        AIoT 스마트 <span>관리 시스템</span>`,
        p: `누구나 쉽게 제어할 수 있는 온도,
        <br />
        씨드앤은 온도 자체에 집중하여
        <br />
        온도에 대한 새로운 기준을 정립합니다.
        <br />
        리프 센서가 수집한 온습도 데이터,
        <br />
        건물 및 날씨데이터를 AI 기술로 조합해
        <br />
        공간 별 최적의 온도를 도출합니다.
        <br />
        지속적인 R&D와 PoC를 통한
        <br />
        현장 검증을 바탕으로
        <br />
        공간의 특성에 따른 온습도 표준화를
        <br />
        가능하게 합니다.
        <br />
        대형 건물에 최적화되어 있던
        <br />
        기존 건물관리시스템의 한계를 뛰어 넘어
        <br />
        모든 공간에 적용 가능한 시스템 혁신을
        <br />
        이루어내고 있습니다.`,
      },
    },
  },
  number: {
    title: '숫자로 보는 씨드앤',
    items: {
      1: {
        title: '8년',
        desc: `
          <p>
            <span>2015년 설립 이후,</span> <span>8년 간 축적된</span>
            <br />
            AIoT 및 데이터 분석 등 <span>기술적 노하우</span>
          </p>
          `,
      },
      2: {
        title: '300여 곳',
        desc: `
          <p>
            <span>실제 리프 서비스를</span>
            <br />
            <span>운영 중인 상업 시설</span>
            <br />약 300여 곳
          </p>
          `,
      },
      3: {
        title: '1억 개',
        desc: `
      <p>
        <span>실제 현장에서 수집한</span>
        <br />
        <span>환경데이터</span>
        <br />약 1억 개 이상
      </p>
      <small>23년 1월 기준</small>
      `,
      },
      4: {
        title: '17%',
        desc: `
    <p>
      <span>리프 사용 후,
      <br />
      기존 설정</span> <span>온도 제어 방식과 비교한</span>
      <br />
      에너지 효율 <span>평균 17% 이상</span>
    </p>
    `,
      },
    },
  },
  history: {
    title: {
      1: `씨드앤은 HVAC 시장을 넘어
      <br />
      에너지 산업에서 `,
      2: `새로운 모험을 하고 있습니다.`,
    },
    items: {
      2015: {
        year: '2015년',
        month: '05월',
        desc: '씨드앤(SeedN) 법인설립',
      },
      2016: {
        year: '2016년',
        1: {
          month: '06월',
          desc: {
            1: '강원지방기상청 ‘날씨데이터+스타트업 Contest’ 최우수상',
            2: '사물인터넷 국제전시회 K-Global IoT 챌린지 한국인터넷진흥원장상',
            3: '리프 1차 테스트 버전 개발 완료 및 베타테스트',
          },
        },
        2: {
          month: '12월',
          desc: 'K-GLOBAL 300 선정',
        },
      },
      2017: {
        year: '2017년',
        month: '09월',
        desc: {
          1: '피츠버그 국제발명전시회 Category of Gadget 금상',
          2: '리프 2차 버전 개발 완료 및 베타테스트',
        },
      },
      2018: {
        year: '2018년',
        month: '12월',
        desc: '리프 3차 버전 개발 완료',
      },
      2019: {
        year: '2019년',
        1: {
          month: '04월',
          desc: '리프 정식 온라인 판매 시작',
        },
        2: {
          month: '05월',
          desc: {
            1: '삼성 에스원 NDA 체결 및 냉난방 원격관리시스템 공급계약',
            2: '삼성에스원 블루스캔 냉난방원격제어시스템 개발',
          },
        },
        3: {
          month: '06월',
          desc: {
            1: '2019 코엑스 World IT쇼 우수 혁신상 수상 및 혁신 우수사례 선정',
            2: '한국 에너지 공단 에너지 적정기술 활용 공모전 우수상',
          },
        },
      },
      2020: {
        year: '2020년',
        1: {
          month: '06월',
          desc: '투썸플레이스 전국 직영점 공급 계약 체결',
        },
        2: {
          month: '08월',
          desc: '리프 4차 버전 개발',
        },
      },
      2021: {
        year: '2021년',
        1: {
          month: '08월',
          desc: {
            1: '카카오벤처스 Pre-A 5억원 투자유치',
            2: '본점 이전 (헤이그라운드 서울숲점)',
          },
        },
        2: {
          month: '11월',
          desc: 'COMEUP2021 루키 선정',
        },
        3: {
          month: '12월',
          desc: '중소벤처기업부 TIPS 선정',
        },
      },
      2022: {
        year: '2022년',
        1: {
          month: '01월',
          desc: '기업부설연구소 설립',
        },
        2: {
          month: '06월',
          desc: {
            1: `씨드앤-SK쉴더스, '친환경' 융합보안 서비스 제공`,
            2: `씨드앤, IoT 기반 냉동고 온습도센싱 상품 개발`,
          },
        },
        3: {
          month: '07월',
          desc: 'IBK창공 마포9기 혁신 창업기업 선정',
        },
        4: {
          month: '10월',
          desc: {
            1: '하나금융그룹 20억원 투자유치',
            2: '하나금융그룹 ESG 더블임팩트 매칭펀드 선정',
            3: '한국전자전(KES) 혁신상 수상',
          },
        },
        5: {
          month: '11월',
          desc: {
            1: 'SK텔레콤, SK쉴더스, 비트파인더 친환경 융합보안 MOU 체결',
            2: '씨드앤-ADT 스마트냉난방 출시',
            3: '한국사회투자 글로벌스타트업투자대회 수상',
            4: '핀포인트 건물AIoT 솔루션 MOU 체결',
            5: 'COMEUP2022 루키 선정',
          },
        },
        6: {
          month: '12월',
          desc: '인피니툼-SKT 펀드, 리벤처스, 한국사회투자 Pre-A 투자 유치',
        },
      },
      2023: {
        year: '2023년',
        1: {
          month: '01월',
          desc: '하나투자증권 Pre-A 투자 유치',
        },
        2: {
          month: '04월',
          desc: 'SK텔레콤 ESG Korea 2023 선정',
        },
      },
    },
  },
  story: {
    title: `<span>아무도 걸어보지 않았던 길을 </span>
    <span>앞장서고 있습니다.</span>`,
    desc: `여러 유형의 공간이 모여 하나의 건물이 완성되는 만큼
    <br />
    공간과 건물에 대한 이해와 전문성은 필수적입니다.
    <br />
    씨드앤은 건물에 대한 진정성을 바탕으로
    <br />
    건물에너지관리에 대한 전문성을 쌓아왔습니다.
    <br />
    우리는 끊임없는 기술 혁신을 통해
    <br />
    HVAC 시장과 에너지 산업에 대한 이해의 장벽을 낮추고
    <br />
    사용자의 편리함을 제고하기 위해 노력하고 있습니다.`,
  },
};

export default about;
