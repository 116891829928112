import styled from 'styled-components';
import { Container } from './ControlDesc';
import serverImage from 'assets/imgs/service/server.svg';
import acImage from 'assets/imgs/service/ac.svg';
import smallArrow from 'assets/imgs/service/smallArrow.svg';
import topDownArrow from 'assets/imgs/service/topDownArrow.svg';
import HubCircle, { Arrow, Circle, Text } from './HubCircle';
import SensorCircle from './SensorCircle';
import ControllerCircle, { StyledControllerCircle } from './ControllerCircle';
import { useState } from 'react';
import DeviceModal from './DeviceModal';
import useWindowSize from 'hooks/common/useWindowSize';
import { Mode } from './Specification';
import { useTranslation, Trans } from 'react-i18next';

function Flow() {
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const [mode, setMode] = useState<Mode | undefined>();
  const { t } = useTranslation('services');

  return (
    <MarginContainer>
      <ServerCircle>
        <img src={serverImage} />
        <Arrow src={smallArrow} />
        <TopDownArrow
          src={topDownArrow}
          style={{ width: '10px', height: '16px' }}
        />
        <Text>
          <Trans t={t} i18nKey="flow.server" />
        </Text>
      </ServerCircle>
      <HubCircle setMode={setMode} />
      <SensorCircle setMode={setMode} />
      <ControllerCircle setMode={setMode} />
      <ACCircle>
        <img src={acImage} />
        <Text>
          <Trans t={t} i18nKey="flow.ac" />
        </Text>
      </ACCircle>
      {isSmallScreen && mode && <DeviceModal mode={mode} setMode={setMode} />}
    </MarginContainer>
  );
}

const ServerCircle = styled(Circle)`
  @media (max-width: 767px) {
    box-shadow: none;
    & > img {
      width: 48px;
      height: 42px;
    }
  }
`;

const MarginContainer = styled(Container)`
  margin-top: 149px;
  margin-bottom: 300px;
  height: 610px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 140px;
    height: 620px;
    padding-top: 50px;
  }
`;

const ACCircle = styled(StyledControllerCircle)`
  left: 790px;
  z-index: -1;
  @media (max-width: 767px) {
    top: 510px;
    left: auto;
    right: 52px;
    box-shadow: none;
    & > img {
      width: 52px;
      height: 52px;
    }
  }
`;

export const TopDownArrow = styled.img`
  position: absolute;
  top: 120px;
  @media (min-width: 768px) {
    display: none;
  }
`;

export default Flow;
