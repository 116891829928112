import styled from 'styled-components';
import controllerImage from 'assets/imgs/service/controller.svg';
import smallArrow from 'assets/imgs/service/smallArrow.svg';
import RealController from 'assets/imgs/service/realController.png';
import { Arrow, Hover, Shadow, Text } from './HubCircle';
import { StyledSensorCircle, SensorLeaf, SmallTextBox } from './SensorCircle';
import topDownArrow from 'assets/imgs/service/topDownArrow.svg';
import { TopDownArrow } from './Flow';
import useWindowSize from 'hooks/common/useWindowSize';
import { Props } from './HubCircle';
import { useTranslation, Trans } from 'react-i18next';

function ControllerCircle({ setMode }: Props) {
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const { t } = useTranslation('services');
  const onClick = () => {
    setMode('controller');
  };
  return (
    <>
      <StyledControllerCircle onClick={onClick}>
        <img src={controllerImage} />
        <Arrow src={smallArrow} />
        <TopDownArrow
          src={topDownArrow}
          style={{ width: '10px', height: '16px' }}
        />
        <Text>
          <Trans t={t} i18nKey="flow.controller" />
        </Text>
        {!isSmallScreen && (
          <Hover>
            <RealControllerImage src={RealController} />
            <SmallTextBox>
              <div>
                <Trans t={t} i18nKey="flow.controllerText1" />
              </div>
              <div>
                <Trans t={t} i18nKey="flow.controllerText2" />
              </div>
            </SmallTextBox>
          </Hover>
        )}
      </StyledControllerCircle>
      <ControllerLeaf />
      {isSmallScreen && <ControllerShadow />}
    </>
  );
}

const RealControllerImage = styled.img`
  width: 128px;
  height: 128px;
  position: relative;
  top: 5px;
  left: -10px;
`;

export const StyledControllerCircle = styled(StyledSensorCircle)`
  top: calc(442px - 149px);
  &:hover > div {
    opacity: 1;
  }
  @media (max-width: 767px) {
    left: auto;
    right: 52px;
    top: 355px;
    & > img {
      width: 54px;
      height: 54px;
    }
  }
`;

const ControllerLeaf = styled(SensorLeaf)`
  top: calc(452px - 149px);
  @media (max-width: 767px) {
    left: auto;
    right: 52px;
    top: 355px;
  }
`;

const ControllerShadow = styled(Shadow)`
  position: absolute;
  left: 220px;
  top: 347px;
`;

export default ControllerCircle;
