import styled from 'styled-components';
import realHubImg from 'assets/imgs/service/realHub.png';
import realSensorImg from 'assets/imgs/service/realSensor.png';
import realControllerImg from 'assets/imgs/service/realController.png';
import { Mode } from './Specification';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

interface Props {
  mode: Mode | undefined;
  setMode: (mode: Mode | undefined) => void;
}

function DeviceModal({ mode, setMode }: Props) {
  const { t } = useTranslation('services');
  const onClick = () => {
    setMode(undefined);
  };
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';
  return (
    <Container>
      <Background />
      {mode === 'hub' ? (
        <Content>
          <Hub src={realHubImg} />
          <Exit onClick={onClick}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
              <g opacity="0.2">
                <path d="M1 1L19.3667 19.3667" stroke="black" strokeWidth="2" />
                <path
                  d="M20 1L1.63333 19.3667"
                  stroke="black"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </Exit>
          <Name>
            <Trans t={t} i18nKey="flow.hub" />
          </Name>
          <p>
            <Trans t={t} i18nKey="flow.hubText1" />
          </p>
          <p>
            <Trans t={t} i18nKey="flow.hubText2" />
          </p>
        </Content>
      ) : mode === 'sensor' ? (
        <Content>
          <Sensor src={realSensorImg} />
          <Exit onClick={onClick}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
              <g opacity="0.2">
                <path d="M1 1L19.3667 19.3667" stroke="black" strokeWidth="2" />
                <path
                  d="M20 1L1.63333 19.3667"
                  stroke="black"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </Exit>
          <Name>
            <Trans t={t} i18nKey="flow.sensor" />
          </Name>
          <p>
            <Trans t={t} i18nKey="flow.sensorText1" />
          </p>
          <p>
            <Trans t={t} i18nKey="flow.sensorText2" />
          </p>
        </Content>
      ) : (
        <Content>
          <Controller src={realControllerImg} />
          <Exit onClick={onClick}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
              <g opacity="0.2">
                <path d="M1 1L19.3667 19.3667" stroke="black" strokeWidth="2" />
                <path
                  d="M20 1L1.63333 19.3667"
                  stroke="black"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </Exit>
          <Name>
            <Trans t={t} i18nKey="flow.controller" />
          </Name>
          <p>
            <Trans t={t} i18nKey="flow.controllerText1" />
          </p>
          <p>
            <Trans t={t} i18nKey="flow.controllerText2" />
          </p>
        </Content>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.8;
  z-index: -1;
`;

const Content = styled.div`
  width: 308px;
  height: 269px;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 93px 21px 28px;
  position: relative;
  & > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #1e1e1e;
    opacity: 0.8;
    margin-bottom: 14px;
  }
`;

const Name = styled.div`
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #111111;
  opacity: 0.8;
  margin-bottom: 14px;
`;

const Hub = styled.img`
  position: absolute;
  width: 145px;
  height: 160px;
  top: -80px;
  left: 85px;
`;

const Sensor = styled(Hub)`
  width: 170px;
  height: 210px;
  left: 75px;
`;

const Controller = styled(Hub)`
  width: 175px;
  height: 175px;
  left: 70px;
`;

const Exit = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;
export default DeviceModal;
