import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';
import { useState } from 'react';
import styled from 'styled-components';
import { SEEDN_NEW } from 'styles/palette';
import { ILLUST_ARTICLES } from 'constants/main';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';
import useWindowSize from 'hooks/common/useWindowSize';

const SLIDE_LENGTH = 4;

const IllustCarousel = () => {
  const { language } = useChangeLanguage();
  const isMobile = useWindowSize().windowSize! < 768;
  const { t } = useTranslation('main');
  const [slideCount, setSlideCount] = useState(0);
  const onRealIndexChange = (swiper: SwiperCore) =>
    setSlideCount(swiper.realIndex);

  return (
    <>
      <StyledSwiper
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        onRealIndexChange={onRealIndexChange}
        spaceBetween={100}
        speed={1000}
      >
        <Articles>
          {ILLUST_ARTICLES.map((article, idx) => (
            <Article
              key={idx}
              gap={article.gap}
              en={isMobile && language === 'en'}
            >
              <IllustImg
                src={article.illustImg}
                alt="일러스트"
                mobile={article.mobileWidth}
              />
              <Text>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t(`illust.articles.${idx + 1}.title`),
                  }}
                ></h3>
                {language === 'ko' && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`illust.articles.${idx + 1}.desc`),
                    }}
                  ></p>
                )}
              </Text>
            </Article>
          ))}
        </Articles>
      </StyledSwiper>
      <SlideButtons>
        {Array.from({ length: SLIDE_LENGTH }).map((_, i) => (
          <Dot key={i} index={i} count={slideCount} />
        ))}
      </SlideButtons>
    </>
  );
};

export default IllustCarousel;

const StyledSwiper = styled(Swiper)`
  width: 300px;
  margin-bottom: 20px;
  overflow: visible;
`;

const Articles = styled.div`
  width: 100%;
  display: flex;
  gap: 80px;
  padding: 0 0 30px 40px;
`;

const SlideButtons = styled.div`
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: 0 auto;
`;

const Dot = styled.button<{ index: number; count: number }>`
  width: 8px;
  height: 8px;
  background-color: ${({ index, count }) =>
    index === count ? SEEDN_NEW : '#e2e2e2'};
  border-radius: 50%;
`;

const Article = styled(SwiperSlide)<{
  gap?: string;
  marginBottom?: string;
  reverse?: boolean;
  en?: boolean;
}>`
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column-reverse;
  justify-content: start;
  align-items: flex-start;
  width: 300px;
  height: ${({ en }) => (en ? '330px' : '460px')};
  padding: 31px 24px;
  margin: 0 auto;
  gap: 0;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);

  &:not(:last-child) {
    /* margin-right: 50vw; */
  }
`;

const IllustImg = styled.img<{ mobile?: string }>`
  width: ${({ mobile }) => mobile};
  height: ${({ mobile }) => mobile};
  margin: 0 auto;
`;

const Text = styled.div`
  width: 250px;
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 0;
    opacity: 0.8;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    position: absolute;
    top: 320px;
    left: 24px;
    letter-spacing: -0.019em;
    color: #1e1e1e;
    opacity: 0.8;
  }
  strong {
    font-weight: 700;
    display: inline-block;
    background-color: #e7dcf8;
    height: 23px;
    line-height: 23px;
  }
  span {
    display: block;
  }
`;
