import useWindowSize from 'hooks/common/useWindowSize';
import styled from 'styled-components';
import Divided from './Divided';
import MobileDevided from './MobileDivided';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

interface StyledProps {
  isEnglish: boolean;
}

function PaymentOptions() {
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const { t } = useTranslation('services');
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';

  return (
    <>
      <Container>
        {!isSmallScreen && <BackgrounLeft isEnglish={isEnglish} />}
        <Title isEnglish={isEnglish}>
          <Trans t={t} i18nKey="payment.title" />
        </Title>
        {isSmallScreen ? <MobileDevided /> : <Divided />}
        {/* <Button>
          <button>결제하기</button>
        </Button> */}
      </Container>
    </>
  );
}

const Container = styled.section`
  margin: 200px auto 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BackgrounLeft = styled.div<StyledProps>`
  position: absolute;
  left: 0;
  top: ${props => (props.isEnglish ? '180px' : '120px')};
  width: 50vw;
  background: black;
  height: 791px;
`;

const Title = styled.div<StyledProps>`
  padding-left: 50px;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 60px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    padding-left: 0;
    margin-bottom: 20px;
  }
`;

const Button = styled.div`
  width: 214px;
  height: 60px;
  margin: 0 auto;
  background: #6f36c9;
  border-radius: 12.6087px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > button {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: white;
  }
  @media (max-width: 767px) {
    width: 124px;
    height: 35px;
    border-radius: 10px;
    & > button {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
    }
  }
`;

export default PaymentOptions;
