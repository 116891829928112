import styled, { css } from 'styled-components';
import { SEEDN_NEW } from 'styles/palette';
import { H2 } from 'styles';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

interface ICircle {
  animation: number;
  circle: {
    image: string;
    text: string;
    id: number;
  };
  [x: string]: any;
}

const Circle = ({ animation, circle, ...props }: ICircle) => {
  const { t } = useTranslation('about');
  const { language } = useChangeLanguage();
  return (
    <CircleItem
      animation={animation}
      image={circle.image}
      en={language === 'en'}
      {...props}
    >
      <H2>{t(`intro.circles.${circle.id}.title`)}</H2>
      <AnimationText animation={animation}>
        <h3
          dangerouslySetInnerHTML={{
            __html: t(`intro.circles.${circle.id}.h3`),
          }}
        />
        <p>
          <Trans t={t} i18nKey={`intro.circles.${circle.id}.p`} />
        </p>
      </AnimationText>
    </CircleItem>
  );
};

export default Circle;

const CircleItem = styled.div<{
  image: String;
  animation: number;
  en: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-size: cover;
  box-shadow: 30px 40px 30px rgba(0, 0, 0, 0.3);
  transition: all 800ms ease-in-out;

  &:first-child {
    left: 45px;
    z-index: 10;

    & > div {
      top: 6px;
      left: calc(280px + 70px);
    }
  }

  &:nth-child(2) {
    top: -64px;
    left: 0;
    z-index: 8;

    & > div {
      top: 252px;
      left: calc(-280px - 88px);
    }
  }

  &:last-child {
    top: -128px;
    left: -45px;

    & > div {
      top: 252px;
      left: ${({ en }) => (en ? '-500px' : '-640px')};
    }
  }

  &::before {
    content: '';
    background-color: rgba(17, 17, 17, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all 800ms ease-in-out;
  }

  h2 {
    position: absolute;
    color: #ffffff;
    z-index: 10;
    transition: opacity 800ms ease-in-out;

    @media (min-width: 768px) {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
    }
  }

  @media (max-width: 767px) {
    width: 114px;
    height: 114px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: translateY(0);

    &:first-child {
      left: 0;
      transition: left 800ms ease-in-out 750ms;

      & > div {
        top: 0;
        left: 0;
      }
    }

    &:nth-child(2) {
      top: -28px;
      left: 0;
      transition: left 800ms ease-in-out 800ms;
      & > div {
        top: 0;
        left: 0;
      }
    }

    &:last-child {
      top: -56px;
      left: 0;
      transition: left 800ms ease-in-out 850ms;
      & > div {
        top: 0;
        left: 0;
      }
    }

    ${({ animation }) =>
      animation >= 1 &&
      css`
        left: 100vw !important;
      `}
  }

  ${({ animation }) =>
    animation &&
    css`
      transform: translateY(-30px);

      &::before {
        background-color: rgba(17, 17, 17, 0.3);
      }
    `}

  ${({ animation }) =>
    animation >= 2 &&
    css`
      h2 {
        opacity: 0;
      }
    `}

  ${({ animation }) =>
    animation === 2 &&
    css`
      &:first-child {
        & > div {
          opacity: 1;
          transform: translateY(-50px);
        }

        &::before {
          opacity: 0;
        }
      }

      &:not(:first-child) {
        opacity: 0.4;
        filter: blur(10px);
        box-shadow: none;
      }
    `}

    ${({ animation }) =>
    animation === 3 &&
    css`
      &:nth-child(2) {
        & > div {
          opacity: 1;
          transform: translateY(-50px);
        }

        &::before {
          opacity: 0;
        }
      }

      &:not(:nth-child(2)) {
        opacity: 0.4;
        filter: blur(10px);
        box-shadow: none;
      }
    `}

    ${({ animation }) =>
    animation === 4 &&
    css`
      &:last-child {
        z-index: 10;

        & > div {
          opacity: 1;
          transform: translateY(-50px);
        }

        &::before {
          opacity: 0;
        }
      }

      &:not(:last-child) {
        opacity: 0.4;
        filter: blur(10px);
        box-shadow: none;
      }
    `}
`;

const AnimationText = styled.div<{ animation: number }>`
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  transition: all 800ms ease-in-out;

  h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 767px) {
    top: 0;
    left: 0;

    h3 {
      font-size: 18px;
      line-height: 26px;
      color: ${SEEDN_NEW};
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
