import { useState, useEffect } from 'react';

const useHeader = (breakpoint: { desktop: number; mobile: number }) => {
  const [headerType, setHeaderType] = useState('scroll');
  const BREAKPOINT =
    window.innerWidth > 768 ? breakpoint.desktop : breakpoint.mobile;
  const handleScroll = () => {
    document.body.scrollTop > BREAKPOINT
      ? setHeaderType('normal')
      : setHeaderType('scroll');
  };

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll);
    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };
  });
  return headerType;
};

export default useHeader;
