import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  userId: string
  password: string
  authCheck: boolean
  error: string
  [prop: string]: any;
}

const initialState: InitialState = {
  userId: '',
  password: '',
  authCheck: false,
  error: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializeLoginForm: state => {
      state.userId = '';
      state.password = '';
      state.error = '';
    },
    onChangeAuth: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
  },
});

export const { initializeLoginForm, onChangeAuth } = authSlice.actions;

export default authSlice.reducer;
