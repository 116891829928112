import styled from 'styled-components';

interface Props {
  height: string;
  mobileHeight: string;
  label: string;
  bottomLabel: string;
  month: string;
}

const Bar = ({ height, mobileHeight, label, bottomLabel, month }: Props) => {
  return (
    <Wrapper>
      <StyledBar
        height={height}
        mobileHeight={mobileHeight}
        label={label}
        bottomLabel={bottomLabel}
        month={month}
      />
      <Label>{bottomLabel}</Label>
    </Wrapper>
  );
};

export default Bar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:first-child {
    margin-left: 60px;

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
`;

const StyledBar = styled.div<{
  height: string;
  mobileHeight: string;
  label: string;
  bottomLabel: string;
  month: string;
}>`
  position: relative;
  width: 67px;
  height: ${({ height }) => height};
  background: #c8c8c8;
  opacity: 0.4;
  border-radius: 33.5471px 33.5471px 0px 0px;
  margin-top: auto;

  @media (max-width: 767px) {
    width: 38px;
    height: ${({ mobileHeight }) => mobileHeight};
  }

  &::before {
    content: '${({ label }) => label}';
    position: absolute;
    top: calc(-6px - 24px);
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Inter';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #111111;
    opacity: 0.5;

    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      opacity: 0.3;
      top: calc(-2px - 15px);
    }
  }

  &::after {
    content: '${({ month }) => month}';
    position: absolute;
    bottom: -55px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    font-size: 23.8557px;
    line-height: 35px;
    text-align: center;
    color: #111111;
    white-space: nowrap;

    @media (max-width: 767px) {
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #111111;
      top: -40px;
      bottom: auto;
    }
  }
`;

const Label = styled.span`
  position: absolute;
  bottom: 35px;
  right: -15px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 23.8557px;
  line-height: 29px;
  color: #ffffff;
  transform: rotate(-90deg);
  z-index: 20;

  @media (max-width: 767px) {
    font-size: 13.7219px;
    line-height: 17px;
    bottom: 21px;
    right: -10px;
  }
`;
