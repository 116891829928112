import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import iconPhone from 'assets/icons/common/phone.png';
import iconMail from 'assets/icons/common/mail.png';
import Responsive from 'components/common/Responsive';
import Modal from 'components/common/Modal';
import LoginForm from './LoginForm';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import useLogout from 'hooks/auth/useLogout';
import Background from './Background';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18n';
import { GREY } from 'styles/palette';
import SNSIcon from './SNSIcon';
import { SNS_ICONS } from 'constants/main';
import { Z_INDEX } from 'styles';
import { ReactComponent as Logo } from 'assets/icons/common/logo-seedn.svg';
import useWindowSize from 'hooks/common/useWindowSize';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

const Footer = () => {
  const { pathname } = useLocation();
  const { authCheck } = useSelector(({ auth }: RootState) => auth);
  const [isAdminLoginModal, setIsAdminLoginModal] = useState(false);
  const logout = useLogout();
  const { language } = useChangeLanguage();
  const { t } = useTranslation('footer');
  const isMobile = useWindowSize().windowSize! < 768;

  return (
    <Container>
      <Background bgColor="#f9f9fb">
        <StyledResponsive>
          <Left>
            <div className="top">
              <StyledLogo fill="#5F5F5F" />
              {isMobile && (
                <span className="company-name-mobile">
                  <Trans t={t} i18nKey={'name'} />
                </span>
              )}
            </div>
            <Wrapper>
              <ContactList>
                <ContactItem>
                  <span className="phone-row">{t('phone')} : </span>
                  <a href="tel:02-6052-1506">02-6052-1506</a>
                </ContactItem>
                <ContactItem>
                  <Item>
                    <span className="phone-row">{t('phoneCS')} :</span>
                    <img className="" src={iconPhone} alt="phone" />
                    <a href="tel:02-6052-1507"> 02-6052-1507</a>
                  </Item>
                  <span className="phone-row">{` | `}</span>
                  <Item>
                    <span className="phone-row">{t('email')} :</span>
                    <img src={iconMail} alt="email" />
                    <a href="mailto:cs@seedn.co.kr"> cs@seedn.co.kr</a>
                  </Item>
                </ContactItem>
                <ContactItem>
                  <span className="phone-row">{t('emailRecruit')} : </span>
                  <a href="mailto:recruit@seedn.co.kr">recruit@seedn.co.kr</a>
                </ContactItem>
              </ContactList>
              <CompanyDesc>
                <div className={isMobile && language === 'en' ? 'en' : ''}>
                  <span className="name">
                    {t('name')}
                    {' | '}
                  </span>

                  <span>
                    <Trans t={t} i18nKey={'businessNumber'} />
                  </span>
                  {isMobile && language === 'en' ? '' : ' | '}
                  <span>
                    <Trans t={t} i18nKey={'representative'} />
                  </span>
                </div>
                <Trans className="address" t={t} i18nKey={'address'} />
              </CompanyDesc>
            </Wrapper>
            <CompanyInfo>
              {i18n.language === 'ko' && (
                <div className="info-mobile">
                  <Link to="/info">이용약관</Link>
                  {' | '}
                  <Link to="/info/private">개인정보처리방침</Link>
                  {' | '}
                  <a
                    href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=3648600096"
                    rel="noreferrer"
                    target="_blank"
                  >
                    사업자정보확인
                  </a>
                </div>
              )}
              <div>Copyright 2023 SeedN Co., Ltd. All Rights Reserved</div>
            </CompanyInfo>
          </Left>
          <Right>
            <IconGroup>
              {SNS_ICONS.map(icon => (
                <SNSIcon
                  key={icon.alt}
                  src={icon.src}
                  alt={icon.alt}
                  link={icon.link}
                />
              ))}
            </IconGroup>
            <Bottom>
              {pathname.includes('/careers') && (
                <AdminLoginButton
                  onClick={() => {
                    if (authCheck) {
                      logout();
                    } else {
                      setIsAdminLoginModal(true);
                    }
                  }}
                >
                  {authCheck ? '로그아웃' : '관리자 로그인'}
                </AdminLoginButton>
              )}
              {i18n.language === 'ko' && (
                <Info>
                  <Link to="/info">이용약관</Link>
                  {' | '}
                  <Link to="/info/private">개인정보처리방침</Link>
                  {' | '}
                  <a
                    href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=3648600096"
                    rel="noreferrer"
                    target="_blank"
                  >
                    사업자정보확인
                  </a>
                </Info>
              )}
            </Bottom>
          </Right>
        </StyledResponsive>
      </Background>

      {isAdminLoginModal && (
        <Modal onClose={() => setIsAdminLoginModal(false)}>
          <LoginForm onClose={() => setIsAdminLoginModal(false)} />
        </Modal>
      )}
    </Container>
  );
};

export default Footer;

const Container = styled.footer`
  position: relative;
  z-index: ${Z_INDEX.SECTION};
`;

const StyledResponsive = styled(Responsive)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 46px 88px 50px;

  @media (max-width: 767px) {
    padding: 50px 30px;
    flex-direction: column;
  }
`;

const Left = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #1e1e1e;

  .top {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    color: #636363;

    .top {
      display: flex;
      flex-direction: column;

      .company-name-mobile {
        display: block;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

const StyledLogo = styled(Logo)`
  width: 122px;
  height: 31px;

  @media (max-width: 767px) {
    width: 81px;
    height: 26px;
    margin-bottom: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactList = styled.ul`
  margin-bottom: 20px;
  font-size: 12px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    gap: 7px;
    margin-bottom: 50px;
    border: none;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;

    .phone-row {
      display: none;
    }
  }
`;

const ContactItem = styled.li`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: -0.011em;

  @media (max-width: 767px) {
    padding: 0;
    border: none;

    &:first-child {
      display: none;
    }
  }
`;

const Item = styled.div`
  display: inline;

  img {
    display: none;
    margin: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    gap: 8px;

    &:first-child {
      margin-bottom: 9px;
    }

    img {
      display: block;
      width: 16px;
    }
  }
`;

const CompanyDesc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.02em;

  .name {
    font-weight: 700;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
    order: -1;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;

    .name {
      display: none;
    }

    .en {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
`;

const CompanyInfo = styled(CompanyDesc)`
  font-weight: 400;
  margin-bottom: 0;

  .info-mobile {
    display: none;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;

    .info-mobile {
      display: block;
    }
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const IconGroup = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 767px) {
    gap: 12px;
    color: #636363;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const AdminLoginButton = styled.button`
  padding: 10px 8px;
  border: 1px solid ${GREY};
  color: ${GREY};
  text-align: center;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.04em;
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
  margin-top: auto;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Info = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #1e1e1e;
  text-align: right;

  @media (max-width: 767px) {
    display: none;
  }
`;
