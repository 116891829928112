import { useState } from 'react';
import styled, { css } from 'styled-components';
import { SEEDN_NEW } from 'styles/palette';
import { ReactComponent as IconArrowDown } from 'assets/icons/faq/arrow-down.svg';
import BalloonImg from 'assets/imgs/faq/faq-balloon.png';
import BalloonImgMobile from 'assets/imgs/faq/faq-balloon-mobile.png';
import { FAQ_LIST_ITEMS, FAQ_TABMENU_ITEMS } from 'constants/faq';
import { ReactComponent as IconPrev } from 'assets/icons/faq/page-prev.svg';
import { ReactComponent as IconNext } from 'assets/icons/faq/page-next.svg';
import Sidebar from './Sidebar';
import { ReactComponent as IconMenu } from 'assets/icons/faq/menu.svg';

const FaqList = () => {
  const [activeTabMenu, setActiveTabMenu] = useState(0);
  const [activeList, setActiveList] = useState(FAQ_LIST_ITEMS.map(_ => false));
  const [activePage, setActivePage] = useState(1);
  const [sidebar, setSidebar] = useState(true);

  const sidebarProp = {
    visible: sidebar,
    setVisible: setSidebar,
    activeTabMenu,
    setActiveTabMenu,
  };

  const onClickDropdown = (i: number) =>
    setActiveList(activeList =>
      activeList.map((listItem, index) => i === index && !listItem),
    );

  return (
    <Container>
      <TabMenu>
        {window.innerWidth >= 768 ? (
          FAQ_TABMENU_ITEMS.map((item, i) => (
            <TabMenuItem
              key={i}
              active={activeTabMenu === i}
              onClick={() => setActiveTabMenu(i)}
            >
              {item}
            </TabMenuItem>
          ))
        ) : (
          <SidebarButton onClick={() => setSidebar(true)}>
            <IconMenu />
            {FAQ_TABMENU_ITEMS[activeTabMenu]}
          </SidebarButton>
        )}
      </TabMenu>

      <ListBox>
        {FAQ_LIST_ITEMS.map((item, i) => (
          <ListItem key={i}>
            <DropdownBox
              active={activeList[i]}
              onClick={() => onClickDropdown(i)}
            >
              <span>{item.q}</span>
              <IconArrowDown />
            </DropdownBox>
            <Balloon active={activeList[i]}>{item.a}</Balloon>
          </ListItem>
        ))}
      </ListBox>

      <Pagination>
        {[1, 2, 3].map(page => (
          <Page
            active={activePage === page}
            onClick={() => setActivePage(page)}
          >
            {page}
          </Page>
        ))}
        <IconPrev
          onClick={() => setActivePage(prev => (prev > 1 ? prev - 1 : prev))}
        />
        <IconNext
          onClick={() => setActivePage(prev => (prev < 3 ? prev + 1 : prev))}
        />
      </Pagination>

      <Sidebar {...sidebarProp} />
    </Container>
  );
};

export default FaqList;

const Container = styled.section`
  width: 924px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const TabMenu = styled.ul`
  display: flex;
  gap: 37px;
  padding-bottom: 20px;
  border-bottom: 2px solid #000;

  @media (max-width: 768px) {
    padding-bottom: 14px;
    border-bottom: 2px solid rgba(65, 65, 65, 0.3);
  }
`;

const TabMenuItem = styled.li<{ active: boolean }>`
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: rgba(17, 17, 17, 0.2);
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${SEEDN_NEW};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${SEEDN_NEW};

      &::after {
        content: '';
        position: absolute;
        top: -6px;
        right: -6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${SEEDN_NEW};
      }
    `}
`;

const ListBox = styled.ul`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 70px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

const ListItem = styled.li``;

const DropdownBox = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px 30px 40px;
  cursor: pointer;

  &:hover {
    span {
      color: ${SEEDN_NEW};
    }
  }

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: ${({ active }) => (active ? SEEDN_NEW : 'rgba(17,17,17,0.8)')};
    transition: all 200ms ease-in-out;

    &::before {
      content: 'Q';
      margin-right: 50px;
      font-family: 'Inter';
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      color: ${SEEDN_NEW};
    }
  }

  svg {
    ${({ active }) =>
      active &&
      css`
        transform: rotate(180deg);
      `}
  }

  @media (max-width: 768px) {
    padding: 15px 20px 15px 6px;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 10px;

      &::before {
        font-size: 14px;
        line-height: 150%;
        margin-right: 20px;
      }
    }

    svg {
      width: 13px;
    }
  }
`;

const Balloon = styled.div<{ active: boolean }>`
  display: ${({ active }) => !active && 'none'};
  width: 805px;
  padding: 30px;
  padding-left: 50px;
  margin: 0 30px 20px 87px;
  background-image: url(${BalloonImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.019em;
  color: #1e1e1e;

  p {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  @media (max-width: 768px) {
    width: 312px;
    margin: 0 auto 20px;
    padding: 20px 20px 20px 33px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    background-image: url(${BalloonImgMobile});

    p {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
`;

const Pagination = styled.ul`
  display: flex;
  position: relative;
  gap: 45px;
  margin: 0 auto 140px;
  width: fit-content;
  height: 35px;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:first-of-type {
      left: -52px;
    }

    &:last-of-type {
      right: -52px;
    }
  }

  @media (max-width: 768px) {
    gap: 30px;
    margin-bottom: 110px;

    svg {
      width: 7px;
      &:first-of-type {
        left: calc(-30 - 7px);
      }

      &:last-of-type {
        right: calc(-30 - 7px);
      }
    }
  }
`;

const Page = styled.li<{ active: boolean }>`
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? '#fff' : SEEDN_NEW)};
  cursor: pointer;
  z-index: 10;
  transition: all 200ms ease-in-out;

  ${({ active }) =>
    active &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${SEEDN_NEW};
        width: 35px;
        height: 35px;
        border-radius: 50%;
        z-index: -10;

        @media (max-width: 768px) {
          width: 32px;
          height: 32px;
        }
      }
    `}

  @media (max-width:768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const SidebarButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 16px;
  background-color: ${SEEDN_NEW};
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  color: #fff;

  svg {
    width: 10px;
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
`;
