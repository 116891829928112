import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';

const Container = styled.div`
  position: relative;
  background-color: #e6e6e6;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 12px;
  padding-left: 10px;
  padding-top: 5px;
  height: 32px;
  font-weight: 400;
  cursor: pointer;
  z-index: 100;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-top: 5px solid #aaa;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  ul,
  li {
    list-style: none;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  li {
    padding-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
  }
  button {
    border: 0;
    outline: 0;
    background-color: #e6e6e6;
    display: block;
    cursor: pointer;
    line-height: 1;
    padding: 10px;
    width: 100%;
  }
  button:hover {
    background-color: #d1d1d1;
  }
  .invisible {
    display: none;
  }
  .first {
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-around;
    background-color: #e6e6e6;
    padding-left: 10px;
    font-weight: 500;
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    border-radius: 5px;
    li {
      font-size: 12px;
    }
  }
  .arrow {
    margin: 0 12px;
  }
`;

const StyledUL = styled.ul<{ order: number }>`
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-around;
  background-color: #e6e6e6;
  font-weight: 500;
  position: absolute;
  left: 200px;
  top: ${props => (props.order - 1) * 50}px;
  width: 100%;
  border-radius: 5px;
  li {
    height: 50px;
    button {
      height: 50px;
      border-radius: 5px;
      font-size: 12px;
    }
  }
`;

interface Props {
  onChange: (event: any) => void;
}

interface ButtonProps {
  value: string;
  onClick: (e: any) => void;
}

const SelectButton = ({ value, onClick }: ButtonProps) => {
  return (
    <button name="group" value={value} onClick={onClick}>
      {value}
    </button>
  );
};

const Dropdown = ({ onChange }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [subOpen, setSubOpen] = useState<string>('');
  const [group, setGroup] = useState<string>('직군 선택');
  const [subGroup, setSubGroup] = useState<string>('');
  const [isArrowShowing, setIsArrowShowing] = useState<boolean>(false);
  const openCategory = () => {
    setIsOpen(!isOpen);
  };

  const openSub = (e: React.MouseEvent<HTMLLIElement>) => {
    if (e.target instanceof HTMLElement) {
      const { id } = e.target;
      setIsArrowShowing(true);
      setGroup(e.target.innerText);
      setSubGroup('');
      setSubOpen(id);
    }
  };

  const closeSub = () => {
    setSubOpen('');
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.target instanceof HTMLButtonElement) {
      setSubGroup(e.target.value);
      onChange(e);
    }
  };

  return (
    <Container onClick={openCategory}>
      <div className="select">
        {group}
        {isArrowShowing ? <span className="arrow">&gt;</span> : ''}
        {subGroup}
      </div>
      <ul className={isOpen ? 'first' : 'invisible'}>
        <li
          id="SW"
          onMouseEnter={e => openSub(e)}
          onMouseLeave={() => closeSub()}
        >
          SW기획개발부서
          <StyledUL order={2} className={subOpen === 'SW' ? '' : 'invisible'}>
            <li>
              <SelectButton value="프론트엔드" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="백엔드" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="DevOps" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="프로덕트 매니저" onClick={onClick} />
            </li>
          </StyledUL>
        </li>
        <li
          id="R&D"
          onMouseEnter={e => openSub(e)}
          onMouseLeave={() => closeSub()}
        >
          R&D부서
          <StyledUL order={2} className={subOpen === 'R&D' ? '' : 'invisible'}>
            <li>
              <SelectButton value="건물에너지" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="건축설비" onClick={onClick} />
            </li>
          </StyledUL>
        </li>
        <li
          id="기획"
          onMouseEnter={e => openSub(e)}
          onMouseLeave={() => closeSub()}
        >
          기획전략부서
          <StyledUL order={3} className={subOpen === '기획' ? '' : 'invisible'}>
            <li>
              <SelectButton value="사업 기획" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="재무/회계" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="법인영업" onClick={onClick} />
            </li>
          </StyledUL>
        </li>
        <li
          id="HR"
          onMouseEnter={e => openSub(e)}
          onMouseLeave={() => closeSub()}
        >
          HR부서
          <StyledUL order={4} className={subOpen === 'HR' ? '' : 'invisible'}>
            <li>
              <SelectButton value="인사" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="총무" onClick={onClick} />
            </li>
          </StyledUL>
        </li>
        <li
          id="마케팅"
          onMouseEnter={e => openSub(e)}
          onMouseLeave={() => closeSub()}
        >
          마케팅부서
          <StyledUL
            order={5}
            className={subOpen === '마케팅' ? '' : 'invisible'}
          >
            <li>
              <SelectButton value="홍보마케팅" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="디자인" onClick={onClick} />
            </li>
          </StyledUL>
        </li>
        <li
          id="운영"
          onMouseEnter={e => openSub(e)}
          onMouseLeave={() => closeSub()}
        >
          서비스 운영기획부서
          <StyledUL order={6} className={subOpen === '운영' ? '' : 'invisible'}>
            <li>
              <SelectButton value="CX" onClick={onClick} />
            </li>
            <li>
              <SelectButton value="운영기획" onClick={onClick} />
            </li>
          </StyledUL>
        </li>
      </ul>
    </Container>
  );
};

export default Dropdown;
