export const THEME = '#66d5d3';

export const DARK_THEME = '#269da6';

export const GRAY = '#eff0f4';

export const LIGHT_GRAY = '#f9fafb';

/* 리뉴얼 추가 색상 */
export const SEEDN_GREEN = '#63C2A0';

export const GREY = '#AAAAAA';

export const SEEDN_MO = '#40826A';

export const GREY_MO = '#5F5F5F';

export const SEEDN_NEW = '#6F36C9';

export const SEEDN_SUB = '#AFFF7A';
