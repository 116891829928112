/* eslint import/no-anonymous-default-export: 0 */
export default {
  mode: 'en',
  name: 'SeedN Co., Ltd.',
  phone: `Tel`,
  phoneCS: 'CS',
  email: 'email',
  emailRecruit: 'Recruit',
  businessNumber: 'Business registration number: 364-86-00096',
  representative: 'Representative: Choi Hyunwoong',
  address:
    'Address : 115, Wangsimni-ro, Seongdong-gu, Seoul, Republic of Korea',
};
