import styled from 'styled-components';
import { usePostsViewer } from 'hooks/careers/usePostsViewer';
import { StrongText, Z_INDEX } from 'styles';
import { Link } from 'react-router-dom';
import Spinner from 'components/common/Spinner';
import { DARK_THEME, GREY, SEEDN_GREEN, THEME } from 'styles/palette';
import { IconWrite, IconNotification } from 'styles/Icons';
import { useEffect, useState } from 'react';

interface Props {
  post: {
    [x: string]: string;
  };
}

const PostViewerBlock = () => {
  const { authCheck, posts, isLoading } = usePostsViewer();
  const [active, setActive] = useState('전체');
  const postCategory = [
    { label: '전체', value: '전체' },
    { label: '인턴', value: 'Internship' },
    { label: '정규직', value: '정규직' },
  ];
  const [filteredPosts, setFilteredPosts] = useState<any[]>([]);

  useEffect(() => {
    setFilteredPosts(
      posts.filter(post => active === '전체' || post.contract === active),
    );
  }, [active, posts]);

  const Post = ({ post }: Props) => {
    return (
      <Link to={post.id}>
        <PostBlock>
          <div className="position">{post.position}</div>
          <div className="description">{post.contract}</div>
        </PostBlock>
      </Link>
    );
  };

  return (
    <Container>
      <PostsViewerBlock>
        <Wrapper>
          <Title>
            <div className="title">
              <span className="row">
                <StrongText>씨드앤</StrongText>과 온도를 혁신할{' '}
              </span>
              <span className="row">
                <StrongText>새로운 동료</StrongText>를 찾습니다.
              </span>
            </div>
            {authCheck && (
              <Link to="write">
                <StyledIconWrite />
              </Link>
            )}
          </Title>
          <ListTitle>
            채용 공고
            <StrongText>{posts.length}</StrongText>
          </ListTitle>
          <ToggleMenu>
            {postCategory.map(menu => (
              <li key={menu.value}>
                <button
                  className={active === menu.value ? 'active' : ''}
                  onClick={() => {
                    setActive(menu.value);
                  }}
                >
                  {menu.label}
                </button>
              </li>
            ))}
          </ToggleMenu>
        </Wrapper>
        <PostsBlock>
          <Border />
          {isLoading ? (
            <Spinner />
          ) : filteredPosts.length > 0 ? (
            <PostsList>
              {filteredPosts.map((post: any, i) => (
                <Post key={i} post={post} />
              ))}
            </PostsList>
          ) : (
            <div className="nojob">
              <IconNotification />
              <span style={{ marginLeft: '10px' }}>
                현재 모집공고가 없습니다!
              </span>
            </div>
          )}
        </PostsBlock>
      </PostsViewerBlock>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  z-index: ${Z_INDEX.SECTION};
`;

const PostsViewerBlock = styled.div`
  padding-top: 30px;
  padding-bottom: 80px;
  margin: 0 auto;

  .nojob {
    justify-content: center;
    padding: 100px 0;
    font-size: 1.3rem;
    display: flex;
  }

  @media (max-width: 767px) {
    padding: 2rem;

    .nojob {
      padding: 50px 0;
      font-size: 16px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  /* margin-bottom: 3rem; */

  @media (max-width: 767px) {
    width: 80vw;
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  .title {
    color: rgb(51, 61, 75);
    font-size: 40px;
    font-weight: bold;
    line-height: 58px;
  }
  @media (max-width: 767px) {
    justify-content: start;

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
    }

    .row {
      display: block;
    }
  }
`;

const StyledIconWrite = styled(IconWrite)`
  padding: 8px;
  display: flex;
  justify-content: center;
  background-color: ${THEME};
  border-radius: 10px;
  color: #fff;
  font-size: 18px;

  :hover {
    background-color: ${DARK_THEME};
  }
`;

const ListTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 20px;
  span {
    margin-left: 0.5rem;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Border = styled.div`
  margin: 0 auto;
  width: 850px;
  border-bottom: 1px solid ${SEEDN_GREEN};

  @media (max-width: 992px) {
    width: 47rem;
  }

  @media (max-width: 767px) {
    width: 80vw;
    border-bottom: 2px solid ${SEEDN_GREEN};
  }
`;

const ToggleMenu = styled.ul`
  display: flex;
  width: fit-content;
  li {
    padding-bottom: 10px;

    button {
      cursor: pointer;
      padding: 0 1.2rem;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      border-left: 1px solid #000;
      color: #000;
      &.active {
        color: ${SEEDN_GREEN};
      }
    }
  }

  li:first-child button {
    padding-left: 0;
    border: none;
  }
  li.active button {
    color: ${SEEDN_GREEN};
  }

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    button {
      padding: 0 16px;
    }
  }
`;

const PostsBlock = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const PostsList = styled.ul`
  padding-top: 23px;
  margin: 0 auto;
`;

const PostBlock = styled.div`
  width: 850px;
  padding: 10px 0 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${GREY};
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 0 0 3px ${SEEDN_GREEN} inset;
  }

  .position {
    color: rgb(25, 31, 40);
    font-size: 16px;
  }

  .description {
    font-size: 15px;
    padding: 0 35px;
    border-left: 1px solid #000;
    line-height: 1;
  }

  @media (max-width: 992px) {
    width: 47rem;
  }

  @media (max-width: 767px) {
    width: 80vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border: 1px solid #818181;
    padding: 10px 12px;

    .position {
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
    }

    .description {
      padding: 2px 14px;
      margin-left: auto;
      background-color: #aaa;
      border: none;
      border-radius: 9px;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
    }
  }
`;
export default PostViewerBlock;
