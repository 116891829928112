import styled from 'styled-components';
import Header from 'components/common/Header';
import Start from 'components/main/Start';
import Illust from 'components/main/Illust';
import Graph from 'components/main/Graph';
import Customer from 'components/main/Customer';
import Footer from 'components/common/Footer';
import Ask from 'components/main/Ask';
import Enterprise from 'components/main/Enterprise';
import useHeader from 'hooks/common/useHeader';
import Floating from 'components/common/FloatingButton';

const BREAKPOINT = {
  desktop: 2330,
  mobile: 1280,
};

const MainPage = () => {
  const headerType = useHeader(BREAKPOINT);
  return (
    <>
      <MainHeader type={headerType} />
      <Start />
      <Illust />
      <Graph />
      <Customer />
      <Ask />
      <Enterprise />
      <Floating />
      <Footer />
    </>
  );
};

const MainHeader = styled(Header)`
  position: fixed;
`;

export default MainPage;
