import React from 'react';
import styled, { css } from 'styled-components';
import { stretch } from 'styles/animations';
import { StyledProps } from './HubDesc';

function AnimatedLines({ isTransition }: { isTransition: boolean }) {
  return (
    <Lines>
      <SvgLeft width="300" height="30" isTransition={isTransition}>
        <line
          x1="300"
          y1="16.5"
          x2="0"
          y2="16.5"
          stroke="#fff"
          strokeWidth="3"
          className="line"
        />
      </SvgLeft>
      <SvgRight width="300" height="30" isTransition={isTransition}>
        <line
          x1="0"
          y1="16.5"
          x2="300"
          y2="16.5"
          stroke="#fff"
          strokeWidth="3"
          className="line"
        />
      </SvgRight>
      <LineLeft width="3" height="30" isTransition={isTransition}>
        <line x1="0" y1="0" x2="0" y2="30" stroke="#fff" strokeWidth="6" />
      </LineLeft>
      <LineRight width="3" height="30" isTransition={isTransition}>
        <line x1="0" y1="0" x2="0" y2="30" stroke="#fff" strokeWidth="6" />
      </LineRight>
    </Lines>
  );
}

const Lines = styled.div`
  position: relative;
  top: 180px;
  width: 300px;
  height: 30px;
`;

const SvgLeft = styled.svg<StyledProps>`
  position: absolute;
  left: -270px;
  .line {
    stroke-dasharray: 300;
    stroke-dashoffset: 270;
    ${({ isTransition }) =>
      isTransition &&
      css`
        animation: ${stretch} 1s forwards ease-in-out;
      `}
  }
`;

const SvgRight = styled.svg<StyledProps>`
  position: absolute;
  right: -270px;
  .line {
    stroke-dasharray: 300;
    stroke-dashoffset: 270;
    ${({ isTransition }) =>
      isTransition &&
      css`
        animation: ${stretch} 1s forwards ease-in-out;
      `}
  }
`;

const LineLeft = styled.svg<StyledProps>`
  position: absolute;
  left: 0;
  transition: all 1s ease-in-out;
  ${({ isTransition }) =>
    isTransition &&
    css`
      transform: translateX(-270px);
    `}
`;
const LineRight = styled.svg<StyledProps>`
  position: absolute;
  right: 0;
  transition: all 1s ease-in-out;
  ${({ isTransition }) =>
    isTransition &&
    css`
      transform: translateX(270px);
    `}
`;

export default AnimatedLines;
