import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'index';
import { setLanguage } from 'slices/languageSlice';

const useChangeLanguage = () => {
  const { language } = useSelector((state: RootState) => state.language);
  const { i18n } = useTranslation('header');
  const dispatch = useDispatch();

  const changeLanguage = () => {
    if (language === 'ko') {
      dispatch(setLanguage('en'));
      i18n.changeLanguage('en');
    }
    if (language === 'en') {
      dispatch(setLanguage('ko'));
      i18n.changeLanguage('ko');
    }
  };

  return { language, changeLanguage };
};

export default useChangeLanguage;
