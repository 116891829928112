import useWindowSize from 'hooks/common/useWindowSize';
import { useState, useEffect } from 'react';

const useScrollAnimation = () => {
  const [animation, setAnimation] = useState(0);
  const isMobile = useWindowSize().windowSize < 768;
  const handleScroll = () => {
    const scrollTop = document.body.scrollTop;
    if (!isMobile) {
      if (scrollTop > 890 && scrollTop <= 1890) {
        setAnimation(1);
      } else if (scrollTop > 1890 && scrollTop <= 2890) {
        setAnimation(2);
      } else if (scrollTop > 2890 && scrollTop <= 3890) {
        setAnimation(3);
      } else if (scrollTop > 3890 && scrollTop <= 4890) {
        setAnimation(4);
      } else if (scrollTop > 4890 && scrollTop <= 5590) {
        setAnimation(4.5);
      } else if (scrollTop > 5590 && scrollTop <= 6090) {
        setAnimation(4.9);
      } else if (scrollTop > 6090 && scrollTop <= 8200) {
        setAnimation(5);
      } else {
        setAnimation(0);
      }
    } else {
      if (scrollTop > 890 && scrollTop <= 2490) {
        setAnimation(1);
      } else if (scrollTop > 2490 && scrollTop <= 4090) {
        setAnimation(2);
      } else if (scrollTop > 4090 && scrollTop <= 5690) {
        setAnimation(3);
      } else if (scrollTop > 5690 && scrollTop <= 7290) {
        setAnimation(4);
      } else if (scrollTop > 7290 && scrollTop <= 7390) {
        setAnimation(4.5);
      } else if (scrollTop > 7390 && scrollTop <= 7490) {
        setAnimation(4.9);
      } else if (scrollTop > 7490 && scrollTop <= 9600) {
        setAnimation(5);
      } else {
        setAnimation(0);
      }
    }
  };

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll);
    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };
  });

  return animation;
};

export default useScrollAnimation;
