import styled, { css } from 'styled-components';
import { FAQ_TABMENU_ITEMS } from 'constants/faq';
import { Dispatch, SetStateAction } from 'react';
import { SEEDN_NEW } from 'styles/palette';
import { ReactComponent as IconNext } from 'assets/icons/faq/arrow-next.svg';

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  activeTabMenu: number;
  setActiveTabMenu: Dispatch<SetStateAction<number>>;
}

const Sidebar = ({
  visible,
  setVisible,
  activeTabMenu,
  setActiveTabMenu,
}: IProps) => {
  const onSelect = (i: number) => {
    setActiveTabMenu(i);
    setVisible(false);
  };

  return (
    <Container visible={visible}>
      <Nav>
        <MenuList>
          {FAQ_TABMENU_ITEMS.map((item, i) => (
            <MenuItem
              key={i}
              active={activeTabMenu === i}
              onClick={() => onSelect(i)}
            >
              {item}
              <IconNext />
            </MenuItem>
          ))}
        </MenuList>
      </Nav>
      <Overlay onClick={() => setVisible(false)} />
    </Container>
  );
};

export default Sidebar;

const Container = styled.div<{ visible: boolean }>`
  display: none;
  @media (max-width: 768px) {
    display: ${({ visible }) => visible && 'block'};
  }
`;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 273px;
  height: 100%;
  background-color: #fff;
  box-shadow: -10px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 35;
`;

const MenuList = styled.ul`
  padding-top: 130px;
`;

const MenuItem = styled.li<{ active: boolean }>`
  position: relative;
  width: 203px;
  padding: 10px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${SEEDN_NEW};
      color: #fff;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    `}

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

const Overlay = styled.div`
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: -10px 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 30;
  }
`;
