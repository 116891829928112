import styled, { css } from 'styled-components';
import { INTRO_BACKGROUND_TEXT, INTRO_CIRCLES } from 'constants/about';
import Wave from './Wave';
import useScrollAnimation from 'hooks/about/useScrollAnimation';
import Circle from './Circle';
import { SEEDN_NEW } from 'styles/palette';
import { useTranslation, Trans } from 'react-i18next';

const Intro = () => {
  const animation = useScrollAnimation();
  const { t } = useTranslation('about');
  return (
    <Container>
      <ScrollSection>
        {animation < 4.9 ? (
          <CircleWrapper animation={animation}>
            <Circles>
              {INTRO_CIRCLES.map(circle => (
                <Circle
                  animation={animation}
                  key={circle.text}
                  circle={circle}
                />
              ))}
            </Circles>
            <MobileCircles>
              {INTRO_CIRCLES.map(circle => (
                <MobileCircle key={circle.text} animation={animation}>
                  <MobileCircleImage image={circle.image} />
                  <MobileText>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: t(`intro.circles.${circle.id}.h3`),
                      }}
                    />
                    <p>
                      <Trans
                        t={t}
                        i18nKey={`intro.circles.${circle.id}.mobileP`}
                      />
                    </p>
                  </MobileText>
                </MobileCircle>
              ))}
            </MobileCircles>
            <BackgroundText animation={animation}>
              {INTRO_BACKGROUND_TEXT.map((text, index) => (
                <Text key={index} animation={animation}>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: t(`intro.background.${text.id}.h3`),
                    }}
                  ></h3>
                  <p>
                    <Trans t={t} i18nKey={`intro.background.${text.id}.p`} />
                  </p>
                </Text>
              ))}
            </BackgroundText>
          </CircleWrapper>
        ) : (
          <Wave animation={animation} />
        )}
      </ScrollSection>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  width: 100%;
  height: 7200px;
  margin: 95px auto 0;

  @media (max-width: 767px) {
    margin: 0 auto;
    height: 8800px;
  }
`;

const ScrollSection = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding-top: 60px;

  @media (max-width: 767px) {
    padding-top: 90px;
  }
`;

const CircleWrapper = styled.div<{ animation: number }>`
  transition: all 800ms ease-in-out;
  opacity: 0;

  ${({ animation }) =>
    animation < 4.5 &&
    css`
      opacity: 1;
    `}
`;

const Circles = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 316px 0 256px;
  z-index: 20;

  @media (max-width: 767px) {
    padding: 0;
    flex-direction: column;
  }
`;

const BackgroundText = styled.div<{ animation: number }>`
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 1024px;
  height: 100%;
  transition: opacity 800ms ease-in-out;

  ${({ animation }) => animation === 1 && `opacity: 0.3;`}
  ${({ animation }) => animation > 1 && `opacity: 0;`}

  @media (max-width: 767px) {
    top: 50%;
    transform: translate(-50%, -65%);
    width: 360px;
    height: 284px;
    opacity: 1;
  }
`;

const Text = styled.div<{ animation: number }>`
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;

  &:first-child {
    top: 104px;
    left: 48px;
    text-align: right;
    transition: left 1s ease-in-out;
  }

  &:nth-child(2) {
    top: 485px;
    left: 480px;
    transition: left 1s ease-in-out 400ms;
  }

  &:last-child {
    top: 48px;
    left: auto;
    right: 56px;
    text-align: right;
    transition: left 1s ease-in-out 300ms;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    opacity: 0.3;
    filter: blur(2.4px);
  }

  @media (max-width: 767px) {
    &:first-child {
      top: 0;
      left: 0;
      right: 17px;
    }

    &:nth-child(2) {
      top: 144px;
      left: 17px;
    }

    &:last-child {
      top: 224px;
      left: 0;
      right: 17px;
    }

    h3 {
      /* mobile Sub2 */
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      opacity: 0.2;

      span {
        display: block;
      }
    }

    p {
      display: none;
    }

    ${({ animation }) =>
      animation >= 1 &&
      css`
        left: 100vw !important;
      `}
  }
`;

const MobileCircles = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const MobileCircle = styled.div<{ animation: number }>`
  display: none;

  @media (max-width: 767px) {
    display: block;
    position: absolute;
    top: 0;
    right: 220vw;
    width: 100%;
    height: 100%;
    flex-basis: 180px;
    transition: all 1000ms ease-in-out 600ms;

    &:not(:first-child) {
      transition-delay: 400ms;
      h3 {
        margin-bottom: 44px;
      }
    }

    ${({ animation }) =>
      animation >= 1 &&
      css`
        &:first-child {
          right: 0;
        }
      `}

    ${({ animation }) =>
      animation >= 2 &&
      css`
        &:first-child {
          right: -150vw;
        }

        &:nth-child(2) {
          right: 0;
        }
      `}

      ${({ animation }) =>
      animation >= 3 &&
      css`
        &:nth-child(2) {
          right: -150vw;
        }
        &:last-child {
          right: 0;
        }
      `}

      ${({ animation }) =>
      animation >= 4 &&
      css`
        &:last-child {
          right: -150vw;
        }
      `}
  }
`;

const MobileCircleImage = styled.div<{ image: string }>`
  position: absolute;
  top: 103px;
  right: -69px;
  width: 180px;
  height: 180px;
  flex-basis: 180px;
  border-radius: 50%;
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
`;

const MobileText = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 260px;
    left: 24px;
    white-space: nowrap;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: ${SEEDN_NEW};
      margin-bottom: 18px;

      span {
        display: block;
      }
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export default Intro;
