import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Container } from './ControlDesc';
import hubImg from 'assets/imgs/service/realHub.png';
import sensorImg from 'assets/imgs/service/realSensor.png';
import controllerImg from 'assets/imgs/service/realController.png';
import hubSpec from 'assets/imgs/service/hubSpec.svg';
import sensorSpec from 'assets/imgs/service/sensorSpec.svg';
import controllerSpec from 'assets/imgs/service/controllerSpec.svg';
import hubEng from 'assets/imgs/service/hubEng.svg';
import sensorEng from 'assets/imgs/service/sensorEng.svg';
import controllerEng from 'assets/imgs/service/controllerEng.svg';
import hubSmall from 'assets/imgs/service/hubSmall.svg';
import sensorSmall from 'assets/imgs/service/sensorSmall.svg';
import controllerSmall from 'assets/imgs/service/controllerSmall.svg';
import hubSmallEng from 'assets/imgs/service/hubSmallEng.svg';
import sensorSmallEng from 'assets/imgs/service/sensorSmallEng.svg';
import controllerSmallEng from 'assets/imgs/service/controllerSmallEng.svg';
import SpecTable from './SpecTable';
import SmallPurpleBox from './SmallPurpleBox';
import useWindowSize from 'hooks/common/useWindowSize';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

export type Mode = 'hub' | 'sensor' | 'controller';

export interface StyledProps {
  mode: Mode;
  type?: Mode;
  isEnglish?: boolean;
}

function Specification() {
  const [mode, setMode] = useState<Mode>('hub');
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const { t } = useTranslation('services');
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';
  return (
    <MarginContainer>
      {isSmallScreen ? (
        <SmallPurpleBox mode={mode} setMode={setMode} />
      ) : (
        <PurpleBox>
          <Item onClick={() => setMode('hub')}>
            <HubImage src={hubImg} draggable="false" />
            <Text mode={mode} type="hub">
              <Trans t={t} i18nKey="flow.hub" />
            </Text>
          </Item>
          <Item onClick={() => setMode('sensor')}>
            <SensorImage src={sensorImg} draggable="false" />
            <Text mode={mode} type="sensor">
              <Trans t={t} i18nKey="flow.sensor" />
            </Text>
          </Item>
          <Item onClick={() => setMode('controller')}>
            <ControllerImage src={controllerImg} draggable="false" />
            <Text mode={mode} type="controller">
              <Trans t={t} i18nKey="flow.controller" />
            </Text>
          </Item>
          <WhiteBackground mode={mode} />
        </PurpleBox>
      )}
      <Detail>
        <Images>
          {isEnglish ? (
            <SpecImage
              isEnglish={isEnglish}
              mode={mode}
              src={
                mode === 'hub'
                  ? isSmallScreen
                    ? hubSmallEng
                    : hubEng
                  : mode === 'sensor'
                  ? isSmallScreen
                    ? sensorSmallEng
                    : sensorEng
                  : isSmallScreen
                  ? controllerSmallEng
                  : controllerEng
              }
            />
          ) : (
            <SpecImage
              isEnglish={isEnglish}
              mode={mode}
              src={
                mode === 'hub'
                  ? isSmallScreen
                    ? hubSmall
                    : hubSpec
                  : mode === 'sensor'
                  ? isSmallScreen
                    ? sensorSmall
                    : sensorSpec
                  : isSmallScreen
                  ? controllerSmall
                  : controllerSpec
              }
            />
          )}
        </Images>
        <SpecTable mode={mode} />
      </Detail>
    </MarginContainer>
  );
}

const MarginContainer = styled(Container)`
  margin-bottom: 300px;
  height: 836px;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
    margin: 0 auto 200px;
    height: 674px;
  }
`;

const PurpleBox = styled.div`
  background: #6f36c9;
  border-radius: 30px;
  margin-right: 20px;
  width: 228px;
  padding: 114px 30px;
  position: relative;
`;

const Detail = styled.div`
  width: 666px;
  @media (max-width: 767px) {
    width: 320px;
  }
`;

const Item = styled.div`
  background: #4f16a9;
  width: 166px;
  height: 166px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
  position: relative;
  cursor: pointer;
`;

const Text = styled.span<StyledProps>`
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  color: ${props => (props.mode === props.type ? '#111111' : '#ffffff')};
  opacity: 0.8;
  position: absolute;
  top: 116px;
  z-index: 20;
  transition: all 0.5s;
`;

const HubImage = styled.img`
  width: 130px;
  height: 130.4px;
  position: relative;
  left: 5px;
  top: -23px;
  z-index: 20;
`;

const SensorImage = styled(HubImage)`
  width: 138px;
  height: 171px;
  top: -10px;
  z-index: 20;
`;

const ControllerImage = styled(HubImage)`
  width: 131px;
  height: 131px;
  top: -10px;
  left: 4px;
  z-index: 20;
`;

const Images = styled.div`
  height: calc(836px - 450px);
  position: relative;
  @media (max-width: 767px) {
    height: 178px;
    margin-top: 50px;
  }
`;

const SpecImage = styled.img<StyledProps>`
  width: ${props =>
    props.isEnglish
      ? props.mode === 'controller'
        ? '560px'
        : '520px'
      : '540px'};
  height: ${props =>
    props.isEnglish && props.mode === 'controller' ? '210px' : '220px'};
  position: absolute;
  left: 80px;
  bottom: 73px;
  @media (max-width: 767px) {
    width: 300px;
    height: ${props =>
      props.isEnglish
        ? props.mode === 'controller'
          ? '120px'
          : '126px'
        : '150px'};
    left: 10px;
    bottom: unset;
  }
`;

const WhiteBackground = styled.div<StyledProps>`
  position: absolute;
  width: 216px;
  height: 216px;
  top: 0;
  left: 12px;
  top: 89px;
  background: white;
  border-radius: 103.5px 0px 0px 103.5px;
  z-index: 10;
  transition: 0.5s;
  ${({ mode }) => css`
    transform: translateY(
      ${mode === 'hub' ? '0' : mode === 'sensor' ? '220px' : '450px'}
    );
  `}
`;

export default Specification;
