import { Mode } from './Specification';
import styled from 'styled-components';
import useWindowSize from 'hooks/common/useWindowSize';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

interface Props {
  mode: Mode;
}

interface English {
  isEnglish: boolean;
}

function SpecTable({ mode }: Props) {
  const { windowSize } = useWindowSize();
  const isSmallScreen = windowSize < 768;
  const { t } = useTranslation('services');
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';
  if (mode === 'hub') {
    return (
      <Container style={{ padding: isSmallScreen ? '70px 50px' : '80px' }}>
        <Table>
          <Row style={{ height: isSmallScreen ? '30px' : 'unset' }}>
            <BoldCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.size" />
            </BoldCell>
            {isSmallScreen ? (
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.hubSizeHorizontal" />
              </GrayCell>
            ) : (
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.hubSize" />
              </GrayCell>
            )}
          </Row>
          {isSmallScreen && (
            <Row>
              <BoldCell isEnglish={isEnglish}></BoldCell>
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.hubSizeVertical" />
              </GrayCell>
            </Row>
          )}
          <Row>
            <BoldCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.color" />
            </BoldCell>
            <GrayCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.white" />
            </GrayCell>
          </Row>
          <Row style={{ height: isSmallScreen ? '30px' : 'unset' }}>
            <BoldCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.conn" />
            </BoldCell>
            <GrayCell isEnglish={isEnglish}>WiFi 2.4GHz</GrayCell>
          </Row>
          {isSmallScreen && (
            <Row>
              <BoldCell isEnglish={isEnglish}></BoldCell>
              <GrayCell isEnglish={isEnglish}>Bluetooth 4.2</GrayCell>
            </Row>
          )}
          <Row>
            <BoldCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.charge" />
            </BoldCell>
            <GrayCell isEnglish={isEnglish}>Android USB 5 PIN</GrayCell>
          </Row>
        </Table>
      </Container>
    );
  }
  if (mode === 'sensor') {
    return (
      <Container style={{ paddingBottom: '40px', paddingTop: '40px' }}>
        <Table>
          <Row style={{ height: isSmallScreen ? '30px' : 'unset' }}>
            <BoldCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.size" />
            </BoldCell>
            {isSmallScreen ? (
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.sensorSizeHorizontal" />
              </GrayCell>
            ) : (
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.sensorSize" />
              </GrayCell>
            )}
          </Row>
          {isSmallScreen && (
            <Row>
              <BoldCell isEnglish={isEnglish}></BoldCell>
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.sensorSizeVertical" />
              </GrayCell>
            </Row>
          )}
          <Row>
            <BoldCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.color" />
            </BoldCell>
            <GrayCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.white" />
            </GrayCell>
          </Row>
          <Row>
            <BoldCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.conn" />
            </BoldCell>
            <GrayCell isEnglish={isEnglish}>Bluetooth 4.2</GrayCell>
          </Row>
          <Row style={{ height: isSmallScreen ? '30px' : '60px' }}>
            <BoldCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.charge" />
            </BoldCell>
            <GrayCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.battery" />
            </GrayCell>
          </Row>
          <Row>
            <BoldCell isEnglish={isEnglish}></BoldCell>
            <GrayCell isEnglish={isEnglish}>
              {isSmallScreen ? (
                <Trans t={t} i18nKey="spec.lowEnergySmall" />
              ) : (
                <Trans t={t} i18nKey="spec.lowEnergy" />
              )}
            </GrayCell>
          </Row>
        </Table>
      </Container>
    );
  }
  return (
    <Container style={{ paddingBottom: '40px', paddingTop: '40px' }}>
      <Table>
        <Row style={{ height: isSmallScreen ? '30px' : 'unset' }}>
          <BoldCell isEnglish={isEnglish}>
            <Trans t={t} i18nKey="spec.size" />
          </BoldCell>
          {isSmallScreen ? (
            <GrayCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.sensorSizeHorizontal" />
            </GrayCell>
          ) : (
            <GrayCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.sensorSize" />
            </GrayCell>
          )}
        </Row>
        {isSmallScreen && (
          <Row>
            <BoldCell isEnglish={isEnglish}></BoldCell>
            <GrayCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.sensorSizeVertical" />
            </GrayCell>
          </Row>
        )}
        <Row>
          <BoldCell isEnglish={isEnglish}>
            <Trans t={t} i18nKey="spec.color" />
          </BoldCell>
          <GrayCell isEnglish={isEnglish}>
            <Trans t={t} i18nKey="spec.black" />
          </GrayCell>
        </Row>
        <Row style={{ height: isSmallScreen ? '30px' : 'unset' }}>
          <BoldCell isEnglish={isEnglish}>
            <Trans t={t} i18nKey="spec.conn" />
          </BoldCell>
          {isSmallScreen ? (
            <GrayCell isEnglish={isEnglish}>Bluetooth 4.2</GrayCell>
          ) : (
            <GrayCell isEnglish={isEnglish}>
              Bluetooth 4.2 / <Trans t={t} i18nKey="spec.ir" />
            </GrayCell>
          )}
        </Row>
        {isSmallScreen && (
          <Row>
            <BoldCell isEnglish={isEnglish}></BoldCell>
            <GrayCell isEnglish={isEnglish}>
              <Trans t={t} i18nKey="spec.ir" />
            </GrayCell>
          </Row>
        )}
        {isSmallScreen ? (
          <>
            <Row style={{ height: '30px' }}>
              <BoldCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.charge" />
              </BoldCell>
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.battery" />
              </GrayCell>
            </Row>
            <Row>
              <BoldCell isEnglish={isEnglish}></BoldCell>
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.lowEnergySmall" />
              </GrayCell>
            </Row>
          </>
        ) : (
          <>
            <Row style={{ height: isSmallScreen ? '30px' : '30px' }}>
              <BoldCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.charge" />
              </BoldCell>
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.battery" />
              </GrayCell>
            </Row>
            <Row>
              <BoldCell isEnglish={isEnglish}></BoldCell>
              <GrayCell isEnglish={isEnglish}>
                <Trans t={t} i18nKey="spec.lowEnergy" />
              </GrayCell>
            </Row>
          </>
        )}
      </Table>
    </Container>
  );
}

const Container = styled.div`
  background: #fafafa;
  height: 450px;
  padding: 80px;
  @media (max-width: 767px) {
    width: 320px;
    height: 370px;
    padding: 35px 50px;
    margin: 0 auto;
  }
`;

const Table = styled.div`
  display: table;
  height: 100%;
`;

const Row = styled.div`
  display: table-row;
`;

const BoldCell = styled.div<English>`
  display: table-cell;
  font-weight: 700;
  font-size: ${props => (props.isEnglish ? '18px' : '20px')};
  padding: 10px;
  padding-right: 30px;
  color: #111111;
  @media (max-width: 767px) {
    font-weight: 500;
    width: 90px;
    font-size: ${props => (props.isEnglish ? '12px' : '14px')};
    line-height: 20px;
    opacity: 0.8;
    padding: 0;
    padding-right: 50px;
  }
`;

const GrayCell = styled.div<English>`
  display: table-cell;
  color: #111111;
  opacity: 0.8;
  padding: 10px;
  padding-left: 20px;
  font-size: ${props => (props.isEnglish ? '18px' : '20px')};
  font-weight: 400;
  @media (max-width: 767px) {
    font-weight: 400;
    font-size: ${props => (props.isEnglish ? '12px' : '14px')};
    line-height: 20px;
    opacity: 0.5;
    padding: 0;
  }
`;

export default SpecTable;
