import useWindowSize from 'hooks/common/useWindowSize';
import styled from 'styled-components';
import ScheduleController from './ScheduleController';
import Slider from './Slider';
import TemperatureController from './TemperatureController';
import TimerController from './TimerController';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

interface English {
  isEnglish: boolean;
}

function ControlDesc() {
  const { windowSize } = useWindowSize();
  const { t } = useTranslation('services');
  const isSmallScreen = windowSize < 768;
  const { language } = useChangeLanguage();
  const isEnglish = language === 'en';
  return (
    <Container>
      <PurpleText>
        <Trans t={t} i18nKey="control.purple" />
      </PurpleText>
      <BigText>
        <span>
          <Trans t={t} i18nKey="control.big1" />
        </span>
        <span>
          <Trans t={t} i18nKey="control.big2" />
        </span>
        <span>
          <Trans t={t} i18nKey="control.big3" />
        </span>
      </BigText>
      <NormalText>
        <Trans t={t} i18nKey="control.normal" />
      </NormalText>
      <Controllers>
        <TemperatureController />
        <ScheduleController />
        <TimerController />
      </Controllers>
      <ControllerNames>
        <div>
          <Trans t={t} i18nKey="control.controllersName1" />
        </div>
        <div>
          <Trans t={t} i18nKey="control.controllersName2" />
        </div>
        <div>
          <Trans t={t} i18nKey="control.controllersName3" />
        </div>
      </ControllerNames>
      {isSmallScreen && <Slider />}
      <PurpleBox isEnglish={isEnglish}>
        <div>
          <p>
            <Trans t={t} i18nKey="control.purpleBox1" />
          </p>
          <p>
            <Trans t={t} i18nKey="control.purpleBox2" />
          </p>
          <p>
            <Trans t={t} i18nKey="control.purpleBox3" />
          </p>
        </div>
        <Circle />
      </PurpleBox>
    </Container>
  );
}

export const Container = styled.section`
  font-family: 'Noto Sans KR';
  padding: 0 50px 0 50px;
  max-width: 1023px;
  margin: 200px auto 507px;
  @media (max-width: 767px) {
    margin: 44px auto 60px;
    max-width: 360px;
    padding: 0 20px 0 20px;
  }
`;

export const PurpleText = styled.div`
  color: #6f36c9;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const BigText = styled.div`
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 40px;
  letter-spacing: -0.04em;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    height: 60px;
    font-weight: 500;
    margin-top: 10px;
  }
`;

export const NormalText = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 80px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const Controllers = styled.div`
  padding-left: 11px;
  padding-right: 10px;
  height: 261px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: none;
  }
`;

const ControllerNames = styled.div`
  display: flex;
  padding-left: 11px;
  padding-right: 10px;
  margin-top: 10px;
  color: #6f36c9;
  font-size: 12px;
  justify-content: space-between;
  & > div {
    width: 261px;
    height: 32px;
    display: flex;
    align-items: center;
  }
  margin-bottom: 38px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const PurpleBox = styled.div<English>`
  width: 523px;
  height: 176px;
  margin: 0 auto;
  border: 3px solid #6f36c9;
  padding: 28px 50px;
  & > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    font-weight: 400;
    font-size: 20px;
  }
  position: relative;
  @media (max-width: 767px) {
    width: 312px;
    height: 110px;
    padding: ${props => (props.isEnglish ? '25px 30px' : '30px 36px')};
    & > div {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      height: 50px;
    }
  }
`;

const Circle = styled.span`
  position: absolute;
  background: #6f36c9;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -10px;
  left: 252px;
  @media (max-width: 767px) {
    left: 146px;
  }
`;

export default ControlDesc;
