import styled from 'styled-components';
import PostViewerBlock from './PostsViewerBlock';
import ScrollSection from './ScrollSection';
import CareerProcess from './CareerProcess';

const PostsViewer = () => {
  return (
    <Container>
      <PostViewerBlock />
      <ScrollSection />
      <CareerProcess />
    </Container>
  );
};

export default PostsViewer;

const Container = styled.main`
  width: 100%;
`;
