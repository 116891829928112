import styled from 'styled-components';
import CareerButton from 'components/careers/CareerButton';
import ProcessCircle from 'components/careers/ProcessCircle';
import { SEEDN_GREEN } from 'styles/palette';
import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { Z_INDEX } from 'styles';
import { SCROLL_HEIGHT } from 'constants/careers';

const CareerProcess = () => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <Container>
      <Main>
        <Block>
          <Title>존중은 씨드앤의 가장 중요한 핵심 가치입니다.</Title>
          <Content>
            <p>지속가능한 환경과 사회를 위해 자유로운 분위기 속에서</p>
            <p>
              <span>상호 존중하고 배려하는 방식으로 일하며,</span>{' '}
              <span>개인과 회사 모두가 성장할 수 있는 팀입니다.</span>
            </p>
          </Content>
          <IntroduceButton>
            <a
              href="https://seednvillage.notion.site/ca0a2e491fd5452fbae618468ac9f527"
              target="_blank"
              rel="noreferrer"
            >{`팀소개 >`}</a>
          </IntroduceButton>
        </Block>
        <Block>
          <SecondTitle>채용 전형</SecondTitle>
          <CircleGroup>
            <ProcessCircle color="#333">
              <span>
                서류
                <br />
                전형
              </span>
            </ProcessCircle>
            <ProcessCircle color="#3F574E">
              <span className="before">
                캐주얼
                <br />
                화상미팅
              </span>
            </ProcessCircle>
            <ProcessCircle color="#4B7B6A">
              <span>
                1차
                <br />
                면접
              </span>
            </ProcessCircle>
            <ProcessCircle color="#579E85">
              <span>
                2차
                <br />
                면접
              </span>
            </ProcessCircle>
            <ProcessCircle color={SEEDN_GREEN}>
              <span>
                최종
                <br />
                합격
              </span>
            </ProcessCircle>
          </CircleGroup>
          <Description>
            <span>{`*캐주얼 화상미팅에서는 온라인에서만 알 수 있는 정보가 아닌 씨드앤의
          목표, 미션, 정체성, 조직문화 등에 대해 상세히 설명드리는 시간을 갖고
          있어요. `}</span>
            <span>{`해당 전형은 면접 결과에 어떤 영향도 주지 않기 때문에 편한
          마음으로 임해주세요.`}</span>
          </Description>
          <IntroduceButton
            onClick={() =>
              document.body.scrollTo({ top: 0, behavior: 'smooth' })
            }
          >{`지원하기 >`}</IntroduceButton>
        </Block>
      </Main>
    </Container>
  );
};

export default CareerProcess;

const Container = styled.section`
  margin-top: calc(-${SCROLL_HEIGHT}px * 0.18);
  z-index: ${Z_INDEX.SECTION};
  position: relative;

  @media (max-width: 767px) {
    margin-top: calc(-${SCROLL_HEIGHT}px * 0.23);
  }
`;

const Main = styled.div`
  padding-top: 64px;
  margin: 0 auto;
  width: 850px;

  @media (max-width: 767px) {
    width: auto;
    padding: 0 24px;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 124px;

  &:last-child {
    padding-bottom: 100px;
  }

  @media (max-width: 767px) {
    &:last-child {
      padding-bottom: 100px;
    }
  }
`;

const Title = styled.h2`
  font-size: 32px;
  line-height: 80px;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    padding-top: 100px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
  }
`;

const SecondTitle = styled(Title)`
  margin-bottom: 28px;
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
`;

const Content = styled.div`
  margin-bottom: 50px;
  p {
    font-size: 22px;
    line-height: 20px;
    letter-spacing: -0.06em;

    &:first-child {
      margin-bottom: 22px;
    }
  }

  @media (max-width: 767px) {
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    span {
      display: block;
    }
  }
`;

const IntroduceButton = styled(CareerButton)`
  margin-left: auto;

  @media (max-width: 767px) {
    width: auto;
    padding: 10px 30px;
  }
`;

const CircleGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  @media (max-width: 767px) {
    margin-bottom: 27px;
  }

  span {
    position: relative;
  }

  span.before::before {
    content: '*';
    position: absolute;
    top: -9px;
    left: 2px;
    font-weight: 300;

    @media (max-width: 767px) {
      top: -4px;
    }
  }
`;

const Description = styled.p`
  width: 780px;
  margin: 0 auto 50px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.06em;

  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;

    span {
      display: block;
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
`;
