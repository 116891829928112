/* eslint import/no-anonymous-default-export: 0 */
export default {
  mode: 'kr',
  name: '(주)씨드앤',
  phone: '대표전화',
  phoneCS: 'CS문의',
  email: '이메일',
  emailRecruit: '채용문의',
  businessNumber: '사업자 등록번호: 364-86-00096',
  representative: '대표 : 최현웅',
  address:
    '우편번호 04768 | 서울 성동구 왕십리로 115, 6층 605, 606, 607호 (성수동1가, 헤이그라운드 서울숲점)',
};
