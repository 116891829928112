import { SEEDN_NEW, SEEDN_SUB } from 'styles/palette';
import styled from 'styled-components';
import WaveImgDesktop from 'assets/imgs/main/bar-graph-wave.gif';
import WaveImgMobile from 'assets/imgs/main/graph-wave-mobile.png';
import Bar from './Bar';
import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import { useState, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

const BarGraph = () => {
  const countRef = useRef<(null | HTMLElement)[]>([]);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [count1, setCount1] = useState(18300.5);
  const [count2, setCount2] = useState(0.9);
  const [transition, setTransition] = useState(false);
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setTransition(true);
    }
  };
  const setTarget = useIntersectionObserver({ onIntersect });
  const { t } = useTranslation('main');
  const { language } = useChangeLanguage();

  const COUNT_NUMBER = [19120.5, 657.9];

  useEffect(() => {
    if (!transition) return;
    const increment = setInterval(() => {
      if (count1 === COUNT_NUMBER[0]) {
        clearInterval(increment);
        setCount1(COUNT_NUMBER[0]);
        return;
      }
      setCount1(count => count + 1);
    }, 1);
    intervalRef.current = increment;
    return () => clearInterval(increment);
  }, [count1, transition]);

  useEffect(() => {
    if (!transition) return;
    const increment2 = setInterval(() => {
      if (count2 === COUNT_NUMBER[1]) {
        clearInterval(increment2);
        setCount2(COUNT_NUMBER[1]);
        return;
      }
      setCount2(count => count + 1);
    }, 1);
    intervalRef.current = increment2;
    return () => clearInterval(increment2);
  }, [count2, transition]);

  return (
    <Container>
      <Title ref={setTarget} en={language === 'en'}>
        <span>
          <Trans t={t} i18nKey={'barGraph.title.smallText'} />
        </span>
        <h1>
          {language === 'ko' ? (
            <>
              CO<span className="small">2</span>는{' '}
            </>
          ) : (
            <>
              We reduced CO<span className="small">2</span> by{' '}
            </>
          )}
          <strong
            ref={itself => {
              if (countRef.current) {
                countRef.current[0] = itself;
              }
            }}
            className={transition ? 'transition' : ''}
          >
            {`${count1.toLocaleString()}`}kg
          </strong>{' '}
          {language === 'ko' && '절감했어요.'}
          <br />
          {language === 'ko' ? <>소나무는 </> : <>Same amount with </>}
          <strong
            ref={itself => {
              if (countRef.current) {
                countRef.current[1] = itself;
              }
            }}
            className={transition ? 'transition' : ''}
          >
            {`${count2.toLocaleString()}`}
            {language === 'ko' && '그루'}
          </strong>{' '}
          {language === 'ko' ? '심을 수 있어요.' : 'pine trees'}
        </h1>
        <DescText>{t('barGraph.title.desc')}</DescText>
      </Title>
      <GraphWrapper>
        <Graph>
          <Bars>
            <Bar
              height="252px"
              mobileHeight="129px"
              label="15,092"
              bottomLabel="13,229"
              month={t('barGraph.bar.1')}
            />
            <Bar
              height="462px"
              mobileHeight="216px"
              label="40,912"
              bottomLabel="14,425"
              month={t('barGraph.bar.2')}
            />
            <Bar
              height="302px"
              mobileHeight="155px"
              label="23,591"
              bottomLabel="10,759"
              month={t('barGraph.bar.3')}
            />
          </Bars>
          <WaveDesktop className={transition ? 'transition' : ''} />
          <WaveMobile />
        </Graph>
        <DescBox>
          <p>
            <span>{t('barGraph.box.p.1')}</span>
            <Trans t={t} i18nKey={'barGraph.box.p.2'} />
          </p>
          <small>{t('barGraph.box.small')}</small>
          <Circle>
            <div />
          </Circle>
        </DescBox>
        <Caption>
          <CaptionItem>
            <div></div>
            <span>
              <Trans t={t} i18nKey={'barGraph.caption.1'} />
            </span>
          </CaptionItem>
          <CaptionItem type="leaf">
            <div></div>
            <span>
              <Trans t={t} i18nKey={'barGraph.caption.2'} />
            </span>
          </CaptionItem>
        </Caption>
      </GraphWrapper>
    </Container>
  );
};

export default BarGraph;

const Container = styled.section`
  margin: 0 auto 400px;
  width: 924px;
  padding: 80px;
  background-color: rgba(217, 217, 217, 0.15);

  @media (max-width: 767px) {
    width: 100%;
    padding: 30px 0 50px;
    margin-bottom: 120px;
  }
`;

const Title = styled.div<{ en: boolean }>`
  margin-bottom: 110px;

  * {
    text-align: center;
  }

  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    opacity: 0.8;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }

  h1 {
    font-weight: 350;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 30px;

    strong {
      font-weight: 900;
    }

    .small {
      display: inline;
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 80px;

    span {
      font-weight: 400;
      font-size: ${({ en }) => (en ? '11px' : '10px')};
      line-height: 14px;
      opacity: 0.8;
      margin-bottom: 20px;
    }

    h1 {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;

      strong {
        font-weight: 700;
      }

      .small {
        display: inline;
        font-size: ${({ en }) => (en ? '10px' : '8px')};
      }
    }
  }
`;

const DescText = styled.small`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.019em;
  color: #1e1e1e;
  opacity: 0.5;
  display: inline-block;
  width: 100%;
  text-align: end;

  @media (max-width: 767px) {
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: -0.019em;
    opacity: 0.5;
  }
`;

const GraphWrapper = styled.div`
  position: relative;
  border-bottom: 2px solid #bfbfbf;
  margin-bottom: 80px;

  @media (max-width: 767px) {
    width: 320px;
    margin: 0 auto 313px;
  }
`;

const Graph = styled.div``;

const Bars = styled.div`
  display: flex;
  gap: 60px;

  @media (max-width: 767px) {
    gap: 35px;
    padding: 0 68px;
  }
`;

const DescBox = styled.div<{ en?: boolean }>`
  position: absolute;
  top: 20px;
  right: 0;
  padding: 30px 27px 30px 30px;
  border: 3px solid ${SEEDN_NEW};
  white-space: nowrap;
  background-color: #fff;

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #111111;
    margin-bottom: 10px;

    span {
      display: block;
    }

    strong {
      font-weight: 900;
    }
  }

  small {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.019em;
    color: #1e1e1e;
    opacity: 0.5;
    display: block;
    text-align: right;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    width: 30px;
    height: 2px;
    background-color: ${SEEDN_NEW};
  }

  @media (max-width: 767px) {
    top: auto;
    right: auto;
    bottom: calc(-140px - 123px - 55px);
    left: 50%;
    transform: translateX(-50%);
    padding: 22px 30px 16px;

    p,
    strong {
      font-size: 14px;
      line-height: 30px;
    }

    p {
      margin-bottom: 0;

      /* span {
        display: inline;
      } */
    }

    small {
      font-size: 8px;
    }

    &::before {
      display: none;
    }
  }
`;

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: -56px;
  transform: translate(0, -50%);
  border-radius: 50%;
  width: 33px;
  height: 33px;
  padding: 7px;
  background-color: rgba(111, 54, 201, 0.2);

  div {
    position: relative;
    width: 19px;
    height: 19px;
    background-color: ${SEEDN_NEW};
    border-radius: 50%;
    z-index: 10;
    opacity: 1;
  }

  @media (max-width: 767px) {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Caption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 28px;
  right: 0;

  @media (max-width: 767px) {
    bottom: -83px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    white-space: nowrap;
  }
`;

const CaptionItem = styled.div<{ type?: string }>`
  display: flex;
  align-items: center;
  gap: 20px;

  div {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: ${({ type }) =>
      type === 'leaf' ? SEEDN_SUB : '#e9e9e9'};
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111111;
    opacity: 0.8;
  }

  @media (max-width: 767px) {
    gap: 10px;
    div {
      width: 19px;
      height: 19px;
    }

    span {
      font-size: 12px;
      line-height: 17px;

      br {
        display: none;
      }
    }
  }
`;

const WaveDesktop = styled.div`
  position: absolute;
  bottom: 0;
  left: 4px;
  width: 755px;
  height: 210px;

  &.transition {
    background-image: url(${WaveImgDesktop});
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const WaveMobile = styled.div`
  display: none;
  background-image: url(${WaveImgMobile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 767px) {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 289px;
    height: 96px;
  }
`;
