const main = {
  start: {
    title: `세상을 위한 적정온도로
      <br />
      기후 위기 문제를 해결합니다.`,
    balloons: {
      1: `"여름인데 카페가 너무 추워서 담요를 찾게 돼요"`,
      2: `"겨울철 히터 때문에 사무실이 너무 건조해요" `,
      3: `"에어컨을 하루종일 틀다보니 전기세가 너무 많이 나와요"`,
      4: `"히터를 끄면 춥고, 켜두면 너무 답답해요"`,
    },
    outro: {
      1: '모두가 공감하고 있지만',
      2: '해결하기 어려웠던 문제,',
      3: '씨드앤과 함께 일상이 변합니다.',
    },
  },
  illust: {
    title: {
      1: '씨드앤의 데이터 기반 체계적 온도 관리',
      2: '에너지 운영관리의 혁신을 가져옵니다.',
    },
    articles: {
      1: {
        title: `<span>에어컨 주변이 아닌</span> <strong>사람 주변</strong>의
          <br />
          온습도 데이터를 수집합니다.`,
        desc: `
          우리는 에어컨 아래에서 활동하며 지냅니다. <br />
          그렇기 때문에 에어컨 주변의 온도는
          <br /> 실제 우리 주변의 온도와 다를 수밖에 없습니다.
          <br />
          리프는 에어컨 주변의 온습도가 아닌
          <br /> 사람 주변의 온습도 데이터를 수집합니다.`,
      },
      2: {
        title: `씨드앤 자체 개발 <strong>Seed AI</strong> 기술로
            <br />
            <span>수집한 데이터를</span> 분석 및 학습합니다.`,
        desc: `Seed AI는 센서가 수집한 온습도 데이터는 물론,
            <br />
            건물데이터와 날씨데이터 등
            <br />
            실내 온도변화에 영향을 주는 요소를
            <br />
            함께 고려하여 학습에 반영합니다.`,
      },
      3: {
        title: `<strong>학습된 데이터</strong>를 바탕으로 한<br />
            체계적인 온도관리`,
        desc: `학습된 데이터로 실내 온습도 변화와 열에너지 변화를<br />
            예측하여 체계적으로 온도를 관리합니다.<br />
            어느 냉난방기를 켜고 끌 것인지와<br />
            운전 모드, 설정 온도, 바람 세기 등 최적의 냉난방기<br />
            운영 조합을 도출해 실내를 쾌적하게 유지합니다.`,
      },
      4: {
        title: `스마트한 <strong>자율온도 제어 서비스</strong>로<br />
            가장 효율적인 에너지 운영 관리`,
        desc: `자율주행자동차가 사람의 개입 없이
            <br />
            자율적으로 안전하게 주행이 가능한 것처럼
            <br />
            리프도 사람의 개입 없이 자율적으로<br />
            냉난방기를 운영하여 에너지 효율을<br />
            10% 이상 높일 수 있습니다.`,
      },
    },
  },
  graph: {
    title: '온도에 대한 기준을 만듭니다.',
    desc: `<strong>실시간으로 수집되는 온습도 데이터</strong>를 바탕으로
      <br />
      과냉방방지, 쾌적함 유지를 위한 <strong>적정온도 구간</strong>을
      설정합니다.`,
    dotLabel: {
      1: '9시',
      2: '10시',
      3: '11시',
    },
    graphLabel: '적정 온도 구간',
    outro: {
      1: '에어컨 주변온도와 사람 주변온도의 차이로 인한 과냉난방,',
      2: '뒤늦은 대응으로 인해 불필요한 에너지 소비가 발생하는 기존의 운영방식에서 벗어나',
      3: '매 10분마다 실시간 변수를 반영해 적정온도 구간을 변경합니다.',
      4: '변화에 대한 사전 예측으로 온도에 대한 기준을 만듭니다.',
    },
  },
  customer: {
    title: {
      1: '고객들은 씨드앤의 리프 서비스로 ',
      2: '일상의 변화를 경험하셨습니다.',
    },
    comment1: {
      message: `“관리자 입장에서 너무 편해서 좋아요.
        <br />
        이번 달에는 지난달 대비 전기세 20%가 줄었어요.”`,
      from: '-글로벌 의류 브랜드 H사',
    },
    comment2: {
      message: `“고객 클레임이 줄었어요.
        <br />
        또 에어컨 성능이 저하되면 점검하라고 알려주니까
        <br />
        고장나기 전에 미리 대응할 수 있더라구요.”`,
      from: '-국내 대형 프렌차이즈 카페 T사',
    },
  },
  barGraph: {
    title: {
      smallText: `씨드앤과 함께 변화하는 일상
        <br />
        환경을 지키는 변화도 가져옵니다.`,
      largeText: {
        1: 'CO2는 <1></1>kg 절감했어요.',
        2: '소나무는 <1></1>그루 심을 수 있어요.',
      },
      desc: '2020년 7~9월 기준',
    },
    bar: {
      1: '7월',
      2: '8월',
      3: '9월',
    },
    box: {
      p: {
        1: '씨드앤의 리프 사용 후 ',
        2: `기존 에너지 사용량이
          <br />
          79,595 Kwh 에서
          <br />
          <strong> 38,413 Kwh</strong> 로 감소했어요.`,
      },
      small: '3개월간 비교',
    },
    caption: {
      1: '기존 냉난방 전력 에너지 사용량',
      2: '리프 도입 후 에너지 사용량',
    },
  },
  askSection: {
    title: '씨드앤의 에너지 혁신에 함께하세요.',
    button: '문의하기',
  },
  enterprise: {
    1: '많은 기업들이 씨드앤과 함께',
    2: '변화를 경험하고 있습니다.',
  },
  ask: {
    content:
      '씨드앤과 함께 누구보다 빠르게<br />세상을 위한 적정온도를 만드세요.',
    btn: '온라인 문의',
    modal: {
      title: '문의하기',
      desc: '담당자가 확인 후 빠른 시일 내에 연락드립니다.',
      company: '회사명',
      name: '성함',
      email: '이메일',
      contact: '연락처',
      content: '내용',
      subIndex: '(200자 이내)',
      cancel: '돌아가기',
      send: '제출하기',
    },
  },
  askSuccess: {
    title: `문의주신 내용이 <br />
    제출되었습니다.`,
    desc: `작성하신 정보를 참고하여 <br />
    빠른 시일 내에 연락드리겠습니다.`,
    button: '홈으로 가기',
  },
  askFail: {
    title: `문의사항 제출에 <br />
    실패하였습니다.`,
    errorCode: '에러코드',
    desc: {
      1: '이용에 불편을 드려 대단히 죄송합니다.',
      2: '동일한 문제가 지속적으로 발생하는 경우',
      3: ' 카카오채널 혹은 CS 유선번호로 문의 부탁드립니다.',
    },
    button: {
      desktop: '이전 페이지로 이동',
      mobile: '이전 페이지로',
    },
  },
};

export default main;
