import { Z_INDEX } from 'styles';
import styled from 'styled-components';
import Button from '../careers/CareerButton';

interface IResultModal {
  type?: string;
  errorMessage?: string;
  onClose: (isSuccess?: boolean) => void;
}

const ResultModal = ({ type, errorMessage, onClose }: IResultModal) => {
  return (
    <Layer>
      <Container>
        <Title>
          {type === 'success' ? '지원서 제출이 완료되었습니다.' : ''}
        </Title>
        <Content>
          {type === 'success' ? (
            <>
              <span>지원해 주셔서 감사합니다.</span>{' '}
              <span className="desktop">
                지원 결과는 영업일 기준 평균 3일 이내로
                <br /> 이력서에 기재해주신 이메일 혹은 전화번호로 연락드릴
                예정입니다.
              </span>{' '}
              <span className="mobile">
                지원 결과는 영업일 기준 평균 3일 이내로 기재해주신
                <br /> 이메일 혹은 전화번호로 연락드릴 예정입니다.
              </span>{' '}
              <span>문의 사항은 recruit@seedn.co.kr 로 문의해주세요.</span>
            </>
          ) : (
            errorMessage
          )}
        </Content>
        <StyledButton
          borderRadius="10px"
          width="200px"
          onClick={() => onClose(!errorMessage)}
        >
          확인
        </StyledButton>
      </Container>
    </Layer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  padding: 43px 0 36px;
  border: 1px solid #000000;
  border-radius: 20px;
  background-color: #fff;
  z-index: ${Z_INDEX.MODAL};

  @media (max-width: 767px) {
    width: 312px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px 8px 8px;
    border: none;
    border-radius: 10px;
  }
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 12px;
  }
`;

const Content = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  span.mobile {
    display: none;
  }

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    gap: 6px;

    span {
      text-align: center;

      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  width: 200px;

  @media (max-width: 767px) {
    width: 100%;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${Z_INDEX.MODAL_LAYER};
`;

export default ResultModal;
