import styled from 'styled-components';
import Responsive from 'components/common/Responsive';
import useApplyForm from 'hooks/careers/useApplyForm';
import { SEEDN_GREEN } from 'styles/palette';
import ApplyButton from 'components/careers/ApplyButton';
import { useNavigate } from 'react-router-dom';
import ApplyModal from 'components/careers/ApplyModal';
import { ReactComponent as IconClose } from 'assets/icons/common/close.svg';
import ResultModal from 'components/common/ResultModal';
import Spinner from 'components/common/Spinner';

const MAX_CAPACITY = 30;

const ApplyForm = () => {
  const navigate = useNavigate();

  const {
    position,
    inputs,
    localError,
    error,
    resultModal,
    setInputs,
    onCloseModal,
    onChange,
    onChangeFile,
    onSubmit,
    confirmModal,
    setConfirmModal,
    isLoading,
  } = useApplyForm();

  const onClose = (type: string) => {
    setConfirmModal({ ...confirmModal, [type]: false });
  };

  return (
    <>
      <Responsive>
        <ApplyFormBlock
          method="post"
          encType="multipart/form-data"
          accept-charset="UTF-8"
        >
          <div className="title">지원서 작성하기</div>
          <div className="position">{position}</div>

          <div className="index">
            <div>
              이름<span style={{ color: 'red' }}> *</span>
            </div>
            {localError?.name && (
              <Validation isError={localError?.name}>
                {localError?.name}
              </Validation>
            )}
          </div>
          <InputGroup>
            <Input
              type="text"
              name="name"
              value={inputs.name}
              onChange={onChange}
              placeholder="이름"
              isError={localError?.name}
            />
            <IconDelete onClick={() => setInputs({ ...inputs, name: '' })} />
          </InputGroup>

          <div className="index">
            <span>
              전화번호<span style={{ color: 'red' }}> *</span>
            </span>
            {localError?.phone && (
              <Validation isError={localError?.phone}>
                {localError?.phone}
              </Validation>
            )}
          </div>
          <InputGroup>
            <Input
              type="text"
              name="phone"
              value={inputs.phone}
              onChange={onChange}
              placeholder="전화번호 (010-XXXX-XXXX)"
              isError={localError?.phone}
            />
            <IconDelete onClick={() => setInputs({ ...inputs, phone: '' })} />
          </InputGroup>

          <div className="index">
            <span>
              이메일<span style={{ color: 'red' }}> *</span>
            </span>
            {localError?.email && (
              <Validation isError={localError?.email}>
                {localError?.email}
              </Validation>
            )}
          </div>
          <InputGroup>
            <Input
              type="email"
              name="email"
              value={inputs.email}
              onChange={onChange}
              placeholder="이메일"
              isError={localError?.email}
            />
            <IconDelete onClick={() => setInputs({ ...inputs, email: '' })} />
          </InputGroup>

          <div className="index">
            <span>
              이력서 및 자기소개서<span style={{ color: 'red' }}> *</span>
            </span>
          </div>
          <Label htmlFor="resume" value={inputs.resume}>
            {inputs.resume
              ? inputs.resume.name
              : '이력서 및 경력기술서 첨부(PDF 권장)'}
          </Label>
          <Input
            type="file"
            id="resume"
            name="resume"
            onChange={onChangeFile}
          />
          <div className="tip">
            파일은 최대 {MAX_CAPACITY}MB까지 업로드하실 수 있습니다.
          </div>
          <div className="tip">
            이력서 및 경력기술서는 자유양식이며, 한 개의 파일로 통합하여
            제출해주세요.
          </div>
          <div className="blank" />

          <div className="index">
            <span>포트폴리오</span>
          </div>
          <Label htmlFor="portfolio" value={inputs.portfolio}>
            {inputs.portfolio
              ? inputs.portfolio.name
              : '포트폴리오 첨부(PDF 권장)'}
          </Label>
          <Input
            type="file"
            id="portfolio"
            name="portfolio"
            onChange={onChangeFile}
          />
          <div className="tip">
            파일은 최대 {MAX_CAPACITY}MB까지 업로드하실 수 있습니다.
          </div>
          <div className="tip">
            포트폴리오를 필수로 제출하셔야 하는 포지션은, 확인 후 첨부해주세요.
          </div>
          <div className="blank" />

          <div className="index">
            <span>경력기술서</span>
          </div>
          <Label htmlFor="career-desc" value={inputs.career}>
            {inputs.career ? inputs.career.name : '경력기술서(PDF 권장)'}
          </Label>
          <Input
            type="file"
            id="career-desc"
            name="career"
            onChange={onChangeFile}
          />
          <div className="tip">
            파일은 최대 {MAX_CAPACITY}MB까지 업로드하실 수 있습니다.
          </div>
          <ButtonGroup>
            <ApplyButton
              color="grey"
              onClick={e => {
                e.preventDefault();
                setConfirmModal({ ...confirmModal, back: true });
              }}
            >
              돌아가기
            </ApplyButton>
            <ApplyButton
              color="green"
              disabled={
                Object.values(localError).filter(el => el === '확인.')
                  .length !==
                  Object.values(localError).length - 1 || inputs.resume === null
              }
              onClick={e => {
                e.preventDefault();
                setConfirmModal({ ...confirmModal, submit: true });
              }}
            >
              지원하기
            </ApplyButton>
          </ButtonGroup>
        </ApplyFormBlock>
        {confirmModal.back && (
          <ApplyModal
            type="back"
            onClose={onClose}
            onSubmit={() => navigate(-1)}
          />
        )}
        {confirmModal.submit && (
          <ApplyModal type="submit" onClose={onClose} onSubmit={onSubmit} />
        )}
        {resultModal && (
          <ResultModal
            type={error ? 'error' : 'success'}
            errorMessage={error}
            onClose={onCloseModal}
          />
        )}
      </Responsive>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
    </>
  );
};

export default ApplyForm;

const ApplyFormBlock = styled.form`
  display: flex;
  flex-direction: column;
  width: 517px;
  margin: 0 auto;

  .title {
    margin-top: 33px;
    margin-bottom: 8px;
    color: #000;
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
  }

  .position {
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #7c7c7c;
  }

  .index {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 4px 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 13px;

    .error-line {
      margin-left: 10px;
      color: #f44336;
      font-size: 10px;
      font-weight: 300;
    }
  }

  .tip {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;

    &::before {
      padding-top: 4px;
      padding-right: 6px;
      content: '*';
    }
  }

  .blank {
    height: 30px;
  }

  @media (max-width: 767px) {
    width: auto;
    padding: 0 24px;

    .title {
      margin: 60px 0 10px;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
    }

    .position {
      margin-bottom: 50px;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
    }

    .index {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      justify-content: space-between;
    }

    .tip {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

const Validation = styled.div<{ isError: string }>`
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: ${({ isError }) => (isError === '확인.' ? SEEDN_GREEN : '#ff0000')};
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 50px;
`;

const Input = styled.input<{ isError?: string }>`
  padding: 17px 15px;
  border: ${({ isError }) =>
    !isError ? '1px solid #000' : '1px solid transparent'};
  box-shadow: ${({ isError }) =>
    isError === '확인.'
      ? `0 0 0 3px ${SEEDN_GREEN} inset`
      : isError && isError !== '확인.'
      ? `0 0 0 3px #ff0000 inset`
      : ''};
  border-radius: 10px;
  color: #000;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  width: 100%;

  &::placeholder {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #aaa;
  }

  &[type='file'] {
    display: none;
  }

  @media (max-width: 767px) {
    padding: 15px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const IconDelete = styled(IconClose)`
  width: 12px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Label = styled.label<{ value?: File | null }>`
  padding: 17px 15px;
  margin-bottom: 8px;
  border: 1px solid #000;
  border: ${({ value }) =>
    !value ? '1px solid #000' : '1px solid transparent'};
  box-shadow: ${({ value }) => value && `0 0 0 3px ${SEEDN_GREEN} inset`};
  border-radius: 10px;
  color: ${({ value }) => (value ? '#000' : '#aaa')};
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  cursor: pointer;

  @media (max-width: 767px) {
    padding: 15px 20px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  margin: 32px 0 97px;

  @media (max-width: 767px) {
    margin: 50px 0;
  }
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;
