import React from 'react';
import styled from 'styled-components';
import { SEEDN_GREEN, SEEDN_MO, GREY, GREY_MO } from 'styles/palette';

interface IButton {
  children: React.ReactNode;
  className?: string;
  width?: string;
  color?: string;
  borderRadius?: string;
  onClick?: (e?: any) => void;
}

const CareerButton = ({
  children,
  className,
  width,
  color,
  borderRadius,
  onClick,
}: IButton) => {
  return (
    <StyledButton
      className={className}
      width={width}
      color={color}
      borderRadius={borderRadius}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<IButton>`
  padding: 6.5px 30.5px;
  width: ${({ width }) => width || '164px'};
  border-radius: ${({ borderRadius }) => borderRadius || '60px'};
  background-color: ${({ color }) => (color === 'grey' ? GREY : SEEDN_GREEN)};
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  transition: all 200ms ease-in;

  &:hover {
    background-color: ${({ color }) => (color === 'grey' ? GREY_MO : SEEDN_MO)};
  }

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
`;

export default CareerButton;
