import styled, { css } from 'styled-components';
import hubImg from 'assets/imgs/service/realHub.png';
import sensorImg from 'assets/imgs/service/realSensor.png';
import controllerImg from 'assets/imgs/service/realController.png';
import { StyledProps } from './Specification';
import { Mode } from './Specification';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  mode: Mode;
  setMode: (mode: Mode) => void;
}

function SmallPurpleBox({ mode, setMode }: Props) {
  const { t } = useTranslation('services');
  return (
    <Container>
      <Item onClick={() => setMode('hub')}>
        <HubImage src={hubImg} draggable="false" />
      </Item>
      <Item onClick={() => setMode('sensor')}>
        <SensorImage src={sensorImg} draggable="false" />
      </Item>
      <Item onClick={() => setMode('controller')}>
        <ControllerImage src={controllerImg} draggable="false" />
      </Item>
      <WhiteBackground mode={mode} />
      <Name>
        {mode === 'hub' ? (
          <Trans t={t} i18nKey="flow.hub" />
        ) : mode === 'sensor' ? (
          <Trans t={t} i18nKey="flow.sensor" />
        ) : (
          <Trans t={t} i18nKey="flow.controller" />
        )}
      </Name>
    </Container>
  );
}

const Container = styled.div`
  width: 340px;
  height: 80px;
  border-radius: 0 40px 40px 0;
  background: #6f36c9;
  position: relative;
  display: flex;
  align-items: center;
  left: -50px;
  margin-bottom: 30px;
  padding: 10px 25px;
`;

const Item = styled.div`
  background: #4f16a9;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 38px;
`;

const HubImage = styled.img`
  width: 64px;
  height: 64px;
  position: relative;
  left: 3px;
  top: -3px;
  z-index: 20;
`;

const SensorImage = styled(HubImage)`
  width: 48px;
  height: 65px;
  left: 2px;
  top: 3px;
  z-index: 20;
`;

const ControllerImage = styled(HubImage)`
  width: 45px;
  height: 45px;
  left: 1.3px;
  top: 0px;
  z-index: 20;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #6f36c9;
  opacity: 0.8;
  position: absolute;
  left: 60px;
  top: 100px;
`;

const WhiteBackground = styled.div<StyledProps>`
  position: absolute;
  width: 60px;
  height: 74px;
  left: 65px;
  top: 6px;
  background: white;
  border-radius: 60px 60px 0px 0px;
  z-index: 10;
  transition: 0.5s;
  ${({ mode }) => css`
    transform: translateX(
      ${mode === 'hub' ? '0' : mode === 'sensor' ? '96px' : '192px'}
    );
  `}
`;

export default SmallPurpleBox;
