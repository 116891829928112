import styled from 'styled-components';
import { SEEDN_SUB } from 'styles/palette';
import ThermometerImg from 'assets/imgs/about/thermometer.png';
import HistoryMobileImg from 'assets/imgs/about/history-mobile.png';
import useIntersectionObserver from 'hooks/common/useIntersectionObserver';
import { useState } from 'react';
import HistoryCarousel from './HistoryCarousel';
import { useTranslation, Trans } from 'react-i18next';
import useChangeLanguage from 'hooks/common/useChangeLanguage';

const History = () => {
  const { t } = useTranslation('about');
  const [animation, setAnimation] = useState(false);
  const onIntersect: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      setTimeout(() => {
        setAnimation(true);
      }, 100);
    } else {
      setAnimation(false);
    }
  };
  const setTarget = useIntersectionObserver({ onIntersect });
  const { language } = useChangeLanguage();

  const HISTORY_TEXT = [
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2015.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2015.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2015.desc'} />
        </Desc>
      </Flexbox>
    </>,
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2016.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2016.1.month'} />
        </Month>
        <div>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2016.1.desc.1'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2016.1.desc.2'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2016.1.desc.3'} />
          </Desc>
        </div>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2016.2.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2016.2.desc'} />
        </Desc>
      </Flexbox>
    </>,
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2017.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2017.month'} />
        </Month>
        <div>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2017.desc.1'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2017.desc.2'} />
          </Desc>
        </div>
      </Flexbox>
    </>,
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2018.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2018.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2018.desc'} />
        </Desc>
      </Flexbox>
    </>,
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2019.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2019.1.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2019.1.desc'} />
        </Desc>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2019.2.month'} />
        </Month>
        <div>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2019.2.desc.1'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2019.2.desc.2'} />
          </Desc>
        </div>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2019.3.month'} />
        </Month>
        <div>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2019.3.desc.1'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2019.3.desc.2'} />
          </Desc>
        </div>
      </Flexbox>
    </>,
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2020.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2020.1.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2020.1.desc'} />
        </Desc>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2020.2.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2020.2.desc'} />
        </Desc>
      </Flexbox>
    </>,
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2021.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2021.1.month'} />
        </Month>
        <div>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2021.1.desc.1'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2021.1.desc.2'} />
          </Desc>
        </div>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2021.2.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2021.2.desc'} />
        </Desc>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2021.3.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2021.3.desc'} />
        </Desc>
      </Flexbox>
    </>,
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2022.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2022.1.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2022.1.desc'} />
        </Desc>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2022.2.month'} />
        </Month>
        <div>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.2.desc.1'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.2.desc.2'} />
          </Desc>
        </div>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2022.3.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2022.3.desc'} />
        </Desc>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2022.4.month'} />
        </Month>
        <div>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.4.desc.1'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.4.desc.2'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.4.desc.3'} />
          </Desc>
        </div>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2022.5.month'} />
        </Month>
        <div>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.5.desc.1'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.5.desc.2'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.5.desc.3'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.5.desc.4'} />
          </Desc>
          <Desc>
            <Trans t={t} i18nKey={'history.items.2022.5.desc.5'} />
          </Desc>
        </div>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2022.6.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2022.6.desc'} />
        </Desc>
      </Flexbox>
    </>,
    <>
      <Year>
        <Trans t={t} i18nKey={'history.items.2023.year'} />
      </Year>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2023.1.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2023.1.desc'} />
        </Desc>
      </Flexbox>
      <Flexbox>
        <Month>
          <Trans t={t} i18nKey={'history.items.2023.2.month'} />
        </Month>
        <Desc>
          <Trans t={t} i18nKey={'history.items.2023.2.desc'} />
        </Desc>
      </Flexbox>
    </>,
  ];

  return (
    <Container>
      <Title>
        <Trans t={t} i18nKey={'history.title.1'} />
        <span>
          <Trans t={t} i18nKey={'history.title.2'} />
        </span>
      </Title>
      <DesktopContent ref={setTarget}>
        <Images>
          <Image src={ThermometerImg} />
          <Liquid animation={animation} />
        </Images>
        <TextWrapper>
          {HISTORY_TEXT.reverse().map((text, i) => (
            <TextItem key={i} en={language === 'en'}>
              {text}
            </TextItem>
          ))}
        </TextWrapper>
      </DesktopContent>
      <MobileContent>
        <MobileImage src={HistoryMobileImg} />
        <HistoryCarousel data={HISTORY_TEXT} />
      </MobileContent>
    </Container>
  );
};

const Container = styled.section`
  width: 1024px;
  margin: 0 auto;

  @media (max-width: 767px) {
    width: 360px;
  }
`;

const Title = styled.h1`
  position: relative;
  margin-left: 50px;
  margin-bottom: 100px;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  opacity: 0.8;

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    left: -13px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${SEEDN_SUB};
  }

  @media (max-width: 767px) {
    /* mobile H2 */
    font-size: 18px;
    line-height: 26px;
    margin-left: 24px;
    margin-bottom: 0;

    span {
      display: block;
    }

    &::before {
      width: 11px;
      height: 11px;
      top: -7px;
      left: -9px;
    }
  }
`;

const DesktopContent = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 400px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  position: relative;
  padding-top: 121px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Images = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 49%;
  transform: translateX(-50%);
  height: 1886px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Liquid = styled.div<{ animation: boolean }>`
  position: absolute;
  top: 103px;
  left: 50%;
  transform: translateX(-65%);
  width: 5px;
  height: 12px;
  background: linear-gradient(180deg, #8c37ca 0%, #3500a7 100%);
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  animation: ${({ animation }) =>
    animation && `down 4000ms ease-in-out 0ms 1 normal forwards`};

  @keyframes down {
    0% {
      height: 12px;
    }
    100% {
      height: 1760px;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileContent = styled.div`
  display: none;

  @media (max-width: 767px) {
    position: relative;
    display: block;
    padding-top: 21px;
    padding-bottom: 0;
  }
`;

const MobileImage = styled.img`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;

const Year = styled.h4`
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 18px;
  line-height: 27px;

  @media (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 23px;
  }
`;

const Flexbox = styled.div`
  /* &:not(:last-child) {
    margin-bottom: 5px;
  } */
  @media (max-width: 767px) {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const Month = styled.strong`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.2;
  @media (max-width: 767px) {
    width: 26px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.2;
  }
`;

const Desc = styled.p`
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  line-height: 21px;
  margin-bottom: 5px;

  &:not(:last-child) {
  }

  @media (min-width: 767px) {
    br {
      display: none;
    }
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const TextItem = styled.div<{ en: boolean }>`
  position: relative;
  width: 660px;
  height: fit-content;
  font-family: 'Inter';
  line-height: 150%;
  margin-bottom: 10px;

  &:nth-child(2n-1) {
    left: 560px;
  }

  &:nth-child(2n) {
    text-align: right;
    left: -196px;
  }
`;

export default History;
