import { AppDispatch, RootState } from 'index';
import { initializeWriteForm, onChangeWriteForm } from 'slices/careersSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createPost as createAsyncPost,
  updatePost as updateAsyncPost,
} from 'slices/careersSlice';

export const useWriter = (edit: boolean) => {
  const navigate = useNavigate();
  const { position, stack, contract, group, content, mainImage, error } =
    useSelector(({ careers }: RootState) => careers?.writeForm);
  const dispatch = useDispatch<AppDispatch>();

  const createPost = async () => {
    dispatch(onChangeWriteForm({ name: 'error', value: null }));
    await dispatch(
      createAsyncPost({
        position,
        content,
        contract,
        group,
        stack,
        mainImage,
      }),
    );
    navigate('/careers');
  };

  const updatePost = async (id: number) => {
    dispatch(onChangeWriteForm({ name: 'error', value: null }));
    await dispatch(
      updateAsyncPost({
        id,
        position,
        content,
        contract,
        group,
        stack: stack,
        mainImage,
      }),
    );
    navigate('/careers');
  };

  useEffect(() => {
    if (!edit) {
      dispatch(initializeWriteForm());
    }
  }, [dispatch, edit]);

  const onChange = (event: any) => {
    const { name, value } = event.target;
    dispatch(onChangeWriteForm({ name, value }));
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (!e.target.files) return;
    const file = e.target.files[0];
    e.target.value = '';
    dispatch(onChangeWriteForm({ name, value: file }));
  };

  const onDeleteImage = () => {
    dispatch(onChangeWriteForm({ name: 'mainImage', value: '' }));
  };

  return {
    position,
    stack,
    contract,
    group,
    mainImage,
    error,
    createPost,
    onChange,
    updatePost,
    onChangeFile,
    onDeleteImage,
  };
};
